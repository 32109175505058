import React from 'react';
import classnames from 'classnames';
import { MyLink } from '../../../../../components/MyLink';
import Tooltip from '../../../../../components/Tooltip';
import s from './index.module.scss';

export const LoanGroupCard = ({ group, className }) => (
  <div className={classnames(s.card, className)}>
    <MyLink href={group.link} className="card__title hov f-26 f-500">
      {group.title}
    </MyLink>
    {group.isActive ? (
      <>
        <div className={s.card__block}>
          <div className="f-18 f-500">
            <span>Target annual return</span>
            <Tooltip
              position="bottom center"
              renderContent={() => (
                <div className={classnames(s.card__tooltip, 'f-16  f-400')}>
                  Target annual return calculations include reinvested
                  repayments. Reinvesting significantly boosts your returns over
                  time.
                </div>
              )}
            />
          </div>
          <p className="f-18 f-500 tl">{group.rate}</p>
        </div>

        <div className={s.card__block}>
          <div className="f-18 f-500">Term</div>
          <p className="f-18 f-500 tl">{group.repaymentPeriod}</p>
        </div>

        <div className={s.card__block}>
          <div className="f-18 f-500">Downside protection</div>
          <p className="f-18 f-500 tl">{group.downsideProtection}</p>
        </div>

        <MyLink
          href={group.link}
          className={classnames(s.card__link_details, 'f-500')}
        >
          See details
        </MyLink>
      </>
    ) : (
      <p className={classnames(s.card__unavailable, 'f-18 f-500')}>
        {group.title} is temporarily unavailable.
      </p>
    )}
  </div>
);
