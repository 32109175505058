export const TIMEZONE_ESTONIA = 'Europe/Tallinn';
export const CURRENCY = '€';
export const EMPTY_DASH = '—';
export const CONTACT_EMAIL = 'ask@scrambleup.com';
export const CONTACT_PHONE = '+372 712 2955';

export const LOAN_GROUPS = {
  conservative: {
    id: 'conservative',
    investor_earnings: 'Up to 1% per month',
    target_annual_return: 'Up to 12.4%',
    term: '6-24 months',
    flat_fee_six_month: '5%',
    flat_fee_six_month_extra: '+ up to 4.4%',
    seniority: 'Senior',
    full_name: 'Group A',
    short_name: 'A',
    scope_name: 'Group A only',
    downside_protection: 'Triple-secured',
    repayment_type: 'Monthly',
    skin_in_the_game: 'up to 20%',
    disclaimer:
      'Select this group if you want to earn a stable income going along with reduced risk.',
    detailLink: 'v3-group-a',
    protection: 'Triple-secured',
  },
  moderate: {
    id: 'moderate',
    investor_earnings: '9% for 6 month',
    target_annual_return: 'Up to 25%',
    term: '6-24 months',
    flat_fee_six_month: '9%',
    flat_fee_six_month_extra: '+ up to 49%',
    seniority: 'Subordinated',
    full_name: 'Group B',
    short_name: 'B',
    scope_name: 'Groups A and B',
    downside_protection: 'Single-secured',
    repayment_type: 'Last month',
    skin_in_the_game: '0%',
    disclaimer:
      'Select this group if you are open to increased risk and want to earn higher yields.',
    detailLink: 'v3-group-b',
    protection: 'Single-secured',
  },
  growth: {
    id: 'growth',
    investor_earnings: 'up to 1% per month',
    target_annual_return: '-',
    term: '-',
    flat_fee_six_month: '-',
    flat_fee_six_month_extra: '-',
    seniority: 'Junior',
    full_name: 'Group C',
    short_name: 'C',
    scope_name: 'Groups A, B and C',
    downside_protection: '-',
    repayment_type: '-',
    skin_in_the_game: '-',
    disclaimer: '-',
    detailLink: 'v3-group-c',
    protection: 'Single-secured',
  },
};

export const ACCOUNT_TYPES = {
  loan: 'loan',
  cash: 'cash',
};

export const LOAN_GROUPS_SHORT = {
  conservative: 'A',
  moderate: 'B',
  growth: 'C',
};

export const LOAN_GROUPS_NAMES = {
  conservative: 'Group A only',
  moderate: 'Groups A and B',
  growth: 'Groups A, B and C',
};

export const FUNDS_ORIGIN = {
  employment: 'Employment',
  ownership: 'Ownership of a business/company profit',
  savings: 'Savings',
  sale_of_property: 'Sale of property',
  investment: 'Investments',
  inheritance: 'Inheritance',
  gift: 'Gift',
  other: 'Other',
};

export const PLANNED_MONTHLY_INVESTMENT = {
  1: '< € 500',
  2: "€ 500 - 1'000",
  3: "€ 1'000 - 5'000",
  4: "€ 5'000 - 15'000",
  5: "> € 15'000",
};

export const USER_STATUSES = {
  new: 'new',
  verified: 'verified',
  blocked: 'blocked',
  suspended: 'suspended',
};

export const PROMOTION_STATUSES = {
  accepted: 'accepted',
  declined: 'declined',
  not_answered: 'not_answered',
  set_company: 'set_company',
  check_company: 'check_company',
};

export const VERIFF_CODES = {
  new: 0,
  started: 7001,
  submitted: 7002,
  verified: 9001,
  notVerified: 9102,
  reSubmitted: 9103,
  expired: 9104,
};

export const LINK_TYPES = {
  phone: 'phone',
  web: 'web',
  mail: 'mail',
};

export const PASSWORD_SPECIAL_CHARS = `!"#$%€&'()*+,-./:;<=>?@[\\]^_'{|}~`;

export const MIN_PASSWORD_LENGTH = 8;

export const QUESTION_STATUSES = {
  accepted: 'accepted',
  rejected: 'rejected',
  draft: 'draft',
};

export const BANNER_STATUSES = {
  get_increased_income: 'get_increased_income',
  keep_benefiting_done_type_a: 'keep_benefiting_done_type_a',
  keep_benefiting_done_type_b: 'keep_benefiting_done_type_b',
  keep_benefiting_done_type_c: 'keep_benefiting_done_type_c',
  keep_benefiting_save_type_a: 'keep_benefiting_save_type_a',
  keep_benefiting_save_type_b: 'keep_benefiting_save_type_b',
  keep_benefiting_save_type_c: 'keep_benefiting_save_type_c',
};
