import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { MyLink } from '../../../../components/MyLink';
import { APP_LINKS } from '../../../../helpers/links';
import { useGetAllocationStatus } from '../../../../hooks/useGetAllocationStatus';
import { LOAN_GROUPS } from '../../../../helpers/constants';
import { Table } from './table';
import s from './index.module.scss';

export const RepaymentSchedule = () => {
  const { data } = useSelector(
    state => state.investStore.allocatedRepaymentSchedule
  );
  const { allocatedGroupA, allocatedGroupB } = useGetAllocationStatus();

  const getGroup = () => {
    if (allocatedGroupA && allocatedGroupB) {
      return `${LOAN_GROUPS.conservative.full_name} and ${LOAN_GROUPS.moderate.full_name}`;
    }
    if (allocatedGroupA) {
      return LOAN_GROUPS.conservative.full_name;
    }
    if (allocatedGroupB) {
      return LOAN_GROUPS.moderate.full_name;
    }
  };

  const groups = getGroup();

  if (!data) {
    return null;
  }

  return (
    <div className={s.wrapper}>
      <div className="f-16-24">
        The repayment schedule may be changed if the fundraising goal is
        increased during the round based on actual investor demand. Check the
        final schedule after the end of the round.
      </div>
      <div className={s.schedule} style={{ marginTop: '20px' }}>
        <div className={s.schedule_title}>{groups} loan repayment schedule</div>
        <Table data={data} />
      </div>
      <div className={classnames(s.hint, 'f-16 f-400')}>
        The repayment schedule is subject to changes in case a startup extends
        loan terms (to a maximum of 24 months) or moves a monthly repayment to
        the last month of the current loan term. Please find more details in the{' '}
        <MyLink href={APP_LINKS.helpLoanTerms()} underline>
          Loan terms
        </MyLink>
        .
      </div>
    </div>
  );
};
