import React from 'react';
import { APP_LINKS } from '../../../helpers/links';
import { ArticleCard } from '../../BlogPage/components/ArticleCard';
import { RoundLink } from '../../../components/UI/RoundLink';
import s from './index.module.scss';

export const LatestArticles = ({ posts }) => {
  return (
    <div className={s.wrapper}>
      <h2 className="f-32 f-500">Latest posts</h2>
      <div className={s.cards}>
        {posts.slice(0, 3).map(article => (
          <ArticleCard key={article.id} article={article} className={s.card} />
        ))}
      </div>
      <div className={s.controls}>
        <RoundLink
          label=" Full list of articles"
          type="btn_black_border"
          variant="black"
          showArrow
          path={APP_LINKS.blog}
        />
      </div>
    </div>
  );
};
