import React, { useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Decimal from 'decimal.js-light';
import AllocateCashForm from '../../components/Forms/AllocateCashForm';
import {
  getAllocateInfo,
  updatePreAllocateInfo,
} from '../../store/actions/fundActions';
import Preloader from '../../components/Preloader';
import { getInvestingStatus } from '../../store/actions/investActions';
import './index.scss';

const AllocateFundModal = ({ className, selected, setProcessing, onClose }) => {
  const dispatch = useDispatch();
  const { allocateInfo, preAllocateInfo } = useSelector(
    state => state.fundStore
  );
  const accounts = useMemo(
    () => (preAllocateInfo.data ? preAllocateInfo.data.accounts : []),
    [preAllocateInfo.data]
  );

  useEffect(() => {
    !preAllocateInfo.data && dispatch(getInvestingStatus());
    !allocateInfo.data && dispatch(getAllocateInfo());
  }, []);

  const onAllocate = async values => {
    const enteredAmount = new Decimal(values.amount).toNumber();
    const payload = {
      from_account: values.from.group,
      to_account: values.to.group,
      amount: enteredAmount,
    };

    setProcessing(true);
    await dispatch(updatePreAllocateInfo(payload));
    setProcessing(false);
    onClose();
  };

  return (
    <div className={classnames('allocate-fund-modal', className)}>
      {accounts.length ? (
        <AllocateCashForm
          preSelectedID={selected}
          className="allocate-fund-modal__form"
          onSubmit={onAllocate}
        />
      ) : (
        <Preloader className="allocate-fund-modal__preloader" />
      )}
    </div>
  );
};

export default AllocateFundModal;
