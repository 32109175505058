import classnames from 'classnames';
import React from 'react';
import moment from 'moment';
import { prettyFloatMoney } from '../../../../../helpers/utils';
import s from './index.module.scss';

export const ExampleTable = ({ data }) => (
  <div className={s.example_table__wrap}>
    <div className={s.example_table__inner}>
      <div className={s.example_table}>
        <div className={s.example_table__thead}>
          <div className={s.example_table__thead_col}>Num</div>
          <div className={s.example_table__thead_col}>Date</div>
          <div className={s.example_table__thead_col}>Interest</div>
          <div className={s.example_table__thead_col}>Success charge</div>
          <div className={s.example_table__thead_col}>Principal</div>
          <div className={s.example_table__thead_col}>Total</div>
        </div>

        <div className={s.example_table__tbody}>
          {data.list.map((item, idx) => (
            <div className={s.example_table__tbody_row} key={idx}>
              <div className={s.example_table__tbody_col}>
                <div className="f-16 f-500 tl">{idx + 1}.</div>
              </div>
              <div className={s.example_table__tbody_col}>
                <span className="f-16 f-500">
                  {moment(item.date).format('MMMM D')}
                </span>
              </div>
              <div className={s.example_table__tbody_col}>
                <div className="f-16 f-500 tl">
                  {prettyFloatMoney(item.interest)}
                </div>
              </div>
              <div className={s.example_table__tbody_col}>
                <div className="f-16 f-500 tl">
                  {item.success_charge
                    ? prettyFloatMoney(item.success_charge)
                    : '-'}
                </div>
              </div>
              <div className={classnames(s.example_table__tbody_col, 'tl')}>
                <div className="f-16 f-500 tl">
                  {item.principal ? prettyFloatMoney(item.principal) : '-'}
                </div>
              </div>
              <div className={classnames(s.example_table__tbody_col, 'tl')}>
                <div className="f-16 f-500 tl">
                  {prettyFloatMoney(item.total)}
                </div>
              </div>
            </div>
          ))}

          <div className="example_table__tbody_row_summary">
            <div className={s.example_table__tbody_col}>
              <div className="f-16 f-500">Total:</div>
            </div>
            <div className={s.example_table__tbody_col}>
              <span className="f-16 f-500" />
            </div>
            <div className={classnames(s.example_table__tbody_col, 'tl')}>
              <span className="f-16 f-500">
                {prettyFloatMoney(data.total_interest)}
              </span>
            </div>
            <div className={s.example_table__tbody_col}>
              <span className="f-16 f-500">
                {prettyFloatMoney(data.total_success_charge)}
              </span>
            </div>
            <div className={classnames(s.example_table__tbody_col, 'tl')}>
              <span className="f-16 f-500">
                {prettyFloatMoney(data.total_principal)}
              </span>
            </div>
            <div className={s.example_table__tbody_col}>
              <span className="f-16 f-500">{prettyFloatMoney(data.total)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
