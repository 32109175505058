import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage, getQuery } from '../../helpers/utils';
import {
  GET_ACTIVITY_LIST,
  GET_TAX_REPORT,
  SET_ACTIVITY_TYPES,
} from '../types/activityTypes';
import Notify from '../../components/Notification';

const getActivityListRequest = () => ({ type: GET_ACTIVITY_LIST.REQUEST });
const getActivityListSuccess = payload => ({
  type: GET_ACTIVITY_LIST.SUCCESS,
  payload,
});
const getActivityListFailure = error => ({
  type: GET_ACTIVITY_LIST.FAILURE,
  error,
});

const getTaxReportRequest = () => ({ type: GET_TAX_REPORT.REQUEST });
const getTaxReportSuccess = payload => ({
  type: GET_TAX_REPORT.SUCCESS,
  payload,
});
const getTaxReportFailure = error => ({
  type: GET_TAX_REPORT.FAILURE,
  error,
});

export const getActivityList = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getActivityListRequest());
    return axios
      .get(Pathes.Activity.list + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().activityStore.activityList.data;
          if (!isNext || !prevData) {
            dispatch(getActivityListSuccess(data));
            return { data, success: true, message };
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getActivityListSuccess(updatedData));
          return { data: updatedData, success: true, message };
        }

        if (status === 400) {
          const errorKey = Object.keys(data).slice(0, 1)[0];
          errorKey && data[errorKey] && Notify.error({ text: data[errorKey] });
        }

        throw new Error(message);
      })
      .catch(e => dispatch(getActivityListFailure(e.message)));
  };
};

export const getActivityTypesList = () => {
  return dispatch => {
    return axios
      .get(Pathes.Activity.types)
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          dispatch({ type: SET_ACTIVITY_TYPES, payload: data });
          return { data, success: true };
        }
        throw new Error('Fetch error');
      })
      .catch(e => dispatch(getActivityListFailure(e.message)));
  };
};

export const getTaxReport = params => {
  return dispatch => {
    dispatch(getTaxReportRequest());
    return axios
      .get(Pathes.Activity.taxReport + getQuery(params))
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          dispatch(getTaxReportSuccess(data));
          return { data, success: true };
        }
        throw new Error('Fetch error');
      })
      .catch(e => dispatch(getTaxReportFailure(e.message)));
  };
};
