import React from 'react';
import {
  AdditionalFiveForFriendBanner,
  FiveForHundredBanner,
} from '../Banners/YellowBanner';

export const AFFILIATE_STEPS = [
  {
    label: 'Invite your friends',
    desc: 'Share your unique \n referral link with your friends',
  },
  {
    label: 'They get a starting bonus',
    desc: 'You friends will receive \n €5 when they sign up',
    banner: <FiveForHundredBanner />,
  },
  {
    label: 'Earn your rewards',
    desc: 'Get a reward depending on your \n friend’s invested amount',
    banner: <AdditionalFiveForFriendBanner />,
  },
];
