import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
import Preloader from '../../../../../components/Preloader';
import { SlideCounter } from '../../../../../components/SlideCounter';
import { BrandCard } from '../../../../../components/Cards/BrandCard';
import { SliderNavigation } from './navigation';
import s from './index.module.scss';

export const BrandSlider = ({ brands }) => {
  const [ready, setReady] = useState(false);
  const [swiperRef, setSwiperRef] = useState(null);
  const { innerWidth } = useSelector(state => state.commonStore);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [params, setParams] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const onNext = () => {
    swiperRef?.slideNext();
  };

  const onPrev = () => {
    swiperRef?.slidePrev();
  };

  const getPerViewCount = useCallback(() => {
    if (innerWidth < 700) {
      return 1;
    }

    if (innerWidth < 700) {
      return 1.5;
    }

    if (innerWidth < 900) {
      return 2.5;
    }
    return 3.5;
  }, [innerWidth]);

  useEffect(() => {
    if (ready && brands.length) {
      setParams({
        isBeginning: true,
        isEnd: !!swiperRef?.isEnd,
      });
    }
  }, [ready, brands.length]);

  return (
    <>
      {!!brands.length && (
        <div className={s.navigation}>
          <SlideCounter current={currentSlide} total={brands.length} />
          <SliderNavigation onNext={onNext} onPrev={onPrev} {...params} />
        </div>
      )}

      {!ready && <Preloader className={s.preloader} />}

      <div className={s.container}>
        <Swiper
          onAfterInit={() => setReady(true)}
          onSwiper={setSwiperRef}
          slidesPerView={getPerViewCount()}
          spaceBetween={20}
          slideActiveClass={s.slide_active}
          className={classnames(s.slider, ready && s.slider_ready)}
          onSlideChange={swiper => {
            setCurrentSlide(swiper.activeIndex + 1);
            setParams({
              isBeginning: swiperRef.isBeginning,
              isEnd:
                swiperRef.isEnd ||
                swiperRef.snapIndex - swiperRef.activeIndex === 1,
            });
          }}
        >
          {brands.map((brand, index) => (
            <SwiperSlide key={index} className={s.slide}>
              <BrandCard brand={brand} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};
