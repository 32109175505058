import React, { useState } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { setModals } from '../../store/actions/commonActions';
import { RoundButton } from '../../components/UI/RoundButton';
import { getUserProfile } from '../../store/actions/profileActions';
import { prettyFloatMoney } from '../../helpers/utils';
import { confirmTermOfUse } from './action';
import s from './index.module.scss';

const TermsOfUseModal = ({ amount, onSuccess, onClose }) => {
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);

  const onAccept = async () => {
    const res = await confirmTermOfUse({ has_accepted_terms: true });
    if (res?.success) {
      onSuccess && onSuccess();
      dispatch(setModals([]));
      dispatch(getUserProfile());
      const warningEl = document.querySelector('#tou_warning');
      if (warningEl) {
        warningEl.style.display = 'none';
      }
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.form}>
        <h1 className="f-24 f-500">Increase your investment guarantees</h1>
        <div className={classnames(s.disclaimer, 'f-16')}>
          Dear Investor,
          <br />
          Scramble is entering the first phase of implementing a secondary
          market for loans through the assignment of loans between investors. By
          authorizing us to assign your current loans to other investors, you
          increase the probability of timely repayment within the first 6
          months. This repayment is supported not only by the funds of the
          brands, but also by the funds of other investors who buy your loans.
          <br /> <br />
          If you do not authorize the loan assignment,{' '}
          {showMore ? (
            <span>
              your funds will be repaid as usual, relying solely on the money
              from the brands. Please note that the brands have the right to
              postpone payments and extend the loan period by 6, 12 or 18
              months, which may change or extend the repayment schedule.
            </span>
          ) : (
            <span
              onClick={() => setShowMore(true)}
              style={{
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              read more...
            </span>
          )}
          <br /> <br />
          You can currently assign{' '}
          <span className="f-500">{prettyFloatMoney(amount)}</span> from your
          active loans.
        </div>

        <div className={s.buttons}>
          <RoundButton
            type="button"
            label={() => <span style={{ color: '#000000' }}>I agree</span>}
            onClick={onAccept}
            className={s.submit}
            fillBackground
          />
          <div onClick={() => onClose()}>Ask me later</div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUseModal;
