import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import {
  getFundraisingCountriesStats,
  getFundraisingStats,
} from '../../store/actions/investActions';
import { prettyMoney } from '../../helpers/utils';
import { ProgressBar } from './bar';
import { ProgressCountries } from './countries';
import s from './index.module.scss';

export const InvestingProgressBar = ({ className }) => {
  const dispatch = useDispatch();
  const { fundraisingStats, fundraisingCountriesStats } = useSelector(
    state => state.investStore
  );

  useEffect(() => {
    updateStats();
  }, []);

  useEffect(() => {
    window.addEventListener('focus', updateStats);
    return () => {
      window.removeEventListener('focus', updateStats);
    };
  }, []);

  const updateStats = () => {
    dispatch(getFundraisingStats());
    dispatch(getFundraisingCountriesStats());
  };

  return (
    <div className={classnames(s.wrapper, className)}>
      {/*<div className="f-26 f-500">Investing round progress</div>*/}
      {/*<div className={classnames('f-16', 'f-400', s.description)}>*/}
      {/*  The October round features 21 consumer goods brands, spanning from top*/}
      {/*  quality food products to cutting-edge self-care items. The investment*/}
      {/*  round is open until October 16, 2023. Don&apos;t miss out on this*/}
      {/*  opportunity to join.*/}
      {/*</div>*/}
      <ProgressBar
        current={fundraisingStats.currently_allocated}
        min={fundraisingStats.min_goal}
        max={fundraisingStats.total_goal}
        className={s.bar}
      />
      {fundraisingCountriesStats && (
        <ProgressCountries data={fundraisingCountriesStats} />
      )}
    </div>
  );
};
