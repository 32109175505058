import {
  GET_ALL_ROUNDS,
  GET_BRANDS_BATCH_BY_ID,
  GET_ROUND_COUNTRIES_BY_ID,
  GET_ROUND_LOAN_GROUPS_BY_ID,
  GET_ROUND_REPAYMENT_PROGRESS_BY_ID,
  GET_ROUNDS_SUMMARY,
} from '../types/roundHistoryTypes';
import { getMessage, getQuery } from '../../helpers/utils';
import Pathes from '../../helpers/pathes';
import axios from '../../axios-api';
import { ROUND_STATUSES } from '../../containers/RoundHistory/constants';

const getAllRoundsRequest = () => ({ type: GET_ALL_ROUNDS.REQUEST });
const getAllRoundsSuccess = payload => ({
  type: GET_ALL_ROUNDS.SUCCESS,
  payload,
});
const getAllRoundsFailure = error => ({
  type: GET_ALL_ROUNDS.FAILURE,
  error,
});

const getRoundsSummaryRequest = () => ({ type: GET_ROUNDS_SUMMARY.REQUEST });
const getRoundsSummarySuccess = payload => ({
  type: GET_ROUNDS_SUMMARY.SUCCESS,
  payload,
});
const getRoundsSummaryFailure = error => ({
  type: GET_ROUNDS_SUMMARY.FAILURE,
  error,
});

const getRoundCountriesByIdRequest = () => ({
  type: GET_ROUND_COUNTRIES_BY_ID.REQUEST,
});
const getRoundCountriesByIdSuccess = payload => ({
  type: GET_ROUND_COUNTRIES_BY_ID.SUCCESS,
  payload,
});
const getRoundCountriesByIdFailure = error => ({
  type: GET_ROUND_COUNTRIES_BY_ID.FAILURE,
  error,
});

const getRoundRepaymentProgressByIdRequest = () => ({
  type: GET_ROUND_REPAYMENT_PROGRESS_BY_ID.REQUEST,
});
const getRoundRepaymentProgressByIdSuccess = payload => ({
  type: GET_ROUND_REPAYMENT_PROGRESS_BY_ID.SUCCESS,
  payload,
});
const getRoundRepaymentProgressByIdFailure = error => ({
  type: GET_ROUND_REPAYMENT_PROGRESS_BY_ID.FAILURE,
  error,
});

const getRoundLoanGroupsByIdRequest = () => ({
  type: GET_ROUND_LOAN_GROUPS_BY_ID.REQUEST,
});
const getRoundLoanGroupsByIdSuccess = payload => ({
  type: GET_ROUND_LOAN_GROUPS_BY_ID.SUCCESS,
  payload,
});
const getRoundLoanGroupsByIdFailure = error => ({
  type: GET_ROUND_LOAN_GROUPS_BY_ID.FAILURE,
  error,
});

const getBrandsBatchByIdRequest = () => ({
  type: GET_BRANDS_BATCH_BY_ID.REQUEST,
});
const getBrandsBatchByIdSuccess = payload => ({
  type: GET_BRANDS_BATCH_BY_ID.SUCCESS,
  payload,
});
const getBrandsBatchByIdFailure = error => ({
  type: GET_BRANDS_BATCH_BY_ID.FAILURE,
  error,
});

export const getAllRounds = () => {
  return dispatch => {
    dispatch(getAllRoundsRequest());
    return axios
      .get(Pathes.RoundHistory.allRounds)
      .then(response => {
        const { status } = response;
        const message = 'Something went wrong';
        const data = response.data.filter(
          r =>
            ![
              ROUND_STATUSES.upcoming.code,
              ROUND_STATUSES.in_progress.code,
            ].includes(r.status)
        );
        if (status === 200) {
          dispatch(getAllRoundsSuccess(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getAllRoundsFailure(e.message)));
  };
};

export const getRoundsSummary = () => {
  return dispatch => {
    dispatch(getRoundsSummaryRequest());
    return axios
      .get(Pathes.RoundHistory.pastRoundStats)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getRoundsSummarySuccess(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getRoundsSummaryFailure(e.message)));
  };
};

export const getRoundCountriesById = fundraisingID => {
  return dispatch => {
    dispatch(getRoundCountriesByIdRequest());
    return axios
      .get(
        Pathes.RoundHistory.roundCountries +
          getQuery({ fundraising_id: fundraisingID })
      )
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getRoundCountriesByIdSuccess(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getRoundCountriesByIdFailure(e.message)));
  };
};

export const getRoundRepaymentProgressById = fundraisingID => {
  return dispatch => {
    dispatch(getRoundRepaymentProgressByIdRequest());
    return axios
      .get(Pathes.RoundHistory.roundRepaymentProgress(fundraisingID))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getRoundRepaymentProgressByIdSuccess(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getRoundRepaymentProgressByIdFailure(e.message)));
  };
};

export const getRoundLoanGroupsById = fundraisingID => {
  return dispatch => {
    dispatch(getRoundLoanGroupsByIdRequest());
    return axios
      .get(Pathes.RoundHistory.roundLoanGroupStatements(fundraisingID))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getRoundLoanGroupsByIdSuccess(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getRoundLoanGroupsByIdFailure(e.message)));
  };
};

export const getBrandsBatchById = (fundraisingId, params) => {
  return dispatch => {
    dispatch(getBrandsBatchByIdRequest());
    return axios
      .get(Pathes.RoundHistory.batchByID(fundraisingId) + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getBrandsBatchByIdSuccess(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getBrandsBatchByIdFailure(e.message)));
  };
};
