import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconReply } from '../../assets/icons/icon_reply_20.svg';
import { FileName } from '../../kit/FileName';
import { getComplaintDetail } from '../../store/actions/complaintActions';
import { prettyDate } from '../../helpers/utils';
import Preloader from '../../components/Preloader';
import s from './index.module.scss';

const ComplaintDetailModal = ({ id }) => {
  const dispatch = useDispatch();
  const { data: detail, loading } = useSelector(
    state => state.complaintStore.detail
  );

  useEffect(() => {
    dispatch(getComplaintDetail(id));
  }, []);

  return (
    <div className={s.wrapper}>
      {loading || !detail ? (
        <Preloader className={s.preloader} />
      ) : (
        <>
          <h1 className="f-26 f-500">Complaint #{detail.id}</h1>
          <div className={s.content}>
            <div className={s.left}>
              <div className={s.group}>
                <div className={s.subtitle}>Round number</div>
                <div className={s.value}>{detail.fundraising_name}</div>
              </div>
              <div className={s.group}>
                <div className={s.subtitle}>Created date</div>
                <div className={s.value}>{prettyDate(detail.created_at)}</div>
              </div>
              <div className={s.group}>
                <div className={s.subtitle}>Uploaded files</div>
                <div className={s.files}>
                  {detail.files.map(file => (
                    <FileName className={s.file} key={file.id} file={file} />
                  ))}
                </div>
              </div>
            </div>
            <div className={s.right}>
              <div className={s.complaint}>
                <div className={s.complaint_title}>Description</div>
                <div className={s.complaint_text}>{detail.description}</div>
              </div>
              {detail.answer && (
                <div className={s.reply}>
                  <IconReply className={s.reply_icon} />
                  <div className={s.reply_title}>
                    Reply from {detail.answer.from}
                  </div>
                  <div className={s.reply_text}>{detail.answer.answer}</div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ComplaintDetailModal;
