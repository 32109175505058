import { APP_LINKS } from '../../../../helpers/links';

export const TERMS = [
  {
    is_active: true,
    id: 'conservative',
    title: 'Group A',
    investor_annual_rate: 'Up to 12.4%',
    term: '6 months',
    flat_fee: 'Up to 1% per month',
    flat_fee_repayment: 'At the end of the term',
    downside_protection: 'Triple-secured',
    details_link: APP_LINKS.helpLoanTermsGroup('v3-group-a'),
  },
  {
    is_active: true,
    id: 'moderate',
    title: 'Group B',
    investor_annual_rate: 'Up to 25%',
    term: '6 months',
    flat_fee: '9% for 6 months',
    flat_fee_repayment: 'At the end of the term',
    downside_protection: 'Single-secured',
    details_link: APP_LINKS.helpLoanTermsGroup('v3-group-b'),
  },
  {
    is_active: false,
    id: 'growth',
    title: 'Group C',
    investor_annual_rate: '-',
    term: '-',
    flat_fee: '-',
    flat_fee_repayment: '-',
    downside_protection: '-',
    details_link: APP_LINKS.helpLoanTermsGroup('v3-group-c'),
  },
];
