import React from 'react';
import classnames from 'classnames';
import s from './index.module.scss';

export const AverageTime = ({ text }) => {
  const averageTime = Math.floor(Math.random() * 10) + 5;
  return (
    <div className={classnames(s.wrapper, 'single_xs f-500')}>
      <svg
        width="11"
        height="12"
        viewBox="0 0 11 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.23069 0.769531C4.20196 0.769531 3.19632 1.07459 2.34095 1.64612C1.48559 2.21766 0.818913 3.03001 0.425232 3.98044C0.0315506 4.93087 -0.0714544 5.9767 0.129243 6.98567C0.32994 7.99464 0.825324 8.92144 1.55275 9.64887C2.28018 10.3763 3.20698 10.8717 4.21595 11.0724C5.22492 11.2731 6.27075 11.1701 7.22118 10.7764C8.17161 10.3827 8.98396 9.71603 9.5555 8.86067C10.127 8.0053 10.4321 6.99967 10.4321 5.97093C10.4305 4.59192 9.882 3.26984 8.90689 2.29473C7.93178 1.31962 6.60971 0.771109 5.23069 0.769531ZM5.23069 10.132C4.4077 10.132 3.60319 9.888 2.9189 9.43077C2.23461 8.97354 1.70127 8.32366 1.38632 7.56332C1.07138 6.80297 0.988975 5.96631 1.14953 5.15913C1.31009 4.35196 1.7064 3.61052 2.28834 3.02857C2.87028 2.44663 3.61172 2.05032 4.4189 1.88977C5.22608 1.72921 6.06274 1.81161 6.82308 2.12656C7.58343 2.4415 8.23331 2.97484 8.69054 3.65913C9.14776 4.34343 9.39181 5.14794 9.39181 5.97093C9.39055 7.07414 8.95174 8.1318 8.17165 8.91189C7.39157 9.69197 6.3339 10.1308 5.23069 10.132ZM5.75083 5.84037V3.37023C5.75083 3.23228 5.69603 3.09998 5.59849 3.00243C5.50094 2.90489 5.36864 2.85009 5.23069 2.85009C5.09274 2.85009 4.96045 2.90489 4.8629 3.00243C4.76536 3.09998 4.71056 3.23228 4.71056 3.37023V5.97093C4.71063 6.05659 4.73173 6.14092 4.77201 6.21652L5.55222 7.6723C5.61736 7.79395 5.72815 7.88475 5.86023 7.92471C5.99231 7.96467 6.13485 7.95052 6.2565 7.88539C6.37815 7.82025 6.46894 7.70946 6.5089 7.57738C6.54886 7.44531 6.53472 7.30276 6.46958 7.18112L5.75083 5.84037Z"
          fill="#333333"
        />
      </svg>
      {text || `${averageTime} min`}
    </div>
  );
};
