import * as React from 'react';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import TopHeader from '../../components/TopHeader';
import { getBalance } from '../../store/actions/dashboardActions';
import {
  getAndRegisterVeriffStatuses,
  getTermsOfUseStatus,
} from '../../store/services/userServices';
import DesktopNavigation from '../../components/Navigation/DesktopNavigation';
// import TabletNavigation from '../../components/Navigation/TabletNavigation';
import MobileNavigation from '../../components/Navigation/MobileNavigation';
import AuthorizedFooter from '../../components/Footer/AuthorisedFooter';
import { logoutUser } from '../../store/actions/userActions';
import { getLastRoute } from '../../helpers/utils';
import Analytics from '../../analyticsGA';
import { setModals } from '../../store/actions/commonActions';
import { getReferralCode } from '../../store/actions/referralActions';
import { MODAL_TYPES } from '../../components/ModalProvider';
import { TermsAcceptWarning } from '../../components/Warnings/TermsAcceptWarning';
import { IncreasedIncomeBanner } from '../../containers/banners/IncreasedIncomeBanner';
import './index.scss';

class RootPage extends React.Component {
  constructor(props) {
    super(props);
    this.intervalID = null;
  }

  componentDidMount() {
    const { user, token, logoutUser, getBalance, getReferralCode, setModals } =
      this.props;
    if (!user || !token || typeof token === 'string') {
      return logoutUser();
    }

    const lastRoute = getLastRoute();
    Analytics.rootPageNavigation(lastRoute);

    getAndRegisterVeriffStatuses(user);

    getTermsOfUseStatus().then(res => {
      if (res.success) {
        res.data.has_active_loans &&
          res.data.amount > 0 &&
          res.data.status === 'pending' &&
          setModals({
            type: MODAL_TYPES.terms_of_use,
            amount: res.data.amount,
            onOutsideClick: true,
            disableCloseIcon: true,
          });
      }
    });

    // Getting updated balance every minute
    getBalance();
    this.intervalID = setInterval(
      () => user && !document.hidden && getBalance(),
      60000
    );

    getReferralCode();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  allowedRoutes(user) {
    const { routes } = this.props && this.props.route;
    return routes
      ? routes.filter(route => (route.auth ? route.auth(user) : true))
      : [];
  }

  render() {
    const { user, innerWidth } = this.props;

    return (
      <div className="root_page">
        {innerWidth >= 1380 ? <DesktopNavigation /> : <MobileNavigation />}
        {/* {innerWidth < 1380 && innerWidth >= 992 && <TabletNavigation />} */}
        {/* {innerWidth < 992 && <MobileNavigation />} */}
        <main id="main">
          <div className="root_page__content">
            <TopHeader />
            <div className="container-inner">
              <TermsAcceptWarning className="root_page__warning" />
            </div>
            <div className="container-inner">
              <IncreasedIncomeBanner />
            </div>
            {renderRoutes(this.allowedRoutes(user), { user })}
          </div>
          {innerWidth >= 1380 && <AuthorizedFooter />}
        </main>
        {innerWidth < 1380 && <AuthorizedFooter />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
  profile: state.profileStore.profile.data,
  token: state.userStore.token,
  innerWidth: state.commonStore.innerWidth,
});

const mapDispatchToProps = dispatch => ({
  getBalance: () => dispatch(getBalance()),
  setModals: modals => dispatch(setModals(modals)),
  logoutUser: () => dispatch(logoutUser()),
  getReferralCode: () => dispatch(getReferralCode()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RootPage);
