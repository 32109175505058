import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityList } from '../../store/actions/activityActions';
import { prettyFloatMoney } from '../../helpers/utils';
import Preloader from '../../components/Preloader';
import MiniFaqs, { MINIFAQ_SOURCES } from '../MiniFaqs';
import { RoundButton } from '../../components/UI/RoundButton';
import ActivityFilters from './filters';
import config from '../../config';
import { MyLink } from '../../components/MyLink';
import { SuspendResidenceWarning } from '../../components/Warnings/SuspendResidenceWarning';
import { USER_STATUSES } from '../../helpers/constants';
import { getUserProfile } from '../../store/actions/profileActions';
import AnalyticsGA from '../../analyticsGA';
import './index.scss';

const FILE_FORMATS = ['pdf', 'xlsx', 'csv'];

export const ActivityModule = () => {
  const dispatch = useDispatch();
  const { activityList, activityTypes } = useSelector(
    state => state.activityStore
  );
  const profile = useSelector(state => state.profileStore.profile.data);

  const [state, setState] = useState({
    page: 1,
    limit: 20,
    filters: {
      group_by_type: 1,
    },
  });

  useEffect(() => {
    const { page, limit, filters } = state;
    dispatch(getActivityList({ page, limit, ...filters }));
    dispatch(getUserProfile());
    AnalyticsGA.pageNavigation('Activity');
  }, []);

  const getNext = totalPages => {
    if (state.page < totalPages) {
      const nextPage = state.page + 1;
      dispatch(
        getActivityList(
          {
            ...state.filters,
            limit: state.limit,
            page: nextPage,
          },
          true
        )
      );

      return setState(prevState => ({
        ...prevState,
        limit: prevState.limit,
        page: nextPage,
      }));
    }
  };

  const onFilterSubmit = ({
    types,
    startDate,
    endDate,
    groupResults,
    loanNumber,
  }) => {
    const filters = {
      operation_types: types.length ? types.map(i => i.value) : null,
      start_date: startDate
        ? `${startDate.year}-${startDate.month}-${startDate.day}`
        : null,
      end_date: endDate
        ? `${endDate.year}-${endDate.month}-${endDate.day}`
        : null,
      loan: loanNumber || null,
      group_by_type: groupResults ? 1 : 0,
    };

    setState(prevState => ({
      ...prevState,
      page: 1,
      filters,
    }));

    dispatch(
      getActivityList({
        page: 1,
        limit: state.limit,
        ...filters,
      })
    );
  };

  const { page, filters } = state;
  const { data, loading } = activityList;
  const isEmpty = !(data && data.list.length);

  const isGrouped = !!filters?.group_by_type;

  return (
    <div className="activity-module">
      {profile?.status === USER_STATUSES.suspended && (
        <SuspendResidenceWarning className="activity-module__warning" />
      )}
      <ActivityFilters onSubmit={onFilterSubmit} />
      {!isEmpty && (
        <div className="activity-module__download">
          <div>Download results</div>
          <ul className="scr-circle-list">
            {FILE_FORMATS.map(format => (
              <li key={format}>
                <MyLink
                  href={`${config.apiURL}activities/download/${format}/${
                    filters
                      ? `?${qs.stringify(filters, {
                          strictNullHandling: true,
                          arrayFormat: 'comma',
                          skipNulls: true,
                        })}`
                      : ''
                  }`}
                  isExternal
                  newWindow
                >
                  {format}
                </MyLink>
              </li>
            ))}
          </ul>
        </div>
      )}

      {loading && !data ? (
        <Preloader className="activity-module__preloader" />
      ) : isEmpty ? (
        <div className="activity-module__empty f-16 f-400">
          {filters ? ' No results for selected filters' : ' No activity yet.'}
        </div>
      ) : (
        <div className="activity-module__table_wrap">
          <div className="activity-module__table_inner">
            <div className="activity-module__table">
              <div className="activity-module__thead">
                <div className="activity-module__thead-col">Date</div>
                <div className="activity-module__thead-col">Details</div>
                <div className="activity-module__thead-col">Amount</div>
              </div>

              <div className="activity-module__tbody">
                {data?.list.map((item, index) => (
                  <div
                    className="activity-module__tbody-row"
                    key={item.id || index}
                  >
                    <div className="activity-module__tbody-col">
                      {moment(item.created_at).format(
                        isGrouped ? 'DD MMMM YYYY' : 'DD MMMM YYYY, HH:mm'
                      )}
                    </div>
                    <div className="activity-module__tbody-col">
                      {item.details
                        ? item.details
                        : activityTypes
                        ? activityTypes[item.operation_type]
                        : ''}
                    </div>
                    <div className="activity-module__tbody-col">
                      <p className="tl">
                        {prettyFloatMoney(
                          isGrouped ? item.total_amount : item.amount
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="activity-module__more-btn">
            {data && page < data.total_pages && (
              <RoundButton
                type="button"
                label="Show more"
                fullWidth
                onClick={() => getNext(data.total_pages)}
              />
            )}
          </div>
        </div>
      )}
      <MiniFaqs source={MINIFAQ_SOURCES.activity} />
    </div>
  );
};
