import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getInactiveFounders } from '../../store/actions/founderActions';
import Preloader from '../../components/Preloader';
import FounderCard from '../../components/Cards/FounderCard';

class FoundersAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      page: 1,
      limit: 30,
    };
  }

  componentDidMount() {
    this.getFounders();
  }

  getFounders = (extraParams, isNext) => {
    const { page, limit } = this.state;
    this.props
      .getInactiveFounders({ page, limit, ...extraParams }, isNext)
      .finally(() =>
        this.setState(prevState => ({ ...prevState, loading: false }))
      );
  };

  getNext = totalPages => {
    if (this.state.page < totalPages) {
      const nextPage = this.state.page + 1;
      this.getFounders({ page: nextPage }, true);
      return this.setState(prevState => ({ ...prevState, page: nextPage }));
    }
  };

  render() {
    const { page, loading } = this.state;
    const { founders, innerWidth } = this.props;
    const haveFounders = !!(founders && founders.list.length);

    return (
      <div className={classnames('founders-all')}>
        <h2 className="f-26 f-500">
          Other awesome founders {haveFounders && `(${founders.total_count})`}
        </h2>

        <p className={classnames('founders-all__desc', 'f-16 f-400')}>
          The founders that participated in the previous rounds and were
          successfully funded.
        </p>

        {loading ? (
          <Preloader className="founders-all__preloader" />
        ) : haveFounders ? (
          <>
            <InfiniteScroll
              dataLength={Number(founders?.list.length) || 0}
              next={() => this.getNext(founders.total_pages)}
              hasMore={!founders || (founders && page < founders.total_pages)}
              scrollableTarget={innerWidth >= 1380 && 'main'}
              scrollThreshold={0.5}
              className="founders-all__list"
              loader={null}
            >
              {founders?.list.map(founder => (
                <FounderCard key={founder.id} founder={founder} hideFR />
              ))}
            </InfiniteScroll>
          </>
        ) : (
          <div className="founders-all__empty">
            <span className="founders-all__empty-title f-16 f-400">
              No founders yet.
            </span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  founders: state.founderStore.inactiveFounders,
  participatingFounders: state.founderStore.participatingFounders,
  innerWidth: state.commonStore.innerWidth,
});

const mapDispatchToProps = dispatch => ({
  getInactiveFounders: (params, isNext) =>
    dispatch(getInactiveFounders(params, isNext)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FoundersAll);
