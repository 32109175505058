import React from 'react';
import s from './index.module.scss';

const SepaInfoModal = () => (
  <div className={s.wrapper}>
    <h2 className="f-26-32 f-500" style={{ paddingRight: '20px' }}>
      What is SEPA and which countries are part of it?
    </h2>
    <p className="f-16-24" style={{ marginTop: '20px' }}>
      SEPA, short for Single Euro Payments Area, is a widely recognized term
      among Europeans and those with connections to Europe. It is an initiative
      of the European Union countries and governments that aims to simplify bank
      transfers denominated in EUR. As of January 2023, SEPA comprises 36
      member-states, including Austria, Belgium, Bulgaria, Cyprus, Croatia,
      Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece,
      Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta,
      Netherlands, Poland, Portugal, Romania, Slovenia, Slovakia, Spain, and
      Sweden. Additionally, the three EEA countries of Norway, Liechtenstein,
      and Iceland, along with Switzerland, Monaco, and Britain (despite Brexit),
      are also part of SEPA.
    </p>
  </div>
);

export default SepaInfoModal;
