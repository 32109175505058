export const SET_INVESTING_STATUS = 'SET_INVESTING_STATUS';
export const GET_INVESTING_STATUS = {
  REQUEST: 'GET_INVESTING_STATUS_REQUEST',
  SUCCESS: 'GET_INVESTING_STATUS_SUCCESS',
  FAILURE: 'GET_INVESTING_STATUS_FAILURE',
};

export const GET_INVESTING_RESULTS = {
  REQUEST: 'GET_INVESTING_RESULTS_REQUEST',
  SUCCESS: 'GET_INVESTING_RESULTS_SUCCESS',
  FAILURE: 'GET_INVESTING_RESULTS_FAILURE',
};

export const GET_INVESTING_LOAN_GROUPS = {
  REQUEST: 'GET_INVESTING_LOAN_GROUPS_REQUEST',
  SUCCESS: 'GET_INVESTING_LOAN_GROUPS_SUCCESS',
  FAILURE: 'GET_INVESTING_LOAN_GROUPS_FAILURE',
};

export const GET_PARTICIPATING_STARTUPS = {
  REQUEST: 'GET_PARTICIPATING_STARTUPS_REQUEST',
  SUCCESS: 'GET_PARTICIPATING_STARTUPS_SUCCESS',
  FAILURE: 'GET_PARTICIPATING_STARTUPS_FAILURE',
};

export const GET_INCREASED_INCOME_STATUS = {
  REQUEST: 'GET_INCREASED_INCOME_STATUS_REQUEST',
  SUCCESS: 'GET_INCREASED_INCOME_STATUS_SUCCESS',
  FAILURE: 'GET_INCREASED_INCOME_STATUS_FAILURE',
};

export const GET_ALLOCATED_REPAYMENT_SCHEDULE = {
  REQUEST: 'GET_ALLOCATED_REPAYMENT_SCHEDULE_REQUEST',
  SUCCESS: 'GET_ALLOCATED_REPAYMENT_SCHEDULE_SUCCESS',
  FAILURE: 'GET_ALLOCATED_REPAYMENT_SCHEDULE_FAILURE',
};

export const SET_FUNDRAISING_STATS = 'SET_FUNDRAISING_STATS';
export const SET_FUNDRAISING_COUNTRIES_STATS =
  'SET_FUNDRAISING_COUNTRIES_STATS';
