import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage } from '../../helpers/utils';

export const getLoanEarningsByFundraisingID = (id, groupId) => {
  return axios
    .get(Pathes.Loans.loanEarningsByFundraisingID(id, groupId))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => {
      return { success: false, message: e.message };
    });
};
