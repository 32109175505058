import React from 'react';
import classnames from 'classnames';
import s from './index.module.scss';

export const PageArrow = ({ direction, disabled }) => {
  return (
    <div className={classnames(s.arrow, disabled && s.arrow_disabled)}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="page_arrow"
        style={
          direction === 'left'
            ? { transform: 'rotate(180deg)' }
            : { transform: 'rotate(0)' }
        }
      >
        <circle
          cx="24"
          cy="24.5068"
          r="23.25"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M24.3594 19.4434L30 25.0839L24.3594 30.7245"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.9999 25.084L18 25.084"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
