import React from 'react';
import classnames from 'classnames';
import { prettyFloatMoney } from '../../../helpers/utils';
import WarningBlock from '../../../components/UI/WarningBlock';
import { RoundLink } from '../../../components/UI/RoundLink';
import { APP_LINKS } from '../../../helpers/links';
import s from './index.module.scss';

export const PaymentFailure = ({ amount }) => (
  <div className={s.wrapper}>
    <h1 className={classnames(s.title, 'f-26 f-500')}>
      Oops! Something went wrong.
    </h1>

    <WarningBlock withBorder className={s.message}>
      <div className="f-16 f-400">
        Your payment of{' '}
        <span className="f-500">{prettyFloatMoney(amount)}</span> was failed,
        please check and try again.
      </div>
    </WarningBlock>

    <div className={s.buttons}>
      <RoundLink
        path={APP_LINKS.dashboard}
        label="Go to dashboard"
        fillBackground
        className={s.button}
      />
      <RoundLink
        path={APP_LINKS.addFunds}
        label="Add cash"
        className={s.button}
      />
    </div>
  </div>
);
