import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NAV_ROUTES } from '../routes';
import { ReactComponent as Logo } from '../../../assets/images/logo_white.svg';
import { MyLink } from '../../MyLink';
import MenuUser from '../../MenuUser';
import { APP_LINKS } from '../../../helpers/links';
import './index.scss';

const DesktopNavigation = () => {
  const { referralCode } = useSelector(state => state.referralStore);
  const isActiveReferralBanner = !!referralCode.data?.is_referral_banner_active;

  return (
    <div className="desktop_navigation__container">
      <div className="desktop_navigation__top">
        <MyLink href={APP_LINKS.dashboard} className="desktop_navigation__logo">
          <Logo />
        </MyLink>
        <nav className="desktop_navigation">
          {NAV_ROUTES({ isActiveReferralBanner }).map(route => (
            <NavLink
              key={route.label}
              to={route.path}
              className="desktop_navigation__item f-16 f-400"
              activeClassName="desktop_navigation__item_active"
            >
              {route.label} {route.icon && <img src={route.icon} alt="icon" />}
            </NavLink>
          ))}
        </nav>
      </div>
      <MenuUser className="desktop_navigation__user" />
    </div>
  );
};

export default DesktopNavigation;
