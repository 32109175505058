import React from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import classnames from 'classnames';
import BalanceSelect from '../../UI/BalanceSelect';
import { RoundButton } from '../../UI/RoundButton';
import { MONEY_REGEX } from '../../../helpers/common';
import { MoneyField } from '../../UI/MoneyField';
import { RoundLink } from '../../UI/RoundLink';
import {
  isNumber,
  prettyFloatMoney,
  prettyMoney,
} from '../../../helpers/utils';
import { APP_LINKS } from '../../../helpers/links';
import { ACCOUNT_TYPES, CURRENCY } from '../../../helpers/constants';
import { useGetAllocationStatus } from '../../../hooks/useGetAllocationStatus';
import { getAmountPresets, validate } from './utils';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object().shape({
  from: Yup.object().shape({
    group: Yup.string().required(),
    type: Yup.string().required(),
  }),
  to: Yup.object().shape({
    group: Yup.string().required(),
    type: Yup.string().required(),
  }),
  amount: Yup.string()
    .matches(MONEY_REGEX, 'Enter valid amount')
    .required('Please enter amount'),
});

const AllocateCashForm = ({ preSelectedID, className, onSubmit }) => {
  const { accounts, noCash } = useGetAllocationStatus();
  const status = useSelector(state => state.investStore.investingStatus.data);
  const allocateInfo = useSelector(state => state.fundStore.allocateInfo.data);

  const preSelectedAccount =
    preSelectedID &&
    accounts &&
    accounts.find(item => item.group === preSelectedID);

  const fromAccount = accounts && accounts[0];
  const toAccount = preSelectedAccount || (accounts && accounts[1]);

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    validationSchema: VALIDATION_SCHEMA,
    onSubmit,
    validate,
    initialValues: {
      from: fromAccount,
      to: toAccount,
      amount: '',
    },
  });

  const disabled =
    !accounts ||
    (accounts &&
      !accounts.reduce((acc, item) => {
        acc += parseFloat(item.amount);
        return acc;
      }, 0));

  const cash =
    accounts.find(acc => acc.type === ACCOUNT_TYPES.cash)?.amount || 0;

  const renderPresets = (values, onClick) => {
    if (values.to.type === ACCOUNT_TYPES.loan) {
      const remaining = values.to.remaining_amount || 0;

      const presets = getAmountPresets(
        parseInt(cash > remaining ? remaining : cash),
        values.to.group
      );

      return (
        !!presets.filter(amount => amount >= 0).length && (
          <div className="alc-cash-form__presets">
            {presets.map((amount, index) => (
              <div
                key={index}
                onClick={() => onClick('amount', `${amount}`)}
                className={classnames(
                  'alc-cash-form__preset',
                  Number(values.amount) === amount && 'selected',
                  'f-20',
                  'f-400'
                )}
              >
                {prettyMoney(amount)}
              </div>
            ))}
          </div>
        )
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={classnames('alc-cash-form', className)}
    >
      <h2 className="alc-cash-form__title f-26 f-500">Transfer</h2>
      {noCash && (
        <RoundLink
          label="Add cash"
          path={APP_LINKS.addFunds}
          style={{ marginTop: '15px' }}
        />
      )}

      <div>
        <p className="alc-cash-form__from f-16">From</p>
        <BalanceSelect
          disabled={disabled}
          value={values.from}
          options={accounts}
          className="alc-cash-form__select"
          onSelect={option => {
            let toAccount = values.to;
            if (option.group === values.to.group) {
              toAccount = accounts.filter(
                item => item.group !== option.group
              )[0];
            }
            setValues({
              ...values,
              amount: '',
              from: option,
              to: toAccount,
            });
          }}
        />
      </div>

      <div>
        <p className="alc-cash-form__to f-16">To</p>
        <BalanceSelect
          value={values.to}
          options={
            accounts?.filter(
              item => ![values.from.group, values.to.group].includes(item.group)
            ) || []
          }
          className="alc-cash-form__select"
          onSelect={option => {
            setValues({ ...values, to: option, amount: '' });
          }}
        />
      </div>

      {status?.is_active && values.to && isNumber(values.to.remaining_amount) && (
        <p className="f-16 alc-cash-form__remaining">
          {values.to.remaining_amount < 0 ? (
            <span className="c-red">
              Remaining amount has been changed by some others investors. Please
              review and update loan groups. Maximum limit is {CURRENCY}&nbsp;
              {
                allocateInfo?.accounts.find(
                  acc => acc.group === values.to.group
                )?.remaining_amount
              }
            </span>
          ) : (
            <span>
              {values.to.remaining_amount > 0
                ? `Remains to be raised in ${
                    values.to.group_title
                  }: ${prettyFloatMoney(values.to.remaining_amount)}`
                : `The full amount in ${values.to.group_title} has been raised`}
            </span>
          )}
        </p>
      )}

      <MoneyField
        label="Amount"
        onChange={(_, value) => setFieldValue('amount', value)}
        value={values.amount}
        name="amount"
        onClear={() => setFieldValue('amount', '')}
        className="alc-cash-form__amount"
        error={errors.amount && touched.amount && errors.amount}
      />

      {renderPresets(values, setFieldValue)}

      <RoundButton
        type="submit"
        label="Transfer"
        onSubmit={handleSubmit}
        disabled={isSubmitting}
        className="alc-cash-form__submit hov"
        fillBackground
      />
    </form>
  );
};

export default AllocateCashForm;
