import React, { useEffect } from 'react';
import FoundersActive from './active';
import FoundersAll from './all';
import { scrollTop } from '../../helpers/utils';
import MiniFaqs, { MINIFAQ_SOURCES } from '../../containers/MiniFaqs';
import { BrandTabs } from '../../components/Tabs/BrandTabs';
import './index.scss';

const FoundersPage = () => {
  useEffect(() => {
    scrollTop();
  });

  return (
    <div className="founders-page">
      <div className="container-inner">
        <h1 className="founders-page__title f-42 f-500">Founders</h1>
        <BrandTabs />
        <div className="founders-page__sections">
          <FoundersActive />
          <FoundersAll />
        </div>
        <MiniFaqs source={MINIFAQ_SOURCES.founders} />
      </div>
    </div>
  );
};

export default FoundersPage;
