import * as React from 'react';
import AppleLogin from 'react-apple-login';
import config from '../../config';
import Analytics from '../../analyticsGA';

import { ReactComponent as IconApple } from '../../assets/icons/icon_apple.svg';

export const AppleButton = ({ label, isSignUp, onResponse, redirectURL }) => (
  <AppleLogin
    clientId={config.appleAppId}
    redirectURI={redirectURL}
    usePopup
    callback={onResponse}
    scope="email name"
    responseMode="query"
    render={renderProps => (
      <button
        type="button"
        className="social-networks__button apple_btn hov"
        onClick={() => {
          renderProps.onClick();
          isSignUp ? Analytics.signUpWithApple() : Analytics.signInWithApple();
        }}
      >
        <IconApple />
        <span className="f-16-24 f-500">{label}</span>
      </button>
    )}
  />
);
