import React from 'react';
import NotificationBlock from '../../NotificationBlock';
import InvestingInfo from '../../InvestingInfo';
import withScroll from '../../../hoc/withScroll';
import { NOTIFICATIONS } from '../../../components/Notification/list';
import InviteFriends from '../../../components/InviteFriends';
import { useCheckPlannedPayments } from '../../../components/Notification/hooks/useCheckPlannedPayments';
import './index.scss';

const InvHold = ({ status }) => {
  const { hasPlannedPayment } = useCheckPlannedPayments();
  const notifications = [NOTIFICATIONS.result_being_processed()];
  hasPlannedPayment && notifications.push(NOTIFICATIONS.october_weekends());

  return (
    <div className="inv-hold">
      <h1 className="f-42 f-500">Get ready for the results</h1>
      <NotificationBlock
        className="inv-hold__notifications"
        notifications={notifications}
      />
      <InvestingInfo
        status={status}
        skipResultFetch
        className="inv-hold__summary"
      />
      <InviteFriends analyticSource="onhold" />
    </div>
  );
};

export default withScroll(InvHold);
