import React, { useState } from 'react';
import moment from 'moment';
import * as qs from 'qs';
import { DATE_REQUEST } from '../../../helpers/common';
import axios from '../../../axios-api';
import { KeySelectField } from '../../../kit/form/select/KeySelect';
import { ReactComponent as IconDownload } from '../../../assets/icons/icon_download_20.svg';
import s from './index.module.scss';

const options = [
  { label: '2020', value: 2020 },
  { label: '2021', value: 2021 },
  { label: '2022', value: 2022 },
  { label: '2023', value: 2023 },
];

export const Filter = ({ year, onChange }) => {
  const [downloading, setDownloading] = useState(false);

  const onDownload = async () => {
    window.scrollTo(0, 0);
    setDownloading(true);
    const datetime = moment().format(DATE_REQUEST);
    const downloadUrl = `activities/investors/tax_report/download/${
      year
        ? `?${qs.stringify(
            { year },
            {
              strictNullHandling: true,
              arrayFormat: 'comma',
              skipNulls: true,
            }
          )}`
        : ''
    }`;

    axios
      .get(downloadUrl)
      .then(res => {
        const options = {
          filename: `Scramble Tax Report - ${year}.pdf`,
        };
        html2pdf().set(options).from(res.data).save();
      })
      .finally(() => setTimeout(() => setDownloading(false), 1200));
  };

  return (
    <div className={s.filters}>
      <KeySelectField
        name="year"
        value={year}
        options={options}
        placeholder="Select year *"
        className={s.select}
        onChange={val => onChange(val)}
      />

      {year && (
        <button type="button" onClick={onDownload} className={s.download}>
          <span>
            {downloading ? (
              'Downloading...'
            ) : (
              <span
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                Download PDF
                <IconDownload style={{ minWidth: '20px' }} />
              </span>
            )}
          </span>
        </button>
      )}
    </div>
  );
};
