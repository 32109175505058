export const GET_COMPLAINTS = {
  REQUEST: 'GET_COMPLAINTS_REQUEST',
  SUCCESS: 'GET_COMPLAINTS_SUCCESS',
  FAILURE: 'GET_COMPLAINTS_FAILURE',
};

export const GET_COMPLAINT_DETAIL = {
  REQUEST: 'GET_COMPLAINT_DETAIL_REQUEST',
  SUCCESS: 'GET_COMPLAINT_DETAIL_SUCCESS',
  FAILURE: 'GET_COMPLAINT_DETAIL_FAILURE',
};
