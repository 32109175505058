export const GET_ALL_ROUNDS = {
  REQUEST: 'GET_ALL_ROUNDS_REQUEST',
  SUCCESS: 'GET_ALL_ROUNDS_SUCCESS',
  FAILURE: 'GET_ALL_ROUNDS_FAILURE',
};

export const GET_ROUNDS_SUMMARY = {
  REQUEST: 'GET_ROUNDS_SUMMARY_REQUEST',
  SUCCESS: 'GET_ROUNDS_SUMMARY_SUCCESS',
  FAILURE: 'GET_ROUNDS_SUMMARY_FAILURE',
};

export const GET_ROUND_COUNTRIES_BY_ID = {
  REQUEST: 'GET_ROUND_COUNTRIES_BY_ID_REQUEST',
  SUCCESS: 'GET_ROUND_COUNTRIES_BY_ID_SUCCESS',
  FAILURE: 'GET_ROUND_COUNTRIES_BY_ID_FAILURE',
};

export const GET_ROUND_REPAYMENT_PROGRESS_BY_ID = {
  REQUEST: 'GET_ROUND_REPAYMENT_PROGRESS_BY_ID_REQUEST',
  SUCCESS: 'GET_ROUND_REPAYMENT_PROGRESS_BY_ID_SUCCESS',
  FAILURE: 'GET_ROUND_REPAYMENT_PROGRESS_BY_ID_FAILURE',
};

export const GET_ROUND_LOAN_GROUPS_BY_ID = {
  REQUEST: 'GET_ROUND_LOAN_GROUPS_BY_ID_REQUEST',
  SUCCESS: 'GET_ROUND_LOAN_GROUPS_BY_ID_SUCCESS',
  FAILURE: 'GET_ROUND_LOAN_GROUPS_BY_ID_FAILURE',
};

export const GET_BRANDS_BATCH_BY_ID = {
  REQUEST: 'GET_BRANDS_BATCH_BY_ID_REQUEST',
  SUCCESS: 'GET_BRANDS_BATCH_BY_ID_SUCCESS',
  FAILURE: 'GET_BRANDS_BATCH_BY_ID_FAILURE',
};
