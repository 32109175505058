import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { MyLink } from '../../../components/MyLink';
import { APP_LINKS } from '../../../helpers/links';
import Avatar from '../../../components/UI/Avatar';
import {
  getNameInitials,
  isParticipatingInvesting,
} from '../../../helpers/utils';
import { SuspendResidenceWarning } from '../../../components/Warnings/SuspendResidenceWarning';
import s from './index.module.scss';

export const SuspendedUsersView = () => {
  const { data: profile } = useSelector(state => state.profileStore.profile);
  const { data: status } = useSelector(
    state => state.investStore.investingStatus
  );

  return (
    <>
      <div className={s.header}>
        <div className={s.header__row}>
          <MyLink href={APP_LINKS.profile} className={s.avatar__link}>
            <Avatar
              src={profile.photo && profile.photo.file}
              size={65}
              alt={getNameInitials(profile.first_name, profile.last_name)}
              nameInitials={getNameInitials(
                profile.first_name,
                profile.last_name
              )}
              className={s.avatar}
              active={isParticipatingInvesting(status)}
            />
          </MyLink>
        </div>

        <div className={classnames('f-42 f-500', s.title)}>
          {profile.first_name
            ? `Hey ${profile.first_name} — Welcome to Scramble!`
            : 'Hi there! Nice to see you!'}
        </div>
      </div>

      <SuspendResidenceWarning className={s.suspended} />
    </>
  );
};
