import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import Preloader from '../../components/Preloader';
import { getTaxReport } from '../../store/actions/activityActions';
import { Filter } from './filter';
import { Table } from './table';
import s from './index.module.scss';

export const TaxReportModule = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(state => state.activityStore.taxReport);
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    dispatch(getTaxReport({ year }));
  }, [year]);

  const isEmpty = !data;

  return (
    <div className={s.activity_module}>
      {loading && !data ? (
        <Preloader className={s.activity_module__preloader} />
      ) : (
        <>
          <Filter
            year={year}
            onChange={selectedYear => setYear(selectedYear)}
          />
          {isEmpty ? (
            <div className={classnames(s.activity_module__empty, 'f-16 f-400')}>
              No tax report.
            </div>
          ) : (
            <Table data={data} />
          )}
        </>
      )}
    </div>
  );
};
