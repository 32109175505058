import React from 'react';
import { ReactComponent as Image } from './image.svg';
import { RoundLink } from '../../components/UI/RoundLink';
import { APP_LINKS } from '../../helpers/links';
import s from './index.module.scss';

const BonusInviteFriendsInvestingModal = () => {
  return (
    <div className={s.modal}>
      <div className={s.content}>
        <div className={s.image}>
          <Image />
        </div>
        <div className="f-22 f-500">
          More Friends, More Rewards: Up to 15% Extra Return!
        </div>

        <div className="f-16-24">
          Invite your friends to invest with Scramble and earn an amazing 15%
          extra return. The more friends you refer, the bigger your bonus!
        </div>

        <RoundLink
          fillBackground
          path={APP_LINKS.affiliate}
          label="Invite Friends"
        />
      </div>
    </div>
  );
};

export default BonusInviteFriendsInvestingModal;
