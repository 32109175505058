import React from 'react';
import classnames from 'classnames';
import { MyLink } from '../../../../../components/MyLink';
import Tooltip from '../../../../../components/Tooltip';
import s from './index.module.scss';

export const LoanGroupCard = ({ group, className }) => {
  return (
    <div className={classnames(s.card, className)}>
      <MyLink
        href={group.details_link}
        className={classnames(s.card__title, 'hov', 'f-26 f-500')}
      >
        {group.title}
      </MyLink>
      {group.is_active ? (
        <>
          <div className={s.card__block}>
            <div className="f-18 f-500">
              <span>Target annual return</span>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className={classnames(s.card__tooltip, 'f-16 f-400')}>
                    Target annual return calculations include reinvested
                    repayments. Reinvesting significantly boosts your returns
                    over time.
                  </div>
                )}
              />
            </div>
            <p className="f-18 f-500 tl">{group.investor_annual_rate}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">Term</div>
            <p className="f-18 f-500 tl">{group.info_term}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">Flat fee for 6 months</div>
            <p className="f-18 f-500 tl">{group.flat_fee}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">Extra flat fee beyond 6 months</div>
            <p className="f-18 f-500 tl">{group.extra_flat_fee}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">Downside protection</div>
            <p className="f-18 f-500 tl">{group.downside_protection}</p>
          </div>

          <MyLink
            href={group.details_link}
            className={classnames(s.card__link_details, 'f-500')}
          >
            See details
          </MyLink>
        </>
      ) : (
        <p className={classnames(s.card__unavailable, 'f-18 f-500')}>
          {group.title} is temporarily unavailable.
        </p>
      )}
    </div>
  );
};
