import React from 'react';
import { RoundButton } from '../../components/UI/RoundButton';
import config from '../../config';
import './index.scss';

const ReferralEmailModal = ({ values, onSubmit, errors, inviteLink }) => {
  const { name } = values;
  const link = `${config.apiURL}invitation_email_preview/?first_name=${
    name || 'Hey!'
  }&invitation_link=${inviteLink}`;

  return (
    <div className="referral_email_modal">
      <div className="referral_email_modal__inner">
        <iframe
          title="affiliate_email_modal"
          id="affiliate_email_modal"
          src={link}
        />
      </div>

      <RoundButton
        label="Send email"
        fillBackground
        fullWidth
        className="referral_email_modal__send"
        disabled={!values.email || (errors && errors.email)}
        onClick={onSubmit}
      />
    </div>
  );
};

export default ReferralEmailModal;
