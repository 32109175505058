import * as React from 'react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import config from '../../config';
import { ReactComponent as IconGoogle } from '../../assets/icons/icon_google_48.svg';

export const GoogleButton = props => (
  <GoogleOAuthProvider clientId={config.googleAppId}>
    <Button {...props} />
  </GoogleOAuthProvider>
);

const Button = props => {
  const { label, onGoogleResponseSuccess, onGoogleResponseFailure } = props;

  const login = useGoogleLogin({
    onSuccess: onGoogleResponseSuccess,
    onError: onGoogleResponseFailure,
  });

  return (
    <button
      type="button"
      className="social-networks__button google_btn hov"
      onClick={() => login()}
    >
      <IconGoogle />
      <span className="f-16-24 f-500">{label}</span>
    </button>
  );
};
