import { METADATA } from '../../helpers/metadata';
import {
  GET_ALL_ROUNDS,
  GET_BRANDS_BATCH_BY_ID,
  GET_ROUND_COUNTRIES_BY_ID,
  GET_ROUND_LOAN_GROUPS_BY_ID,
  GET_ROUND_REPAYMENT_PROGRESS_BY_ID,
  GET_ROUNDS_SUMMARY,
} from '../types/roundHistoryTypes';

const initialState = {
  allRounds: {
    ...METADATA.default,
    data: null,
  },
  pastRoundStats: { ...METADATA.default, data: null },
  countries: { ...METADATA.default, data: null },
  repaymentProgress: { ...METADATA.default, data: null },
  loanGroups: { ...METADATA.default, data: null },
  brands: { ...METADATA.default, data: null },
};

const roundHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ROUNDS.REQUEST:
      return {
        ...state,
        allRounds: { ...state.allRounds, ...METADATA.request },
      };
    case GET_ALL_ROUNDS.SUCCESS:
      return {
        ...state,
        allRounds: { ...METADATA.success, data: action.payload },
      };
    case GET_ALL_ROUNDS.FAILURE:
      return {
        ...state,
        allRounds: {
          ...state.allRounds,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_ROUNDS_SUMMARY.REQUEST:
      return {
        ...state,
        pastRoundStats: { ...state.pastRoundStats, ...METADATA.request },
      };
    case GET_ROUNDS_SUMMARY.SUCCESS:
      return {
        ...state,
        pastRoundStats: { ...METADATA.success, data: action.payload },
      };
    case GET_ROUNDS_SUMMARY.FAILURE:
      return {
        ...state,
        pastRoundStats: {
          ...state.pastRoundStats,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_ROUND_COUNTRIES_BY_ID.REQUEST:
      return {
        ...state,
        countries: { data: null, ...METADATA.request },
      };
    case GET_ROUND_COUNTRIES_BY_ID.SUCCESS:
      return {
        ...state,
        countries: { ...METADATA.success, data: action.payload },
      };
    case GET_ROUND_COUNTRIES_BY_ID.FAILURE:
      return {
        ...state,
        countries: {
          ...METADATA.error,
          error: action.error,
          data: null,
        },
      };
    case GET_ROUND_REPAYMENT_PROGRESS_BY_ID.REQUEST:
      return {
        ...state,
        repaymentProgress: { data: null, ...METADATA.request },
      };
    case GET_ROUND_REPAYMENT_PROGRESS_BY_ID.SUCCESS:
      return {
        ...state,
        repaymentProgress: { ...METADATA.success, data: action.payload },
      };
    case GET_ROUND_REPAYMENT_PROGRESS_BY_ID.FAILURE:
      return {
        ...state,
        repaymentProgress: {
          ...METADATA.error,
          error: action.error,
          data: null,
        },
      };
    case GET_ROUND_LOAN_GROUPS_BY_ID.REQUEST:
      return {
        ...state,
        loanGroups: { data: null, ...METADATA.request },
      };
    case GET_ROUND_LOAN_GROUPS_BY_ID.SUCCESS:
      return {
        ...state,
        loanGroups: { ...METADATA.success, data: action.payload },
      };
    case GET_ROUND_LOAN_GROUPS_BY_ID.FAILURE:
      return {
        ...state,
        loanGroups: {
          ...METADATA.error,
          error: action.error,
          data: null,
        },
      };
    case GET_BRANDS_BATCH_BY_ID.REQUEST:
      return {
        ...state,
        brands: { data: null, ...METADATA.request },
      };
    case GET_BRANDS_BATCH_BY_ID.SUCCESS:
      return {
        ...state,
        brands: { ...METADATA.success, data: action.payload },
      };
    case GET_BRANDS_BATCH_BY_ID.FAILURE:
      return {
        ...state,
        brands: {
          ...METADATA.error,
          error: action.error,
          data: null,
        },
      };
    default:
      return state;
  }
};

export default roundHistoryReducer;
