import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { APP_LINKS } from './helpers/links';
import RootPage from './pages/RootPage';
import SignUpPage from './pages/SignUpPage';
import SignInPage from './pages/SignInPage';
import EmailVerificationStatusPage from './pages/EmailVerificationStatusPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import RecoverPasswordPage from './pages/RecoverPasswordPage';
import DevPage from './pages/DevPage';
import DashboardPage from './pages/DashboardPage';
import PageNotFound from './pages/PageNotFound';
import FundsPage from './pages/FundsPage';
import FundsAddPage from './pages/FundsAddPage';
import FundsWithdrawPage from './pages/FundsWithdrawPage';
import ProfilePage from './pages/ProfilePage';
import ActivityPage from './pages/ActivityPage';
import LoanTermsPage from './pages/LoanTermsPage';
import LoanTermsGroupDetailPage from './pages/LoanTermsGroupDetailPage';
import InvestingPage from './pages/InvestingPage';
import FounderDetailPage from './pages/FounderDetailPage';
import TermsOfUsePage from './pages/TermsOfUsePage';
import RoundHistoryPage from './pages/RoundHistoryPage';
// import InvestorsPage from './pages/InvestorsPage';
import UnsubscribePage from './pages/UnsubscribePage';
import HelpPage from './pages/HelpPage';
import LoansPage from './pages/LoansPage';
import LoansLoanPage from './pages/LoansLoanPage';
import LoansSchedulePage from './pages/LoansSchedulePage';
import AffiliatePage from './pages/AffiliatePage';
import StartupsPage from './pages/StartupsPage';
import FoundersPage from './pages/FoundersPage';
import StartupDetailPage from './pages/StartupDetailPage';
import ClientQuestionsPage from './pages/ClientQuestionsPage';
import TwoFactorAuthPage from './pages/TwoFactorAuthPage';
import FundsBankAccountsPage from './pages/FundsBankDetailsPage';
import BrandsRootPage from './pages/BrandsRootPage';
import FundsAddResultPage from './pages/FundsAddResultPage';
import ComplaintsPage from './pages/ComplaintsPage';
import ComplaintAddPage from './pages/ComplaintAddPage';
import ReportsPage from './pages/ReportsPage';
import TaxReportPage from './pages/TaxReportPage';
import BlogPage from './pages/BlogPage';
import BlogDetailPage from './pages/BlogDetailPage';

const ROUTES = [
  {
    path: APP_LINKS.signUp,
    component: SignUpPage,
    pageTitle: 'Scramble - Sign Up',
    exact: true,
  },
  {
    path: APP_LINKS.signIn,
    component: SignInPage,
    pageTitle: 'Scramble - Sign In',
    exact: true,
  },
  {
    path: APP_LINKS.twoFactorAuth,
    component: TwoFactorAuthPage,
    pageTitle: 'Scramble - Two Factor Authentication',
    exact: true,
  },
  {
    path: APP_LINKS.emailVerificationStatus(),
    component: EmailVerificationStatusPage,
    pageTitle: 'Scramble - Email Verification Status',
    exact: true,
  },
  {
    path: '/unsubscribe/:token',
    component: UnsubscribePage,
    pageTitle: 'Scramble - Unsubscribe from emails',
    exact: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordPage,
    pageTitle: 'Scramble - Forgot Password',
    exact: true,
  },
  {
    path: '/password/set/:key',
    component: RecoverPasswordPage,
    pageTitle: 'Scramble - Password Recovery',
    exact: true,
  },
  {
    path: '/legal/terms-of-use/',
    component: TermsOfUsePage,
    pageTitle: 'Scramble - Terms of Use',
    exact: true,
  },
  {
    path: APP_LINKS.root,
    component: RootPage,
    routes: [
      {
        path: APP_LINKS.root,
        render: () => <Redirect to={APP_LINKS.dashboard} />,
        pageTitle: 'Scramble - Dashboard Page',
        exact: true,
      },
      {
        path: APP_LINKS.dashboard,
        component: DashboardPage,
        pageTitle: 'Scramble - Dashboard',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.investing,
        component: InvestingPage,
        pageTitle: 'Scramble - Investing',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.roundHistory,
        component: RoundHistoryPage,
        pageTitle: 'Scramble - Round History Page',
        exact: true,
      },
      {
        path: APP_LINKS.affiliate,
        component: AffiliatePage,
        pageTitle: 'Scramble - Affiliate Page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.profile,
        component: ProfilePage,
        pageTitle: 'Profile Page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.founderDetail(':id'),
        component: FounderDetailPage,
        pageTitle: 'Scramble - Founder Detail page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.startupDetail(':slug'),
        component: StartupDetailPage,
        pageTitle: 'Scramble - Startup Detail page',
        auth: user => !!user,
        exact: true,
      },
      // {
      //   path: APP_LINKS.investors,
      //   component: InvestorsPage,
      //   pageTitle: 'Scramble - Investors page',
      //   auth: user => !!user,
      //   exact: true,
      // },
      {
        path: APP_LINKS.cash,
        component: FundsPage,
        auth: user => !!user,
        pageTitle: 'Funds',
        routes: [
          {
            path: APP_LINKS.cash,
            render: () => <Redirect to={APP_LINKS.addFunds} />,
            pageTitle: 'Scramble - Allocate Page',
            exact: true,
          },
          {
            path: APP_LINKS.addFunds,
            component: FundsAddPage,
            pageTitle: 'Scramble - Cash',
            exact: true,
          },
          {
            path: APP_LINKS.addFundsResult,
            component: FundsAddResultPage,
            pageTitle: 'Scramble - Add cash',
            exact: true,
          },
          {
            path: APP_LINKS.withdraw,
            component: FundsWithdrawPage,
            pageTitle: 'Scramble - Withdraw',
            exact: true,
          },
          {
            path: APP_LINKS.bankAccounts,
            component: FundsBankAccountsPage,
            pageTitle: 'Scramble - Bank accounts',
            exact: false,
          },
        ],
      },
      {
        path: APP_LINKS.reports,
        component: ReportsPage,
        auth: user => !!user,
        pageTitle: 'Scramble - Reports',
        routes: [
          {
            path: APP_LINKS.reports,
            render: () => <Redirect to={APP_LINKS.activity} />,
            pageTitle: 'Scramble - Activity',
            exact: true,
          },
          {
            path: APP_LINKS.activity,
            component: ActivityPage,
            pageTitle: 'Scramble - Activity',
            auth: user => !!user,
            exact: true,
          },
          {
            path: APP_LINKS.taxReports,
            component: TaxReportPage,
            pageTitle: 'Scramble - Tax Report',
            auth: user => !!user,
            exact: true,
          },
        ],
      },
      {
        path: APP_LINKS.brandsRoot,
        component: BrandsRootPage,
        auth: user => !!user,
        pageTitle: 'Scramble - Brands',
        routes: [
          {
            path: APP_LINKS.brandsRoot,
            render: () => <Redirect to={APP_LINKS.startups} />,
            pageTitle: 'Scramble - Brands',
            exact: true,
          },
          {
            path: APP_LINKS.startups,
            component: StartupsPage,
            pageTitle: 'Scramble - Brands',
            exact: true,
          },
          {
            path: APP_LINKS.founders,
            component: FoundersPage,
            pageTitle: 'Scramble - Founders',
            exact: true,
          },
        ],
      },
      {
        path: APP_LINKS.helpLoanTerms(':version?'),
        component: LoanTermsPage,
        pageTitle: 'Scramble - Loan Terms',
        exact: true,
      },
      {
        path: APP_LINKS.helpLoanTermsGroup(),
        component: LoanTermsGroupDetailPage,
        pageTitle: 'Scramble - Loan Terms Group Detail',
        exact: true,
      },
      {
        path: '/help/:slugLevelOne?/:slugLevelTwo?/:slugLevelThree?',
        component: HelpPage,
        pageTitle: 'Scramble - Help Page',
        exact: true,
      },
      {
        path: APP_LINKS.help,
        component: HelpPage,
        pageTitle: 'Scramble - Help Page',
        exact: true,
      },
      {
        path: APP_LINKS.loans,
        component: LoansPage,
        pageTitle: 'Loans',
        auth: user => !!user,
        routes: [
          {
            path: APP_LINKS.loans,
            render: () => <Redirect to={APP_LINKS.loansList} />,
            pageTitle: 'Scramble - Loans Page',
            exact: true,
          },
          {
            path: APP_LINKS.loansList,
            component: LoansLoanPage,
            pageTitle: 'Scramble - Loans Page',
            exact: true,
          },
          {
            path: APP_LINKS.loansSchedule,
            component: LoansSchedulePage,
            pageTitle: 'Scramble - Repayments Schedule',
            exact: true,
          },
        ],
      },
      {
        path: '/help',
        component: HelpPage,
        pageTitle: 'Help page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.clientQuestions,
        component: ClientQuestionsPage,
        pageTitle: 'Scramble - Client Questions',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.complaints,
        component: ComplaintsPage,
        pageTitle: 'Scramble - Complaints',
        exact: true,
      },
      {
        path: APP_LINKS.complaintCreate,
        component: ComplaintAddPage,
        pageTitle: 'Scramble - Complaint Create',
        exact: true,
      },
      {
        path: APP_LINKS.blog,
        component: BlogPage,
        pageTitle: 'Scramble - Blog',
        exact: true,
      },
      {
        path: APP_LINKS.blogDetail(),
        component: BlogDetailPage,
        pageTitle: 'Scramble - Blog Detail',
        exact: true,
      },
      {
        path: '/dev',
        component: DevPage,
        pageTitle: 'Development Page - To be removed',
        exact: true,
      },
      {
        path: '**',
        component: PageNotFound,
        pageTitle: 'Not found',
      },
    ],
  },
];

export default ROUTES;
