import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { APP_LINKS } from '../../helpers/links';
import { RoundLink } from '../../components/UI/RoundLink';
import { getComplaints } from '../../store/actions/complaintActions';
import { prettyDate } from '../../helpers/utils';
import { removeComplaint } from '../../store/services/complaintServices';
import { setModals } from '../../store/actions/commonActions';
import { MODAL_TYPES } from '../../components/ModalProvider';
import Notify from '../../components/Notification';
import { ReactComponent as IconTrash } from '../../assets/icons/icon_trash_red_22.svg';
import s from './index.module.scss';

const ComplaintsPage = () => {
  const dispatch = useDispatch();
  const { data: complaintList } = useSelector(
    state => state.complaintStore.complaintList
  );
  const isEmpty = !complaintList?.list.length;

  useEffect(() => {
    dispatch(getComplaints());
  }, []);

  const onRemove = id => {
    dispatch(
      setModals({
        type: MODAL_TYPES.complaint_remove,
        onDelete: async () => {
          const res = await removeComplaint(id);
          if (res?.success) {
            dispatch(getComplaints());
            dispatch(setModals([]));
            Notify.success({ text: 'Removed successfully' });
          }
        },
      })
    );
  };

  const onComplaintClick = id => {
    dispatch(setModals({ type: MODAL_TYPES.complaint_detail, id }));
  };

  return (
    <div className={s.page}>
      <div className="container-inner">
        <div className={s.wrapper}>
          <h1 className="f-42 f-500">Investor complaints</h1>
          {isEmpty ? (
            <div className={classnames(s.empty, 'f-20')}>
              You don’t have any complaints. Please create one.
            </div>
          ) : (
            <div className={s.content}>
              <div className={s.table__wrapper}>
                <div className={s.table__inner}>
                  <div className={s.table}>
                    <div className={s.table__thead}>
                      <div className={s.table__thead_col}>Round number</div>
                      <div className={s.table__thead_col}>Status</div>
                      <div className={s.table__thead_col}>Created date</div>
                      <div className={s.table__thead_col}>Actions</div>
                    </div>

                    <div className={s.table__tbody}>
                      {complaintList.list.map(item => (
                        <div
                          className={classnames(s.table__tbody_row, 'hov')}
                          key={item.id}
                          onClick={() => onComplaintClick(item.id)}
                        >
                          <div className={s.table__tbody_col}>
                            {item.fundraising_name}
                          </div>
                          <div className={s.table__tbody_col}>
                            <div className={s.status}>
                              <span
                                className={item.review_status}
                                style={{
                                  textTransform: 'capitalize',
                                  color: '#9E98A3',
                                }}
                              >
                                {item.status}
                              </span>
                            </div>
                          </div>
                          <div className={s.table__tbody_col}>
                            {prettyDate(item.created_at)}
                          </div>
                          <div className={s.table__tbody_col}>
                            <div className={s.controls}>
                              <button
                                type="button"
                                onClick={e => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  onRemove(item.id);
                                }}
                                className={classnames(
                                  s.button,
                                  s.button_remove
                                )}
                              >
                                <IconTrash />
                                <span>Delete</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <RoundLink
          path={APP_LINKS.complaintCreate}
          className={s.button_add}
          label="Add a new one"
        />
      </div>
    </div>
  );
};
export default ComplaintsPage;
