import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationBlock from '../../NotificationBlock';
import { RoundButton } from '../../../components/UI/RoundButton';
import withScroll from '../../../hoc/withScroll';
import { getFundraisingStats } from '../../../store/actions/investActions';
import Dropdown from '../../../components/Dropdown';
import { LOAN_GROUPS, USER_STATUSES } from '../../../helpers/constants';
import AnalyticsGA from '../../../analyticsGA';
import { NOTIFICATIONS } from '../../../components/Notification/list';
import { APP_LINKS } from '../../../helpers/links';
import { MyLink } from '../../../components/MyLink';
import WarningBlock from '../../../components/UI/WarningBlock';
import { getUserProfile } from '../../../store/actions/profileActions';
import { ParticipatingStartupsList } from '../Batches/Startup/participate';
import {
  hasRequiredPersonalSectionFields,
  hasRequiredProfileSectionFields,
  isSubmittedVeriff,
  isVerifiedEmail,
  isVerifiedPhoneNumber,
} from '../../../helpers/user';
import { LoanTerms } from '../../../components/LoanTerms';
import { ChooseStartups } from '../../../components/ChooseStartups';
import { MinInvestAmountWarning } from '../../../components/Warnings/MinInvestAmountWarning';
import { SuspendResidenceWarning } from '../../../components/Warnings/SuspendResidenceWarning';
import { InvestingProgressBar } from '../../../components/InvestingProgressBar';
import { InfluencerVideo } from '../../../components/InfluencerVideo';
import { setModals } from '../../../store/actions/commonActions';
import { MODAL_TYPES } from '../../../components/ModalProvider';
import {
  CALCULATOR_TYPES,
  LoanCalculator,
} from '../../../components/LoanCalculator';
import './index.scss';
import s from '../../../components/LoanCalculator/index.module.scss';
import { useCheckPlannedPayments } from '../../../components/Notification/hooks/useCheckPlannedPayments';
import { BonusBanner } from '../../BonusProgram/banner';

const InvStartProcess = ({ status, setStep, disabled }) => {
  const dispatch = useDispatch();
  const [groupID, setGroupID] = useState(LOAN_GROUPS.conservative.id);
  const { fundraisingStats } = useSelector(state => state.investStore);
  const { veriffStatusCode } = useSelector(state => state.profileStore);
  const { fundraisingDates } = useSelector(state => state.dashboardStore);
  const profile = useSelector(state => state.profileStore.profile.data);
  const { hasPlannedPayment } = useCheckPlannedPayments();

  const hasVerifiedPhone = isVerifiedPhoneNumber(profile);

  useEffect(() => {
    dispatch(getFundraisingStats());
    dispatch(getUserProfile());
  }, []);

  const onJoinRound = () => {
    setStep(1);
  };

  const onCalculate = groupID => {
    setGroupID(groupID || LOAN_GROUPS.conservative.id);
    const el = document.getElementById('calculator');
    el && el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const openPhoneVerifyModal = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.info_modal,
        title: 'Your phone number not verified',
        desc: 'You can’t join the round please verify your phone number in profile page to start investing.',
        redirectLabel: 'Verify',
        redirectPath: APP_LINKS.profile + '/#phone_verification',
      })
    );
  };

  const hasReachedGoal =
    fundraisingStats.currently_allocated >= fundraisingStats.total_goal;
  const notifications = [];

  const joinDisabled =
    disabled || hasReachedGoal || profile?.status === USER_STATUSES.suspended;

  !hasReachedGoal &&
    notifications.push(NOTIFICATIONS.loan_disbursement_starts(status.end_date));
  // hasReachedGoal
  //   ? notifications.push(NOTIFICATIONS.founders_reached_goal())
  //   : notifications.push(
  //       NOTIFICATIONS.loan_disbursement_starts(status.end_date)
  //     );

  !isVerifiedPhoneNumber(profile) &&
    notifications.push(NOTIFICATIONS.verify_phone_number_to_join_round());

  !isVerifiedEmail(profile) && notifications.push(NOTIFICATIONS.verify_email());

  hasPlannedPayment && notifications.push(NOTIFICATIONS.october_weekends());

  fundraisingDates?.id === 101 &&
    fundraisingDates?.is_active &&
    notifications.push(NOTIFICATIONS.regular_top_ups());

  return (
    <div className="inv-start-process">
      <h1 className="f-42 f-500">
        {hasReachedGoal
          ? 'Round expected to be enlarged'
          : 'Participate in the round'}
      </h1>

      {profile?.country_code === 'US' &&
        status?.min_investment_amount === 1000 && (
          <MinInvestAmountWarning className="inv-start-process__warning-header" />
        )}

      {profile &&
        (profile.status === USER_STATUSES.verified ? (
          <NotificationBlock
            className="inv-start-process__notifications"
            notifications={notifications}
          />
        ) : profile?.status === USER_STATUSES.suspended ? (
          <SuspendResidenceWarning className="inv-start-process__suspend_top" />
        ) : (
          <WarningBlock
            className="inv-start-process__warning-header"
            withBorder
          >
            {hasRequiredPersonalSectionFields(profile) &&
            hasRequiredProfileSectionFields(profile) &&
            isSubmittedVeriff(veriffStatusCode) ? (
              <div className="f-16 f-400">
                Investing is available only to verified investors. Your profile
                information is being checked, it may take up to two business
                days.
              </div>
            ) : (
              <div className="f-16 f-400">
                Complete the verification process to start investing.{' '}
                <MyLink href={APP_LINKS.dashboard} className="c-grey">
                  Complete verification&nbsp;→
                </MyLink>
              </div>
            )}
          </WarningBlock>
        ))}

      <BonusBanner className={s.bonus} />

      {status.is_active && (
        <InvestingProgressBar className="inv-start-process__progress" />
      )}

      <div className="inv-start-process__buttons">
        {joinDisabled && !hasVerifiedPhone ? (
          <RoundButton
            type="button"
            label="Join the round"
            className="inv-start-process__start-btn"
            onClick={openPhoneVerifyModal}
            fillBackground
          />
        ) : (
          <RoundButton
            type="button"
            label="Join the round"
            className="inv-start-process__start-btn"
            onClick={() => {
              AnalyticsGA.investingJoinRound('header');
              onJoinRound();
            }}
            fillBackground
            disabled={joinDisabled}
          />
        )}
      </div>

      <Dropdown
        title="Consumer Goods Brands batch"
        className="inv-start-process__founders"
        defaultShow
        disableToggle
      >
        <ParticipatingStartupsList
          onMoreDetails={startup => {
            AnalyticsGA.investingPreviewBrand(startup?.name);
          }}
        />
      </Dropdown>

      {joinDisabled && !hasVerifiedPhone ? (
        <RoundButton
          type="button"
          label="Join the round"
          className="inv-start-process__founders_button"
          onClick={openPhoneVerifyModal}
          fillBackground
          fullWidth
        />
      ) : (
        <RoundButton
          type="button"
          label="Join the round"
          className="inv-start-process__founders_button"
          onClick={() => {
            AnalyticsGA.investingJoinRound('under_brands');
            onJoinRound();
          }}
          fillBackground
          fullWidth
          disabled={joinDisabled}
        />
      )}

      <LoanTerms onCalculate={onCalculate} />

      {/* <LoanCalculator */}
      {/*   groupID={groupID} */}
      {/*   setGroupID={setGroupID} */}
      {/*   onJoin={onJoinRound} */}
      {/*   joinDisabled={joinDisabled} */}
      {/*   hasVerifiedPhone={hasVerifiedPhone} */}
      {/*   openPhoneVerifyModal={openPhoneVerifyModal} */}
      {/*   status={status} */}
      {/*   onChange={type => */}
      {/*     type === CALCULATOR_TYPES.long_term && setGroupID(null) */}
      {/*   } */}
      {/* /> */}

      <ChooseStartups />

      {/* <InfluencerVideo /> */}

      <div className="inv-start-process__bottom">
        {profile?.status === USER_STATUSES.suspended ? (
          <SuspendResidenceWarning className="inv-start-process__suspend_bottom" />
        ) : (
          profile?.status !== USER_STATUSES.verified && (
            <WarningBlock className="inv-start-process__warning-note">
              {hasRequiredPersonalSectionFields(profile) &&
              hasRequiredProfileSectionFields(profile) &&
              isSubmittedVeriff(veriffStatusCode) ? (
                <div className="f-16 f-400">
                  Investing is available only to verified investors. Your
                  profile information is being checked, it may take up to two
                  business days.
                </div>
              ) : (
                <div className="f-16 f-400">
                  Complete the verification process to start investing.{' '}
                  <MyLink href={APP_LINKS.dashboard} className="c-grey">
                    Complete verification&nbsp;→
                  </MyLink>
                </div>
              )}
            </WarningBlock>
          )
        )}

        {joinDisabled && !hasVerifiedPhone ? (
          <RoundButton
            type="button"
            label="Join the round"
            onClick={openPhoneVerifyModal}
            fillBackground
            fullWidth
          />
        ) : (
          <RoundButton
            type="button"
            label="Join the round"
            onClick={() => {
              AnalyticsGA.investingJoinRound('footer');
              onJoinRound();
            }}
            fillBackground
            fullWidth
            disabled={joinDisabled}
          />
        )}
      </div>
    </div>
  );
};

export default withScroll(InvStartProcess);
