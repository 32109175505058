import * as React from 'react';
import * as classnames from 'classnames';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { Veriff } from '@veriff/js-sdk';
import { createVeriffFrame } from '@veriff/incontext-sdk';
import config from '../../config';
import AnalyticsGA from '../../analyticsGA';
import { APP_LINKS } from '../../helpers/links';
import { setUserVeriffSkipReason } from '../../store/services/userServices';
import { MyLink } from '../MyLink';
import './index.scss';

export const SOURCES = {
  registration: 'registration',
  wizard: 'wizard',
  profile: 'profile',
};

export const SKIP_REASON_TYPES = {
  skipped_later: 'skipped_later',
  skipped_dont_want: 'skipped_dont_want',
};

class VerifyForm extends React.Component {
  constructor(props) {
    super(props);
    this.veriff = this.initVeriff();
  }

  componentDidMount() {
    if (this.veriff) {
      this.setVeriffParams();
      this.mountVeriff();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { user } = this.props;
    if (
      this.veriff &&
      user &&
      (user.first_name !== prevProps.user?.first_name ||
        user.last_name !== prevProps.user?.last_name)
    ) {
      this.setVeriffParams();
      this.mountVeriff();
    }
  }

  initVeriff = () => {
    const { dispatch, source, onVeriffEvent } = this.props;
    return Veriff({
      host: config.veriffHostURL,
      apiKey: config.veriffApiKey,
      parentId: 'veriff-root',
      onSession(err, response) {
        const chat = document.getElementById('tidio-chat');
        if (chat) {
          chat.style.display = 'none';
        }
        createVeriffFrame({
          url: response.verification.url,
          onEvent: msg => {
            console.log('onEvent', err, response);
            const chat = document.getElementById('tidio-chat');
            if (chat) {
              chat.style.display = 'unset';
            }
            AnalyticsGA.veriffPass();
            onVeriffEvent && onVeriffEvent(msg);
            dispatch(push(APP_LINKS.dashboard));
          },
        });
      },
    });
  };

  setVeriffParams = () => {
    const { user } = this.props;
    this.veriff &&
      this.veriff.setParams({
        person: {
          givenName: user?.first_name || '',
          lastName: user?.last_name || '',
        },
        vendorData: user?.email || '',
      });
  };

  mountVeriff = () => {
    this.veriff &&
      this.veriff.mount({
        submitBtnText: 'Start session',
      });
  };

  render() {
    const { source } = this.props;
    return (
      <div className="verify_form">
        <div className="verify_form__top">
          {source === SOURCES.registration && (
            <h1 className="verify_form__title f-500">Verify your identity</h1>
          )}
          <div className="verify_form__text">
            <div>
              To provide a safe marketplace and prevent financial crime, we are
              bound to verify the identity of our investors.
            </div>
            <div>
              To start verification, you’ll need to choose the verification
              assistance option and take the following steps:
            </div>
            <ul className="verify_form__list">
              <li>
                Prepare a valid and undamaged original identity document:
                passport, national ID card, driver's license or residence
                permit.
              </li>
              <li>Check if your device’s camera is uncovered and working.</li>
              <li>Be prepared to take a photo of your ID and a selfie.</li>
            </ul>
          </div>
          <div id="veriff-root" className="verify_form__form" />
          <div
            className={classnames(
              'verify_form__bottom',
              source === SOURCES.profile && 'full'
            )}
          >
            <div>
              Identity verification is provided by Veriff’s highly trusted and
              secure solution.
            </div>
            <div>
              During identity verification, all media materials are stored for a
              limited time by Veriff and aren't transferred to Scramble.
            </div>
          </div>
        </div>

        {/* {[SOURCES.registration, SOURCES.profile].includes(source) && ( */}
        {/*   <div className="verify_form__chat"> */}
        {/*     <div>Do you have any questions about verification?</div> */}
        {/*     <RoundButton */}
        {/*       label="Chat with us" */}
        {/*       onClick={() => TidioChat.open()} */}
        {/*       className="verify_form__chat_button" */}
        {/*       fullWidth */}
        {/*     /> */}
        {/*   </div> */}
        {/* )} */}

        {source === SOURCES.registration && (
          <div className="verify_form__actions">
            <MyLink
              underline
              href={APP_LINKS.dashboard}
              onClick={() => {
                setUserVeriffSkipReason(SKIP_REASON_TYPES.skipped_later);
              }}
            >
              I will complete verification later
            </MyLink>
            <MyLink
              underline
              href={APP_LINKS.dashboard}
              onClick={() => {
                setUserVeriffSkipReason(SKIP_REASON_TYPES.skipped_dont_want);
              }}
            >
              I don't want to be verified
            </MyLink>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
});

export default connect(mapStateToProps)(VerifyForm);
