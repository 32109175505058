import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/* Hook that used to retrieve status BEFORE confirmed allocation */

export const useGetAllocationStatus = () => {
  const { preAllocateInfo } = useSelector(state => state.fundStore);

  const accounts = useMemo(
    () => (preAllocateInfo.data ? preAllocateInfo.data.accounts : []),
    [preAllocateInfo.data]
  );

  const accountsByGroup = useMemo(() => {
    return accounts.reduce((res, acc) => {
      res[acc.group] = acc;
      return res;
    }, {});
  }, [accounts]);

  const availableCash = accountsByGroup.cash?.amount || 0;
  const totalAllocated = preAllocateInfo.data?.total_allocated || 0;
  const bonusAmount = preAllocateInfo.data?.bonus_amount || 0;
  const availableCashWithoutBonus = Number(availableCash - bonusAmount) || 0;
  const allocatedGroupA = accountsByGroup.conservative?.amount || 0;
  const allocatedGroupB = accountsByGroup.moderate?.amount || 0;
  const noCash = availableCashWithoutBonus < 0.1;
  const usedBonusInGroupA =
    allocatedGroupA < bonusAmount ? allocatedGroupA : bonusAmount;
  const bonusRemaining =
    bonusAmount - usedBonusInGroupA < 0 ? 0 : bonusAmount - usedBonusInGroupA;

  const totalCash = preAllocateInfo.data
    ? preAllocateInfo.data.total_allocated + availableCash
    : 0;

  const totalCashWithoutBonus = totalCash - bonusAmount;

  return {
    accounts,
    accountsByGroup,
    noCash,
    availableCash,
    availableCashWithoutBonus,
    totalAllocated,
    bonusAmount,
    allocatedGroupA,
    allocatedGroupB,
    bonusRemaining,
    usedBonusInGroupA,
    totalCash,
    totalCashWithoutBonus,
  };
};
