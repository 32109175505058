import { METADATA } from '../../helpers/metadata';
import { GET_REFERRAL_CODE, GET_REFERRALS_LIST } from '../types/referralTypes';

const initialState = {
  referralCode: { ...METADATA.default, data: null },
  referralsList: { ...METADATA.default, data: null },
};

const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REFERRALS_LIST.REQUEST:
      return {
        ...state,
        referralsList: { ...state.referralsList, ...METADATA.request },
      };
    case GET_REFERRALS_LIST.SUCCESS:
      return {
        ...state,
        referralsList: { ...METADATA.success, data: action.payload },
      };
    case GET_REFERRALS_LIST.FAILURE:
      return {
        ...state,
        referralsList: {
          ...state.referralsList,
          ...METADATA.error,
          error: action.error,
        },
      };

    case GET_REFERRAL_CODE.REQUEST:
      return {
        ...state,
        referralCode: { ...state.referralCode, ...METADATA.request },
      };
    case GET_REFERRAL_CODE.SUCCESS:
      return {
        ...state,
        referralCode: { ...METADATA.success, data: action.payload },
      };
    case GET_REFERRAL_CODE.FAILURE:
      return {
        ...state,
        referralCode: {
          ...METADATA.error,
          error: action.error,
          data: null,
        },
      };

    default:
      return state;
  }
};

export default referralReducer;
