import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { AccountCreateStep } from './steps/accountCreate';
import { VerifyIdentityStep } from './steps/verifyIdentity';
import { WainUntilCheckedStep } from './steps/waitUntilChecked';
import { StartInvestingStep } from './steps/startInvesting';
import { AddCashStep } from './steps/addCash';
import {
  hasRequiredPersonalSectionFields,
  hasRequiredProfileSectionFields,
  isPassedVeriff,
  isVerifiedPhoneNumber,
} from '../../../../helpers/user';
import { MODAL_TYPES } from '../../../../components/ModalProvider';
import { calculateCash } from '../../../../helpers/utils';
import { USER_STATUSES, VERIFF_CODES } from '../../../../helpers/constants';
import { setModals } from '../../../../store/actions/commonActions';
import { getVeriffStatus } from '../../../../store/actions/profileActions';
import { sendVerificationCode } from '../../../../store/services/userServices';
import { getUserLocation } from '../../../../store/actions/userActions';
import s from './index.module.scss';

export const STEPS = {
  account_create: 'account_create',
  verify_identity: 'verify_identity',
  wait_until_checked: 'wait_until_checked',
  add_cash: 'add_cash',
  start_investing: 'start_investing',
};

const VerificationProcessWizard = ({ isActiveFR }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const intervalID = useRef(null);
  const [passed, setPassed] = useState([STEPS.account_create]);
  const [modalType, setModalType] = useState(null);
  const { balance } = useSelector(state => state.dashboardStore);
  const { modals } = useSelector(state => state.commonStore);
  const { data: profile } = useSelector(state => state.profileStore.profile);
  const investingStatus = useSelector(
    state => state.investStore.investingStatus.data
  );
  const veriffStatusCode = useSelector(
    state => state.profileStore.veriffStatusCode
  );

  const [preSavedForm, setPreSavedForm] = useState({
    profile_details: null,
    personal_information: null,
    investor_type: null,
  });

  useEffect(() => {
    checkCurrentStep();
    dispatch(getUserLocation());
    return () => {
      clearInterval(intervalID.current);
    };
  }, []);

  useEffect(() => {
    checkCurrentStep();
  }, [veriffStatusCode]);

  useEffect(() => {
    if (modalType) {
      openModal();
    }
  }, [modalType, profile, preSavedForm]);

  useEffect(() => {
    if (!modals.length) {
      setModalType(null);
    }
  }, [modals]);

  const closeModal = () => {
    dispatch(setModals([]));
  };

  const openModal = async () => {
    switch (modalType) {
      case MODAL_TYPES.wizard_veriff:
        return openVeriffModal();

      case MODAL_TYPES.wizard_profile_detail:
        return openProfileDetailModal();

      case MODAL_TYPES.wizard_personal_information:
        return openPersonalInfoModal();

      case MODAL_TYPES.phone_verification:
        await sendVerificationCode(profile?.phone);
        return openPhoneVerificationModal(profile);

      case MODAL_TYPES.investor_type:
        return openInvestorTypeModal();

      default:
        return closeModal();
    }
  };

  const checkCurrentStep = openModal => {
    const { status } = profile;
    if (status === USER_STATUSES.new) {
      if (
        veriffStatusCode === VERIFF_CODES.verified &&
        hasRequiredProfileSectionFields(profile) &&
        hasRequiredPersonalSectionFields(profile)
      ) {
        return setPassed([
          STEPS.account_create,
          STEPS.verify_identity,
          STEPS.wait_until_checked,
        ]);
      }

      const { veriff } = qs.parse(location.search.replace('?', ''));
      if (veriff === 'open') {
        setModalType(MODAL_TYPES.wizard_veriff);
      } else {
        openModal && onVerifyIdentity();
      }

      return setPassed([STEPS.account_create, STEPS.verify_identity]);
    }

    const minInvestAmount = investingStatus?.min_investment_amount || 0;
    const cash = calculateCash(balance);

    if (status === USER_STATUSES.verified) {
      if (cash >= minInvestAmount) {
        return setPassed(Object.keys(STEPS));
      }
      return setPassed([
        STEPS.account_create,
        STEPS.verify_identity,
        STEPS.wait_until_checked,
        STEPS.add_cash,
      ]);
    }
  };

  const checkVeriffResult = () => {
    clearInterval(intervalID.current);
    intervalID.current = setInterval(() => {
      dispatch(getVeriffStatus()).then(res => {
        if (res && res.success && res.data.code === VERIFF_CODES.verified) {
          clearInterval(intervalID.current);
          setPassed([
            STEPS.account_create,
            STEPS.verify_identity,
            STEPS.wait_until_checked,
          ]);
        }
      });
    }, 2000);
  };

  const onVerifyIdentity = () => {
    if (!hasRequiredProfileSectionFields(profile)) {
      return setModalType(MODAL_TYPES.wizard_profile_detail);
    }

    if (!hasRequiredPersonalSectionFields(profile)) {
      return setModalType(MODAL_TYPES.wizard_personal_information);
    }

    if (['RU', 'BY'].includes(profile?.country_code)) {
      setModalType(null);
      return closeModal();
    }

    if (!isVerifiedPhoneNumber(profile)) {
      return setModalType(MODAL_TYPES.phone_verification);
    }

    if (profile.country_code === 'GB' && !profile.investor?.investor_type) {
      return setModalType(MODAL_TYPES.investor_type);
    }

    setModalType(MODAL_TYPES.wizard_veriff);
  };

  const openProfileDetailModal = () => {
    dispatch(
      setModals(
        {
          type: MODAL_TYPES.wizard_profile_detail,
          initialValues: preSavedForm.profile_details,
          onSave: formData => {
            setPreSavedForm({ ...preSavedForm, profile_details: formData });
            setModalType(MODAL_TYPES.wizard_personal_information);
          },
        },
        true
      )
    );
  };

  const openPersonalInfoModal = () => {
    dispatch(
      setModals(
        {
          type: MODAL_TYPES.wizard_personal_information,
          initialValues: preSavedForm.personal_information,
          onBack: formData => {
            setPreSavedForm({
              ...preSavedForm,
              personal_information: formData,
            });
            setModalType(MODAL_TYPES.wizard_profile_detail);
          },

          onSave: (formData, data) => {
            setPreSavedForm({
              ...preSavedForm,
              personal_information: formData,
            });

            if (['RU', 'BY'].includes(data.country_code)) {
              return closeModal();
            }

            if (!isVerifiedPhoneNumber(data)) {
              return setModalType(MODAL_TYPES.phone_verification);
            }

            if (data.country_code === 'GB') {
              return setModalType(MODAL_TYPES.investor_type);
            }

            if (!isPassedVeriff(data)) {
              return setModalType(MODAL_TYPES.wizard_veriff);
            }

            return closeModal();
          },
        },
        true
      )
    );
  };

  const openInvestorTypeModal = () => {
    dispatch(
      setModals(
        {
          type: MODAL_TYPES.investor_type,
          initialValues: preSavedForm.investor_type,
          onBack: formData => {
            setPreSavedForm({
              ...preSavedForm,
              investor_type: formData,
            });
            setModalType(MODAL_TYPES.wizard_personal_information);
          },
          onSave: formData => {
            setPreSavedForm({
              ...preSavedForm,
              investor_type: formData,
            });

            if (!isPassedVeriff(profile)) {
              return setModalType(MODAL_TYPES.wizard_veriff);
            }

            return closeModal();
          },
        },
        true
      )
    );
  };

  const openVeriffModal = () => {
    dispatch(
      setModals(
        {
          type: MODAL_TYPES.wizard_veriff,
          onBack: () => {
            profile?.country_code === 'GB'
              ? setModalType(MODAL_TYPES.investor_type)
              : setModalType(MODAL_TYPES.wizard_personal_information);
          },
          onVeriffEvent: msg => msg !== 'CANCELED' && closeModal(),
          onSuccess: checkVeriffResult,
        },
        true
      )
    );
  };

  const openPhoneVerificationModal = () => {
    dispatch(
      setModals(
        {
          type: MODAL_TYPES.phone_verification,
          onBack: () => setModalType(MODAL_TYPES.wizard_personal_information),
          onSuccess: () => {
            if (profile.country_code === 'GB') {
              return setModalType(MODAL_TYPES.investor_type);
            }

            if (!isPassedVeriff(profile)) {
              return setModalType(MODAL_TYPES.wizard_veriff);
            }

            return closeModal();
          },
        },
        true
      )
    );
  };

  return (
    <div className={s.wrapper}>
      <ul className={s.list}>
        <AccountCreateStep passed={passed} />
        <VerifyIdentityStep
          passed={passed}
          setPassed={setPassed}
          onVerifyIdentity={onVerifyIdentity}
        />
        <WainUntilCheckedStep passed={passed} isActiveFR={isActiveFR} />
        <AddCashStep passed={passed} />
        <StartInvestingStep passed={passed} />
      </ul>
    </div>
  );
};

export default VerificationProcessWizard;
