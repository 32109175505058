import { METADATA } from '../../helpers/metadata';
import { GET_COMPLAINT_DETAIL, GET_COMPLAINTS } from '../types/complaintTypes';

const initialState = {
  complaintList: { ...METADATA.default, data: null },
  detail: { ...METADATA.default, data: null },
};

const complaintReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPLAINTS.REQUEST:
      return {
        ...state,
        complaintList: { ...state.complaintList, ...METADATA.request },
      };
    case GET_COMPLAINTS.SUCCESS:
      return {
        ...state,
        complaintList: { ...METADATA.success, data: action.payload },
      };
    case GET_COMPLAINTS.FAILURE:
      return {
        ...state,
        complaintList: {
          ...state.complaintList,
          ...METADATA.error,
          error: action.error,
        },
      };

    case GET_COMPLAINT_DETAIL.REQUEST:
      return {
        ...state,
        detail: { data: null, ...METADATA.request },
      };
    case GET_COMPLAINT_DETAIL.SUCCESS:
      return {
        ...state,
        detail: { ...METADATA.success, data: action.payload },
      };
    case GET_COMPLAINT_DETAIL.FAILURE:
      return {
        ...state,
        detail: {
          ...METADATA.error,
          error: action.error,
          data: null,
        },
      };
    default:
      return state;
  }
};

export default complaintReducer;
