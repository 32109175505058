import React from 'react';
import classnames from 'classnames';
import { LoanGroupCard } from './card';
import { LOAN_GROUPS_V1 } from './data';
import s from './index.module.scss';

export const LoanTermsVersion01 = () => (
  <div className={s.page}>
    <div className="container-inner">
      <h1 className="f-42 f-500">Loan Terms</h1>
      <p className={classnames(s.page__desc, 'f-20 f-500')}>
        All loan groups available at the same time, allocate your capital based
        on your risk appetite.
      </p>
      <div className={s.page__cards}>
        {LOAN_GROUPS_V1.map(group => (
          <LoanGroupCard
            key={group.id}
            group={group}
            className={s.page__card}
          />
        ))}
      </div>
    </div>
  </div>
);
