import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { prettyMoney } from '../../../helpers/utils';
import s from './index.module.scss';

export const ProgressBar = ({ current = 0, min = 0, max = 0, className }) => {
  const { innerWidth } = useSelector(state => state.commonStore);

  const result = React.useMemo(() => {
    const res = {
      currentPercent: 0,
      maxPercent: 100,
      minPercent: 100,
      currentLineWidth: 0,
      maxLineWidth: 100,
      minLineWidth: 100,
    };

    const minGoal = min || max;

    const maxPercent = Number((max * 100) / minGoal);

    res.maxPercent = maxPercent;

    if (max !== 0) {
      res.currentPercent = Number((current * maxPercent) / max);

      const _currentLineWidth = Number((current * 100) / max);
      res.currentLineWidth = _currentLineWidth > 100 ? 100 : _currentLineWidth;

      const _minLineWidth = Number((100 * 100) / maxPercent);
      res.minLineWidth = _minLineWidth > 100 ? 100 : _minLineWidth;
    }

    return res;
  }, [current, min, max]);

  const {
    currentPercent,
    maxPercent,
    minPercent,
    minLineWidth,
    maxLineWidth,
    currentLineWidth,
  } = result;

  return (
    <div className={classnames(s.wrapper, className)}>
      <div className={s.stats}>
        <div className={s.stats_left}>
          <div className={s.stats_label}>Invested to the round</div>
          <div className={s.stats_name}>Current</div>
          <div className={s.stats_value}>{prettyMoney(current)}</div>
        </div>
        <div className={s.stats_right}>
          <div className={s.stats_label}>Investing goal</div>
          <div className={s.stats_name}>Max</div>
          <div
            className={s.stats_value}
            style={{ color: current >= (max || min) ? '#000' : '#666' }}
          >
            {prettyMoney(max || min)}
          </div>
        </div>
      </div>
      <div className={s.bar}>
        <div
          className={classnames(s.line, s.line_max)}
          style={{ width: `${maxLineWidth}%` }}
        >
          <span>{parseInt(maxPercent || 100)}%</span>
        </div>

        <div
          className={classnames(s.line, s.line_current)}
          style={{ zIndex: current > min ? 1 : 2 }}
        >
          <div
            className={s.line_current_inner}
            style={{
              background: current > (min || max) ? '#F8A100' : '#FFD200',
              width: `max(${currentLineWidth}%, ${
                innerWidth <= 700 ? '48px' : '78px'
              })`,
            }}
          />
        </div>

        {!!min && (
          <div
            className={classnames(s.line, s.line_min)}
            style={{
              width: `${minLineWidth}%`,
              background: current > min ? '#FFD200' : '#FFF1B2',
              zIndex: current > min ? 2 : 1,
            }}
          >
            <span>{parseInt(minPercent)}%</span>
          </div>
        )}

        <div
          className={classnames(s.line, s.line_transparent)}
          style={{
            width: `max(${currentLineWidth}%, ${
              innerWidth <= 700 ? '48px' : '78px'
            })`,
          }}
        >
          <div className={s.pointer_wrap}>
            <div className={s.pointer}>{parseInt(currentPercent || 0)}%</div>
          </div>
        </div>
      </div>
    </div>
  );
};
