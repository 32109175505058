import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { prettyFloatMoney } from '../../../../../helpers/utils';
import s from './index.module.scss';

export const ExampleTable = ({ data }) => {
  const style = {
    gridTemplateColumns: data.total_outstanding_principal
      ? 'minmax(30px, 30px) repeat(6, minmax(0, 1fr))'
      : 'minmax(100px, 100px) repeat(4, minmax(0, 1fr))',
  };

  return (
    <div className={s.example_table__wrap}>
      <div className={s.example_table__inner}>
        <div className={s.example_table} data-rows="">
          <div className={s.example_table__thead} style={style}>
            <div className={s.example_table__thead_col}>Num</div>
            <div className={s.example_table__thead_col}>Date</div>
            {data.total_outstanding_principal && (
              <div className={s.example_table__thead_col}>
                Outstanding Principal
              </div>
            )}
            {data.total_flat_fee_accrued && (
              <div className={s.example_table__thead_col}>
                Basic Flat Fee Accrued
              </div>
            )}
            <div className={s.example_table__thead_col}>Flat Fee Paid</div>
            <div className={s.example_table__thead_col}>Principal</div>
            <div className={s.example_table__thead_col}>Total</div>
          </div>

          <div className={s.example_table__tbody}>
            {data.list.map((item, idx) => (
              <div
                className={s.example_table__tbody_row}
                key={idx}
                style={style}
              >
                <div className={s.example_table__tbody_col}>
                  <div className="f-16 f-400 tl">{idx + 1}.</div>
                </div>
                <div className={s.example_table__tbody_col}>
                  <span className="f-16 f-400">
                    {moment(item.date).format('DD.MM.YYYY')}
                  </span>
                </div>
                {item.outstanding_principal && (
                  <div className={s.example_table__tbody_col}>
                    <div className="f-16 f-400 tl">
                      {item.outstanding_principal
                        ? prettyFloatMoney(item.outstanding_principal)
                        : '-'}
                    </div>
                  </div>
                )}
                {item.flat_fee_accrued && (
                  <div className={s.example_table__tbody_col}>
                    <div className="f-16 f-400 tl">
                      {item.flat_fee_accrued
                        ? prettyFloatMoney(item.flat_fee_accrued)
                        : '-'}
                    </div>
                  </div>
                )}
                <div className={s.example_table__tbody_col}>
                  <div className="f-16 f-400 tl">
                    {item.fee ? prettyFloatMoney(item.fee) : '-'}
                  </div>
                </div>
                <div className={classnames(s.example_table__tbody_col, 'tl')}>
                  <div className="f-16 f-400 tl">
                    {item.principal ? prettyFloatMoney(item.principal) : '-'}
                  </div>
                </div>
                <div className={classnames(s.example_table__tbody_col, 'tl')}>
                  <div className="f-16 f-400 tl">
                    {item.total ? prettyFloatMoney(item.total) : '-'}
                  </div>
                </div>
              </div>
            ))}

            <div className={s.example_table__tbody_row_summary} style={style}>
              <div className={s.example_table__tbody_col}>
                <div className="f-16 f-500">Total:</div>
              </div>
              <div className={s.example_table__tbody_col}>
                <span className="f-16 f-500" />
              </div>
              {data.total_outstanding_principal && (
                <div className={classnames(s.example_table__tbody_col, 'tl')}>
                  <span className="f-16 f-500">
                    {prettyFloatMoney(data.total_outstanding_principal)}
                  </span>
                </div>
              )}
              {data.total_flat_fee_accrued && (
                <div className={classnames(s.example_table__tbody_col, 'tl')}>
                  <span className="f-16 f-500">
                    {prettyFloatMoney(data.total_flat_fee_accrued)}
                  </span>
                </div>
              )}
              <div className={classnames(s.example_table__tbody_col, 'tl')}>
                <span className="f-16 f-500">
                  {prettyFloatMoney(data.total_flat_fee)}
                </span>
              </div>
              <div className={classnames(s.example_table__tbody_col, 'tl')}>
                <span className="f-16 f-500">
                  {prettyFloatMoney(data.total_principal)}
                </span>
              </div>
              <div className={s.example_table__tbody_col}>
                <span className="f-16 f-500">
                  {prettyFloatMoney(data.total)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
