import React, { useState } from 'react';
import classnames from 'classnames';
import { RepaymentSchedule } from './RepaymentSchedule';
import { EarningSchedule } from './EarningSchedule';
import s from './index.module.scss';

const TABS = {
  earnings: 'earnings',
  repayments: 'repayments',
};

export const EarningsAndRepayments = () => {
  const [tab, setTab] = useState(TABS.earnings);

  return (
    <div>
      <div className={classnames(s.tabs, 'f-20 f-400')}>
        <div
          className={classnames(
            s.tab,
            tab === TABS.earnings && 'active',
            'hov'
          )}
          onClick={() => setTab(TABS.earnings)}
        >
          Earnings schedule
        </div>
        <div
          className={classnames(
            s.tab,
            tab === TABS.repayments && 'active',
            'hov'
          )}
          onClick={() => setTab(TABS.repayments)}
        >
          Repayment schedule
        </div>
      </div>
      {tab === TABS.earnings && <EarningSchedule />}
      {tab === TABS.repayments && <RepaymentSchedule />}
    </div>
  );
};
