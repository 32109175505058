import React from 'react';
import { RoundButton } from '../../components/UI/RoundButton';
import s from './index.module.scss';

const ComplaintRemoveModal = ({ onDelete, onClose }) => {
  return (
    <div className={s.wrapper}>
      <h1 className="f-20 f-500">
        Are you sure you want to delete your complaints form?
      </h1>
      <div className={s.text}>
        Deleting the form may result in the loss of all recorded complaints and
        associated data. Before proceeding, please ensure that you have
        thoroughly reviewed and backed up any important information contained
        within the form.
      </div>
      <div className={s.controls}>
        <RoundButton className={s.cancel} label="Cancel" onClick={onClose} />
        <RoundButton className={s.remove} label="Delete" onClick={onDelete} />
      </div>
    </div>
  );
};

export default ComplaintRemoveModal;
