import React from 'react';
import classnames from 'classnames';
import { MyLink } from '../../components/MyLink';
import { ReactComponent as XIcon } from '../../assets/icons/icon_close_grey_24.svg';
import s from './index.module.scss';

export const FileName = ({ file, className, prefix, onRemove }) => {
  const content = (
    <>
      <div className="file_name tl" title={file.name}>
        {prefix}
        {file.name}
      </div>

      {onRemove && (
        <div className={s.controls}>
          <button
            type="button"
            onClick={e => {
              e.preventDefault();
              onRemove(file);
            }}
            className={s.remove}
          >
            <XIcon />
          </button>
        </div>
      )}
    </>
  );

  return file.file ? (
    <MyLink
      href={file.file || '#'}
      className={classnames(s.file, className)}
      underline={!!file.file}
      isExternal
      style={{ cursor: file.file ? 'pointer' : 'unset' }}
    >
      {content}
    </MyLink>
  ) : (
    <div className={classnames(s.file, className)}>{content}</div>
  );
};
