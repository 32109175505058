import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import {
  contactInfoUpdate,
  getUserLocation,
  personalInfoUpdate,
  registerUser,
} from '../../store/actions/userActions';
import RegisterForm from '../../components/Forms/RegisterForm';
import VerifyForm, { SOURCES } from '../../components/VerifyForm';
import ContactInfoForm from '../../components/Forms/ContactInfoForm';
import { cropAvatar } from '../../components/AvatarEdit';
import PersonalInfoForm from '../../components/Forms/PersonalInfoForm';
import InvestorTypeForm from '../../components/Forms/InvestorTypeForm';
import PhoneVerificationForm from '../../components/Forms/PhoneVerificationForm';
import Notify from '../../components/Notification';
import { APP_LINKS } from '../../helpers/links';
import { scrollTop } from '../../helpers/utils';
import { uploadPhoto } from '../../store/services/commonServices';
import { getUserProfile } from '../../store/actions/profileActions';
import {
  sendVerificationCode,
  setInvestorType,
  validatePhoneNumber,
} from '../../store/services/userServices';
import {
  getReferralCodeFromCookie,
  saveReferralCodeToCookie,
} from '../../helpers/referral';
import Analytics from '../../analyticsGA';
import s from './index.module.scss';

const STEPS = {
  main: 'main',
  contact: 'contact',
  personal: 'personal',
  phone_verification: 'phone_verification',
  investor_type: 'investor_type',
  veriff: 'veriff',
};

const SignUp = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { user, userLocation } = useSelector(state => state.userStore);

  const [countryCode, setCountryCode] = useState('');
  const [phone, setPhone] = useState('');
  const [step, setStep] = useState(STEPS.main);

  const { invite, ref } = qs.parse(location.search.replace('?', ''));

  const inviteCode = useRef(invite);

  useEffect(() => {
    dispatch(getUserLocation());
  }, []);

  useEffect(() => {
    if (ref) {
      Array.isArray(ref)
        ? saveReferralCodeToCookie(ref[0])
        : saveReferralCodeToCookie(ref);
    }
  }, [ref]);

  const nextStep = step => {
    scrollTop();
    setStep(step);
  };

  const onRegister = async (data, { setSubmitting }) => {
    Analytics.signUpRegister();
    const payload = {
      email: data.email,
      password: data.password,
      referral_code: getReferralCodeFromCookie(),
    };

    if (inviteCode.current) {
      payload.promo_code = inviteCode.current;
    }

    const res = await dispatch(registerUser(payload));
    setSubmitting(false);
    res && res.success && nextStep(STEPS.contact);
  };

  const onContactInfoSubmit = async (data, { setSubmitting }) => {
    Analytics.signUpContactsDataInput();
    try {
      const payload = {
        first_name: data.first_name,
        last_name: data.last_name,
        company_name: data.company_name,
        position: data.position,
        photo: null,
      };

      if (data.photo && data.editorRef) {
        const croppedAvatar = cropAvatar(data.editorRef);
        const res = await uploadPhoto(croppedAvatar);
        res && res.success && (payload.photo = res.data.id);
      }

      const res = await dispatch(contactInfoUpdate(payload));
      if (res && res.success) {
        Notify.success({ text: 'Contact information updated successfully' });
        nextStep(STEPS.personal);
      } else {
        setSubmitting(false);
      }
    } catch (e) {
      // do nothing
    }
  };

  const onPersonalInfoSubmit = async (data, { setSubmitting }) => {
    Analytics.signUpPersonalDataInput();
    try {
      const payload = {
        phone: data.phone,
        citizenship: data.citizenship,
        citizenship_code: data.citizenship_code,
        country: data.country,
        country_code: data.country_code,
        city: data.city,
        address: data.address,
        postal_code: data.postal_code,
        origin_of_funds: data.origin_of_funds,
        monthly_investment: data.planned_monthly_investment,
      };

      const res = await dispatch(personalInfoUpdate(payload));

      if (res && res.success) {
        setCountryCode(data.country_code);
        setPhone(data.phone);

        if (['RU', 'BY'].includes(payload.country_code)) {
          dispatch(getUserProfile());
          return history.replace(APP_LINKS.dashboard);
        }

        await sendVerificationCode(data.phone);
        nextStep(STEPS.phone_verification);
      } else {
        setSubmitting(false);
      }
    } catch (e) {
      // do nothing
    }
  };

  const onInvestorTypeSubmit = async ({ investor_type }, { setSubmitting }) => {
    if (!investor_type) {
      return Notify.error({ text: 'Please select your type of investor' });
    }
    const payload = { investor_type };
    try {
      const res = await setInvestorType(payload);
      if (res && res.success) {
        Notify.success({ text: res.data.message });
        nextStep(STEPS.veriff);
      } else {
        setSubmitting(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onPhoneVerification = async (values, { setErrors }) => {
    if (values.code.length < 6) {
      return setErrors({ code: 'Verification code is required' });
    }

    const res = await validatePhoneNumber({
      phone_number: phone,
      code: values.code.join(''),
    });

    if (res && res.success) {
      Analytics.signUpPhoneVerification();
      nextStep(countryCode === 'GB' ? STEPS.investor_type : STEPS.veriff);
    } else {
      setErrors({ code: res.message });
    }
  };

  return (
    <div
      className={classNames(
        s.sign_up,
        step === STEPS.investor_type && s.sign_up__investor_type
      )}
    >
      <div className={s.sign_up__inner}>
        {step === STEPS.main && (
          <RegisterForm
            onSubmit={onRegister}
            nextStep={() => nextStep(STEPS.contact)}
            inviteCode={inviteCode.current}
          />
        )}

        {step === STEPS.contact && (
          <ContactInfoForm
            onSubmit={onContactInfoSubmit}
            userLocation={userLocation}
            user={user}
          />
        )}

        {step === STEPS.personal && (
          <PersonalInfoForm
            onSubmit={onPersonalInfoSubmit}
            userLocation={userLocation}
          />
        )}

        {step === STEPS.phone_verification && (
          <PhoneVerificationForm
            phone={phone}
            onSubmit={onPhoneVerification}
            onLater={() =>
              nextStep(
                countryCode === 'GB' ? STEPS.investor_type : STEPS.veriff
              )
            }
          />
        )}

        {step === STEPS.investor_type && countryCode === 'GB' && (
          <InvestorTypeForm onSubmit={onInvestorTypeSubmit} />
        )}

        {step === STEPS.veriff && <VerifyForm source={SOURCES.registration} />}
      </div>
    </div>
  );
};

export default SignUp;
