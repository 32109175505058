import React from 'react';
import classnames from 'classnames';
import { prettyFloatMoney } from '../../../../../helpers/utils';
import s from './index.module.scss';

export const IncreasedIncomeWarning = ({ amount, className }) => {
  return (
    <div className={classnames(s.wrapper, className)}>
      {amount ? (
        <>
          <div className="f-16-24 f-500">{`Want +${prettyFloatMoney(
            amount
          )} more?`}</div>
          <div className="f-16-24 f-400">
            Get increased income of 1% per month on the outstanding loan
            principal.
          </div>
          <div className="f-16-24 f-400">
            The increased income is accrued in the last month on a very simple
            condition: regular monthly replenishments and investments must be at
            least €100 per month during the full loan repayment period.
          </div>
        </>
      ) : (
        <>
          <div className="f-16-24 f-500">
            Get increased income of 1% per month on the outstanding loan
            principal.
          </div>
          <div className="f-16-24 f-400">
            The increased income is accrued in the last month on a very simple
            condition: regular monthly replenishments and investments must be at
            least €100 per month during the full loan repayment period.
          </div>
        </>
      )}
    </div>
  );
};
