import React from 'react';
import classnames from 'classnames';
import { MonthStatus } from './status/month';
import s from './index.module.scss';

export const RoundDetailRepaymentProgress = ({ graph, selected }) => {
  if (!graph) {
    return null;
  }

  const { payments, postponed_brands, extended_brands, is_new_terms } = graph;

  const stats = payments.reduce(
    (acc, item) => {
      if (
        item.status === 'completed' ||
        item.status === 'extended' ||
        item.status === 'postponed'
      ) {
        acc.paid += 1;
      }
      return acc;
    },
    { paid: 0, total: payments.length }
  );

  const hasPaidAll = stats.total === stats.paid;

  const isOldRounds = selected.id < 94;

  return (
    <div className={s.wrapper}>
      <div className="single_s">Round repayments progress</div>

      <div className={classnames(s.info, 'f-14')}>
        {hasPaidAll ? (
          <div className={s.info__column}>
            <div className={s.info__item}>All repayments were made.</div>
          </div>
        ) : (
          <>
            {is_new_terms ? (
              <>
                <div className={s.info__column}>
                  {!!postponed_brands?.length && (
                    <>
                      <div className={s.info__item}>
                        Postponed loan repayment
                      </div>
                      <div className={s.info__brands}>
                        {postponed_brands?.map(brand => (
                          <BrandIcon brand={brand} key={brand.id} />
                        ))}
                      </div>
                    </>
                  )}
                  {!!extended_brands?.length && (
                    <>
                      <div className={s.info__item}>
                        Extended loan repayment
                      </div>
                      <div className={s.info__brands}>
                        {extended_brands?.map(brand => (
                          <BrandIcon brand={brand} key={brand.id} />
                        ))}
                      </div>
                    </>
                  )}
                  {isOldRounds && (
                    <div className={s.info__item}>
                      5% flat fee was paid out to Investors A on the first day
                      after the round ends
                    </div>
                  )}
                  <div className={s.info__item}>
                    {stats.paid} of {stats.total} monthly payments completed
                    successfully on time
                  </div>
                </div>
                <div className={s.info__column}>
                  <div className={s.info__item}>
                    {stats.total - stats.paid} more{' '}
                    {`repayment${stats.total - stats.paid > 1 ? 's' : ''}`} are
                    expected to be made according to a schedule
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={s.info__column}>
                  {!!postponed_brands?.length && (
                    <>
                      <div className={s.info__item}>
                        Postponed loan repayment
                      </div>
                      <div className={s.info__brands}>
                        {postponed_brands?.map(brand => (
                          <BrandIcon brand={brand} key={brand.id} />
                        ))}
                      </div>
                    </>
                  )}

                  {!!extended_brands?.length && (
                    <>
                      <div className={s.info__item}>
                        Extended loan repayment
                      </div>
                      <div className={s.info__brands}>
                        {extended_brands?.map(brand => (
                          <BrandIcon brand={brand} key={brand.id} />
                        ))}
                      </div>
                    </>
                  )}

                  <div className={s.info__item}>
                    {stats.paid} of {stats.total} monthly payments completed
                    successfully on time
                  </div>
                </div>
                <div className={s.info__column}>
                  <div className={s.info__item}>
                    {stats.total - stats.paid} more{' '}
                    {`repayment${stats.total - stats.paid > 1 ? 's' : ''}`} are
                    expected to be made according to a schedule
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {!!payments?.length && (
        <div className={s.graph}>
          {payments.map((month, index) => (
            <MonthStatus
              key={month.deadline}
              month={month}
              index={index + 1}
              className={s.card}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const BrandIcon = ({ brand }) => (
  <div className={s.brand}>
    <img
      src={brand.promo_image?.file}
      width={40}
      height={40}
      alt={brand.name}
    />
  </div>
);
