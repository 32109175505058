import Decimal from 'decimal.js-light';
import { ACCOUNT_TYPES, LOAN_GROUPS } from '../../../helpers/constants';
import { prettyFloatMoney, round10 } from '../../../helpers/utils';

export const getAmountPresets = (amount = 0, groupID) => {
  let presets = [];
  switch (groupID) {
    case LOAN_GROUPS.conservative.id:
      presets = [amount, amount * 0.5, amount * 0.2]; // для A - 100%, 50%, 20%
      break;
    case LOAN_GROUPS.moderate.id:
      presets = [amount, amount * 0.8, amount * 0.5]; // для В - 100%, 80%, 50%
      break;
    default:
      break;
  }

  return presets.reduce((acc, amount, index) => {
    let roundedAmount;
    if (index === 0) {
      roundedAmount = parseInt(amount);
    } else if (round10(amount, true) >= presets[0]) {
      roundedAmount = parseInt(amount);
    } else {
      roundedAmount = round10(amount, true);
    }
    if (roundedAmount && !acc.includes(roundedAmount)) {
      acc.push(roundedAmount);
    }
    return acc;
  }, []);
};

export const validate = values => {
  const remaining = values.to.remaining_amount || 0;

  const errors = {};
  const amount = new Decimal(values.amount || 0).toNumber();
  const maxAmount = remaining;

  if (values.from && values.to) {
    if (values.to.type === ACCOUNT_TYPES.loan) {
      if (amount > maxAmount) {
        errors.amount =
          maxAmount === 0
            ? 'Remaining amount already reached'
            : `Amount should be less than ${prettyFloatMoney(maxAmount)}`;
      }

      if (values.from && amount > values.from.amount) {
        errors.amount = 'There are no funds to transfer';
      }
    }
  } else {
    errors.amount = 'Please check selected accounts';
  }

  return errors;
};
