import React from 'react';
import classnames from 'classnames';
import { STEPS } from '../index';
import { MyLink } from '../../../../../components/MyLink';
import { APP_LINKS } from '../../../../../helpers/links';
import s from '../index.module.scss';

export const WainUntilCheckedStep = ({ passed, isActiveFR }) => {
  const active = passed.includes(STEPS.wait_until_checked);

  return (
    <li
      id={`vpw_${STEPS.wait_until_checked}`}
      className={classnames(s.list_item, active && s.list_item_active)}
    >
      <div className={s.list_item_left}>
        <span
          className={classnames(
            s.circle,
            active && s.circle_active,
            'f-26 f-400'
          )}
        >
          03
        </span>
      </div>
      <div className={s.list_item_right}>
        <div className={classnames(s.list_item_title, 'f-26 f-400')}>
          Wait until you get checked
        </div>
        <div
          className={classnames(
            s.list_item_content,
            active &&
              !passed.includes(STEPS.add_cash) &&
              s.list_item_content_active,
            'f-16-24 f-400'
          )}
        >
          <div className={s.list_item_content_inner}>
            <div className="f-16 f-400">
              We are currently checking your profile information – it may take
              up to two business days.
            </div>
            <div className="f-16 f-400" style={{ marginTop: '8px' }}>
              We will notify you by email when the results are ready.
            </div>
            {isActiveFR ? (
              <div className="f-16 f-400" style={{ marginTop: '8px' }}>
                In the meantime, you can{' '}
                <MyLink href={APP_LINKS.investing} underline>
                  meet the participants of the round
                </MyLink>{' '}
                or{' '}
                <MyLink href={APP_LINKS.helpLoanTerms()} underline>
                  learn more about loan terms
                </MyLink>
                .
              </div>
            ) : (
              <div className="f-16 f-400">
                In the meantime, you can meet the{' '}
                <MyLink href={APP_LINKS.startups} underline>
                  brands
                </MyLink>{' '}
                who became part of Scramble or{' '}
                <MyLink href={APP_LINKS.helpLoanTerms()} underline>
                  learn more about loan terms
                </MyLink>
                .
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};
