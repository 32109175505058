import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileDetailsForm from '../../components/Forms/ProfileDetailsForm';
import { cropAvatar } from '../../components/AvatarEdit';
import { uploadPhoto } from '../../store/services/commonServices';
import { contactInfoUpdate } from '../../store/actions/userActions';
import Notify from '../../components/Notification';
import { setUserProfile } from '../../store/actions/profileActions';

const WizardProfileDetailModal = ({ initialValues, onSave }) => {
  const dispatch = useDispatch();
  const { data: profile } = useSelector(state => state.profileStore.profile);
  const userLocation = useSelector(state => state.userStore.userLocation);

  const onProfileDetailSubmit = async data => {
    try {
      const payload = {
        first_name: data.first_name,
        last_name: data.last_name,
        company_name: data.company_name,
        position: data.position,
        photo: profile && profile.photo ? profile.photo.id : null,
      };

      if (data.photo && data.editorRef) {
        const croppedAvatar = cropAvatar(data.editorRef);
        const res = await uploadPhoto(croppedAvatar);
        res && res.success && (payload.photo = res.data.id);
      }

      const res = await dispatch(contactInfoUpdate(payload));
      if (res && res.success) {
        Notify.success({ text: 'Profile Details updated' });
        dispatch(setUserProfile(res.data));
        onSave && onSave(data);
      }
    } catch (e) {
      // do nothing
    }
  };

  return (
    <ProfileDetailsForm
      onSubmit={onProfileDetailSubmit}
      user={profile}
      location={userLocation}
      initialValues={initialValues}
    />
  );
};

export default WizardProfileDetailModal;
