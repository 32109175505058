import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { InputTextField } from '../UI/InputTextField';
import { RoundButton } from '../UI/RoundButton';
import { getReferralCodeFromCookie } from '../../helpers/referral';
import {
  applyUserReferralCode,
  checkReferralCodeValidity,
  checkUserReferralCodeStatus,
} from '../../store/services/referralService';
import s from './index.module.scss';

export const ReferralCodeInput = () => {
  const location = useLocation();
  const { ref } = qs.parse(location.search.replace('?', ''));
  const [value, setValue] = useState(ref || getReferralCodeFromCookie() || '');

  const defaultStatus = {
    error: '',
    text: '',
    is_valid: false,
    is_activated: false,
  };

  const [status, setStatus] = useState(defaultStatus);

  useEffect(() => {
    checkStatus();
  }, []);

  useEffect(() => {
    setStatus(defaultStatus);
    value &&
      value.length >= 8 &&
      checkReferralCodeValidity(value).then(res => {
        if (res.success) {
          if (res.data.is_valid) {
            setStatus({
              ...status,
              ...res.data,
              error: '',
            });
          } else {
            setStatus({
              ...status,
              ...res.data,
              error: res.data.text,
              text: '',
            });
          }
        }
      });
  }, [value]);

  const checkStatus = async () => {
    const res = await checkUserReferralCodeStatus();
    res?.success && setStatus({ ...status, ...res.data });
  };

  const onChange = e => {
    setValue(e.target.value);
  };

  const onClick = async () => {
    const res = await applyUserReferralCode(value);
    res?.success &&
      setStatus({
        ...status,
        ...res.data,
        error: res.data.is_activated ? '' : res.data.text,
      });
  };

  return (
    <>
      <div className={s.wrapper}>
        <InputTextField
          label="Referral code"
          name="ref_code"
          value={value}
          onChange={onChange}
          error={status.error}
          className={s.input}
          disabled={status.is_activated}
        />
        <div className={s.controls}>
          <RoundButton
            label={status.is_activated ? 'Applied' : 'Apply'}
            disabled={status.is_activated}
            onClick={onClick}
            fillBackground
            className={s.apply}
          />
          {status.is_activated && (
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16.3C12.4183 16.3 16 12.7183 16 8.30005C16 3.88177 12.4183 0.300049 8 0.300049C3.58172 0.300049 0 3.88177 0 8.30005C0 12.7183 3.58172 16.3 8 16.3ZM6.73091 11.2581C6.7937 11.2856 6.86148 11.2999 6.93004 11.3001H6.93504C7.00397 11.2993 7.07198 11.2842 7.13479 11.2558C7.1976 11.2274 7.25386 11.1863 7.30004 11.1351L11.87 6.13513C11.9187 6.0874 11.9571 6.03023 11.9828 5.96713C12.0086 5.90403 12.0212 5.83633 12.0198 5.7682C12.0184 5.70006 12.0032 5.63292 11.9749 5.5709C11.9466 5.50888 11.906 5.45329 11.8555 5.40755C11.805 5.36181 11.7456 5.32688 11.6811 5.3049C11.6166 5.28293 11.5483 5.27437 11.4803 5.27977C11.4124 5.28516 11.3463 5.30438 11.286 5.33626C11.2258 5.36813 11.1727 5.41199 11.13 5.46513L6.92504 10.0651L4.86504 7.87013C4.77421 7.77332 4.64863 7.71657 4.51595 7.71235C4.38327 7.70813 4.25435 7.75679 4.15754 7.84763C4.06074 7.93846 4.00398 8.06403 3.99976 8.19672C3.99555 8.3294 4.04421 8.45832 4.13504 8.55513L6.56504 11.1401C6.61166 11.1904 6.66812 11.2305 6.73091 11.2581Z"
                fill="#44AF69"
              />
            </svg>
          )}
        </div>
      </div>

      {status.is_activated && (
        <div className={s.bonus}>
          <svg
            width="40"
            style={{ minWidth: '40px' }}
            height="41"
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M39.4105 20.3001C39.4105 31.1603 30.7404 39.9552 20.0563 39.9552C9.37221 39.9552 0.702148 31.1603 0.702148 20.3001C0.702148 9.43986 9.37221 0.644897 20.0563 0.644897C30.7404 0.644897 39.4105 9.43986 39.4105 20.3001Z"
              fill="white"
            />
            <path
              d="M20.872 10.8168L23.2126 15.6623C23.2777 15.7971 23.3739 15.9137 23.493 16.0021C23.612 16.0905 23.7503 16.1479 23.896 16.1696L29.1289 16.9467C29.2965 16.9716 29.454 17.0439 29.5835 17.1554C29.713 17.267 29.8093 17.4134 29.8617 17.578C29.914 17.7426 29.9202 17.9189 29.8795 18.0869C29.8389 18.255 29.753 18.408 29.6317 18.5288L25.8444 22.3003C25.6303 22.5136 25.5331 22.8206 25.5831 23.121L26.477 28.4469C26.5056 28.6174 26.4869 28.7928 26.4231 28.953C26.3593 29.1132 26.2529 29.2521 26.1159 29.3538C25.9789 29.4554 25.8168 29.5159 25.6479 29.5284C25.479 29.5409 25.31 29.5048 25.1602 29.4243L20.479 26.9093C20.3486 26.8394 20.2037 26.803 20.0565 26.803C19.9093 26.803 19.7644 26.8394 19.634 26.9093L14.9529 29.4243C14.803 29.5048 14.6341 29.5409 14.4652 29.5284C14.2963 29.5159 14.1342 29.4554 13.9972 29.3538C13.8602 29.2521 13.7538 29.1132 13.6899 28.953C13.6261 28.7928 13.6074 28.6174 13.636 28.4469L14.53 23.121C14.5549 22.9728 14.5441 22.8207 14.4985 22.6777C14.453 22.5347 14.3741 22.4052 14.2686 22.3003L10.4813 18.5288C10.36 18.408 10.2742 18.255 10.2335 18.0869C10.1929 17.9189 10.1991 17.7426 10.2514 17.578C10.3037 17.4134 10.4001 17.267 10.5295 17.1554C10.6591 17.0439 10.8165 16.9716 10.9842 16.9467L16.2171 16.1696C16.3627 16.1479 16.5011 16.0905 16.6201 16.0021C16.7391 15.9137 16.8354 15.7971 16.9005 15.6623L19.2411 10.8168C19.5769 10.1278 20.5389 10.1278 20.872 10.8168Z"
              fill="black"
            />
          </svg>
          <div className={s.bonus_text}>{status.text}</div>
        </div>
      )}
    </>
  );
};
