import React from 'react';

export const OtpReader = ({ children, onChange }) => {
  const abortController = React.useRef(new AbortController());

  React.useEffect(() => {
    autoReadSMS();
    () => {
      abortController.current.abort();
    };
  }, []);

  const autoReadSMS = () => {
    if ('OTPCredential' in window) {
      try {
        navigator.credentials
          .get({
            otp: { transport: ['sms'] },
            signal: abortController.current.signal,
          })
          .then(otp => {
            onChange(otp.code);
          })
          .catch(err => {
            console.error(err.message);
          });
        // .finally(() => {
        //   autoReadSMS();
        // });

        // TODO fix "OTP retrieval was cancelled"
      } catch (e) {
        console.error(e.message);
      }
    }
  };

  return children;
};
