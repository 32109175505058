import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { BackButton } from '../../../../components/UI/Buttons';
import { canGoBack } from '../../../../helpers/utils';
import { APP_LINKS } from '../../../../helpers/links';
import { GROUP_DETAIL } from './data';
import { TERMS } from './terms';
import s from './index.module.scss';

export const GroupDetailVersion03 = ({ id }) => {
  const history = useHistory();
  const { innerWidth } = useSelector(state => state.commonStore);

  const {
    title,
    termsSection,
    fundsUsageSection,
    fundsProtectionSection,
    repaymentSection,
    agreementSection,
  } = GROUP_DETAIL[id](TERMS, innerWidth);

  return (
    <div className={s.page}>
      <div className="container-inner">
        <BackButton
          onClick={() =>
            canGoBack(history)
              ? history.goBack()
              : history.push(APP_LINKS.helpLoanTerms('v3'))
          }
        />
        <h1 className="f-42 f-500">{title}</h1>
        {termsSection}
        {fundsUsageSection}
        {fundsProtectionSection}
        {repaymentSection}
        <div className={classnames(s.page__fees, 'f-16-24 f-400')}>
          <b>No fees on investor capital</b>: annual management, transaction, or
          profit-sharing fees for the investors.
        </div>
        {agreementSection}
      </div>
    </div>
  );
};
