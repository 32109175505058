import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { EMPTY_DASH } from '../../../../helpers/constants';
import { prettyMoney } from '../../../../helpers/utils';
import s from './index.module.scss';

const STATS = {
  count: {
    label: 'Date',
    value: ({ round_date_start, round_date_end }) =>
      `${moment(round_date_start).format('D')}-${moment(round_date_end).format(
        'D'
      )} ${moment(round_date_start).format('MMMM')}` || EMPTY_DASH,
  },
  total_funded: {
    label: 'Fundraising goal',
    value: ({ fundraising_goal }) =>
      (fundraising_goal && prettyMoney(fundraising_goal)) || EMPTY_DASH,
  },
  startups_count: {
    label: 'Brands',
    value: ({ startups_count }) => startups_count,
  },
  repaid_back: {
    label: 'Repaid back',
    value: ({ repaid_back, repaid_back_percentage }) =>
      (repaid_back &&
        `${prettyMoney(repaid_back)} (${repaid_back_percentage}%)`) ||
      '—',
  },
  investors_earnings: {
    label: 'Investors earnings',
    value: ({ investors_earnings }) =>
      prettyMoney(investors_earnings) || EMPTY_DASH,
  },
  repayments_on_time_percentage: {
    label: 'Repayments on time Group A / B',
    value: ({
      repayments_group_a_on_time_percentage,
      repayments_group_b_on_time_percentage,
    }) => {
      let groupA = EMPTY_DASH;
      let groupB = EMPTY_DASH;
      if (typeof repayments_group_a_on_time_percentage === 'number') {
        groupA = `${repayments_group_a_on_time_percentage}%`;
      }
      if (typeof repayments_group_b_on_time_percentage === 'number') {
        groupB = `${repayments_group_b_on_time_percentage}%`;
      }
      return `${groupA} / ${groupB}`;
    },
  },
  countries: {
    label: 'Countries',
    value: ({ countries }) => countries,
  },
  loans_count: {
    label: 'Investors',
    value: ({ investors_count }) => investors_count,
  },
};

export const RoundDetailStats = ({ selectedFundraising }) => (
  <div className={s.cards}>
    {Object.keys(STATS).map(key => (
      <div className={s.card} key={key}>
        <div className={classnames('f-14', s.card__label)}>
          {STATS[key].label}
        </div>
        <div className={classnames('f-16-24', 'f-500', s.card__value)}>
          {STATS[key].value(selectedFundraising)}
        </div>
      </div>
    ))}
  </div>
);
