import React from 'react';
import VeriffForm from '../../components/Forms/VeriffForm';

const WizardVeriffModal = ({ onSuccess, onBack, onVeriffEvent }) => {
  const handleSuccess = veriffProps => {
    onSuccess && onSuccess(veriffProps);
  };
  return (
    <VeriffForm
      disableSkip
      onBack={onBack}
      onSuccess={handleSuccess}
      onVeriffEvent={onVeriffEvent}
    />
  );
};

export default WizardVeriffModal;
