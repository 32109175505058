import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { MODAL_TYPES } from '../ModalProvider';
import { getBonusAllocationInfo } from '../../store/actions/bonusActions';
import { setModals } from '../../store/actions/commonActions';
import { getBonusGroup } from '../../store/actions/profileActions';
import { prettyFloatMoney } from '../../helpers/utils';
import s from './index.module.scss';

export const BonusAdditionalAmount = ({ className }) => {
  const dispatch = useDispatch();
  const status = useSelector(state => state.bonusStore.bonusAllocationInfo);
  const { bonusGroup } = useSelector(state => state.profileStore);

  useEffect(() => {
    dispatch(getBonusAllocationInfo());
    dispatch(getBonusGroup());
  }, []);

  const onClick = () => {
    dispatch(setModals({ type: MODAL_TYPES.bonus_banner, props: bonusGroup }));
  };

  if (!status || !bonusGroup) {
    return null;
  }

  return (
    <div className={classnames(s.wrapper, className, 'f-16-24 f-400')}>
      <span>
        Invest another{' '}
        <span className="f-500">{prettyFloatMoney(status.amount_needed)}</span>{' '}
        in Group A to get an additional
      </span>{' '}
      <IconStar />{' '}
      <span className={classnames(s.bonus, 'f-500')}>
        {prettyFloatMoney(status.bonus_amount)} bonus
      </span>{' '}
      <button
        type="button"
        onClick={onClick}
        className={classnames(s.learn, 'underline')}
      >
        Learn more
      </button>
    </div>
  );
};

const IconStar = () => (
  <svg
    width="35"
    height="34"
    viewBox="0 0 35 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.999 16.9999C33.999 26.231 26.6295 33.7068 17.548 33.7068C8.46648 33.7068 1.09692 26.231 1.09692 16.9999C1.09692 7.76869 8.46648 0.292969 17.548 0.292969C26.6295 0.292969 33.999 7.76869 33.999 16.9999Z"
      fill="#FFD11C"
    />
    <path
      d="M18.2412 8.93926L20.2307 13.0579C20.2861 13.1725 20.3679 13.2716 20.4691 13.3468C20.5703 13.4219 20.6878 13.4707 20.8116 13.4891L25.2596 14.1496C25.4021 14.1708 25.5359 14.2322 25.646 14.3271C25.7561 14.4219 25.838 14.5463 25.8825 14.6863C25.9269 14.8262 25.9322 14.976 25.8977 15.1188C25.8631 15.2617 25.7901 15.3918 25.687 15.4944L22.4678 18.7003C22.2858 18.8815 22.2032 19.1424 22.2457 19.3978L23.0055 23.9248C23.0298 24.0697 23.0139 24.2188 22.9597 24.355C22.9055 24.4912 22.815 24.6092 22.6986 24.6957C22.5821 24.7821 22.4443 24.8335 22.3008 24.8441C22.1572 24.8547 22.0136 24.8241 21.8862 24.7556L17.9072 22.6179C17.7964 22.5585 17.6732 22.5275 17.5481 22.5275C17.423 22.5275 17.2998 22.5585 17.189 22.6179L13.21 24.7556C13.0826 24.8241 12.939 24.8547 12.7955 24.8441C12.6519 24.8335 12.5141 24.7821 12.3977 24.6957C12.2812 24.6092 12.1907 24.4912 12.1365 24.355C12.0822 24.2188 12.0663 24.0697 12.0907 23.9248L12.8505 19.3978C12.8717 19.2719 12.8625 19.1425 12.8238 19.021C12.7851 18.8994 12.718 18.7894 12.6283 18.7003L9.40918 15.4944C9.30607 15.3918 9.23309 15.2617 9.19856 15.1188C9.16403 14.976 9.16925 14.8262 9.21374 14.6863C9.25818 14.5463 9.34013 14.4219 9.45016 14.3271C9.56025 14.2322 9.69408 14.1708 9.83659 14.1496L14.2846 13.4891C14.4084 13.4707 14.526 13.4219 14.6271 13.3468C14.7283 13.2716 14.8101 13.1725 14.8655 13.0579L16.855 8.93926C17.1404 8.35358 17.9581 8.35358 18.2412 8.93926Z"
      fill="black"
    />
  </svg>
);
