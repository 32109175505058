import React from 'react';
import { MyLink } from '../../components/MyLink';
import { APP_LINKS } from '../../helpers/links';
import s from './index.module.scss';

const BonusInviteFriendsModal = () => {
  return (
    <div className={s.modal}>
      <div className={s.content}>
        <div className="f-22 f-500" style={{ paddingRight: '37px' }}>
          An exclusive promotion to boost your earnings and get your friends on
          board! Triple your winnings and earn up to 15% extra return with
          Scramble&apos;s Friend Referral Program
        </div>

        <div>
          <div className="f-16-24 f-500">How It Works</div>
          <ul className={s.list}>
            <li>
              1. Standard Referral Reward: Bring a friend and receive 5% of
              their investment within the first 60 days.{' '}
              <MyLink
                isExternal
                href={APP_LINKS.docAffiliateProgramScramble2024}
                underline
              >
                Learn More
              </MyLink>
            </li>
            <li>
              2. Exclusive July Bonus: Earn an additional 5% bonus on your
              friend&apos;s first investment.{' '}
              <MyLink
                isExternal
                href={APP_LINKS.docAffiliateProgramAdditional5Scramble2024}
                underline
              >
                Learn More
              </MyLink>
            </li>
            <li>
              3. Friend&apos;s Bonus: Your friend will receive a €5 bonus for
              every €100 of their first investment in Group A.{' '}
              <MyLink
                isExternal
                href={APP_LINKS.docScrambleFiveEuroProgram}
                underline
              >
                Learn More
              </MyLink>
            </li>
          </ul>
        </div>
        <div className="f-16-24">Total Reward: Up to 15% extra return!</div>
      </div>
    </div>
  );
};

export default BonusInviteFriendsModal;
