import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../store/actions/userActions';
import { getLastRoute, removeLastRoute } from '../../helpers/utils';
import { APP_LINKS } from '../../helpers/links';
import LoginForm from '../../components/Forms/LoginForm';
import Analytics from '../../analyticsGA';
import s from './index.module.scss';

const SignIn = () => {
  const { token, loginError } = useSelector(state => state.userStore);
  const dispatch = useDispatch();
  const history = useHistory();
  const existingEmail = useRef(sessionStorage.getItem('existingEmail'));

  useEffect(() => {
    if (token) {
      history.push(APP_LINKS.dashboard);
    }
    () => sessionStorage.setItem('existingEmail', '');
  }, [token]);

  const onSubmit = async values => {
    Analytics.signInLogin();
    const payload = {
      email: values.email,
      password: values.password,
    };
    const res = await dispatch(loginUser(payload));
    if (res && res.success) {
      if (res.data.temp_token) {
        return history.push(APP_LINKS.twoFactorAuth);
      }
      const lastRoute = getLastRoute();
      history.push(lastRoute || APP_LINKS.dashboard);
      removeLastRoute();
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.inner}>
        <LoginForm
          initialEmail={existingEmail.current}
          onSubmit={onSubmit}
          error={loginError}
        />
      </div>
    </div>
  );
};

export default SignIn;
