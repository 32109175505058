import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage } from '../../helpers/utils';
import Notify from '../../components/Notification';

export const changePassword = payload => {
  return axios
    .post(Pathes.Auth.changePassword, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: message });
        return { success: true };
      }
      Notify.error({
        text: 'Could not change password, incorrect credentials',
      });
      throw new Error('Could not change password');
    })
    .catch(e => ({ success: false, message: e.message }));
};

export const updateProfilePhoto = photo => {
  return axios
    .post(Pathes.User.updateProfilePhoto, { photo })
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: photo ? 'Photo updated' : 'Photo removed' });
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

export const updateProfileSummary = payload => {
  return axios
    .post(Pathes.User.doUpdatePersonalInfo, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: 'Profile details updated' });
        return { data, success: true };
      }

      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

export const updateProfileContacts = payload => {
  return axios
    .post(Pathes.User.doUpdateContactInfo, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: 'Personal information updated' });
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

export const getFounderDetail = id => {
  return axios
    .get(Pathes.Founders.detail(id))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

export const toggleSubscriptionEmails = (token, current) => {
  return axios
    .post(
      current ? Pathes.User.subscribeEmails : Pathes.User.unsubscribeEmails,
      { token }
    )
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: message });
        return { data, success: true };
      }

      Notify.error({ text: message });
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// GET veriff statuses
export const getAndRegisterVeriffStatuses = user => {
  return axios.get(Pathes.User.veriffStatuses);
};

export const setUserVeriffSkipReason = reason => {
  return axios
    .post(Pathes.User.veriffSkip, { reason })
    .then(response => {
      const { status, data } = response;
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

export const setInvestorType = investorType => {
  return axios
    .post(Pathes.User.investorType, investorType)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

export const getPhoneSentCodes = () => {
  return axios
    .get(Pathes.User.sentCodes)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

export const validatePhoneNumber = payload => {
  return axios
    .post(Pathes.User.validatePhoneNumber, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: message });
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

export const sendVerificationCode = phone => {
  return axios
    .post(Pathes.User.sentCodes, { phone_number: phone })
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Login code status
export const getLoginCodeStatus = () => {
  return axios
    .get(Pathes.Auth.loginCodeStatus)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Resend login code
export const resendLoginCode = () => {
  return axios
    .post(Pathes.Auth.resendLoginCode)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

export const verifyUserEmail = code => {
  return axios
    .post(Pathes.User.emailVerification, { code })
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, error_code: data.error_code, success: true };
      }
      return { success: false, error_code: data?.error_code, message };
    })
    .catch(e => ({ success: false, error_code: null, message: e.message }));
};

export const submitEmailVerification = () => {
  return axios
    .post(Pathes.User.sendEmailVerificationCode)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: message });
        return { data, success: true };
      }
      message && Notify.error({ text: message });
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

export const getTermsOfUseStatus = () => {
  return axios
    .get(Pathes.Investing.termsOfUseStatus)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};
