import React from 'react';
import classnames from 'classnames';
import { Tooltip } from 'react-tippy';
import { useSelector } from 'react-redux';
import {
  prettyDate,
  prettyFloatMoney,
  round,
} from '../../../../../helpers/utils';
import { CURRENCY, LOAN_GROUPS } from '../../../../../helpers/constants';
import { DATE_FORMAT_DD_MM_YYYY } from '../../../../../helpers/common';
import { MyLink } from '../../../../../components/MyLink';
import { APP_LINKS } from '../../../../../helpers/links';
import { InfoIcon } from '../../../../../components/UI/Icons';
import s from './index.module.scss';

export const Table = props => {
  const { innerWidth } = useSelector(state => state.commonStore);
  const { allocatedAmount, tab: groupId } = props;
  const repayment = props.data.repayment_schedule[0].principal;

  const calculateA = (allocated, repayment, percent) => {
    let current = allocated;

    return props.data.repayment_schedule.map((i, index) => {
      const item = {
        date: i.date,
        earnings_percent: percent,
        loan_balance: null,
        earnings: null,
        earnings_base: null,
      };

      if (index === 0) {
        item.loan_balance = current * percent;
      } else {
        current -= repayment;
        item.loan_balance = current;
      }

      item.earnings = round(item.loan_balance * 0.01, { roundUpper: true });
      item.earnings_base = item.loan_balance * 0.0075;

      return item;
    });
  };

  const calculateB = (allocated, percent) => {
    const lastMonth =
      props.data.repayment_schedule[props.data.repayment_schedule.length - 1];

    return lastMonth
      ? [
          {
            date: lastMonth.date,
            earnings_percent: percent,
            loan_balance: allocatedAmount,
            earnings: round((allocatedAmount * 9) / 100, { roundUpper: true }),
            earnings_base: null,
          },
        ]
      : [];
  };

  const data =
    groupId === LOAN_GROUPS.conservative.id
      ? calculateA(allocatedAmount, repayment, 1)
      : calculateB(allocatedAmount, 9);

  const { total_earnings, total_earnings_base } = data.reduce(
    (acc, item) => {
      acc.total_loan_balance += item.loan_balance;
      acc.total_earnings += item.earnings;
      acc.total_earnings_base += item.earnings_base;
      return acc;
    },
    { total_loan_balance: 0, total_earnings: 0, total_earnings_base: 0 }
  );

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.inner}>
          <div className={s.table}>
            <div className={s.table__thead}>
              <div className={s.table__thead_col}>Due Date</div>
              <div className={s.table__thead_col}>Loan Balance</div>
              <div className={classnames(s.table__thead_col, s.table__hide)}>
                Earnings (%)
              </div>
              <div className={s.table__thead_col}>Earnings ({CURRENCY})</div>
            </div>

            <div className={s.table__tbody}>
              {data.map((item, index) => (
                <div key={index} className={classnames(s.table__tbody_row)}>
                  <div className={s.table__tbody_col}>
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {prettyDate(item.date, DATE_FORMAT_DD_MM_YYYY)}
                    </span>
                  </div>
                  <div className={s.table__tbody_col}>
                    {prettyFloatMoney(item.loan_balance)}
                  </div>
                  <div
                    className={classnames(s.table__tbody_col, s.table__hide)}
                  >
                    {groupId === LOAN_GROUPS.conservative.id
                      ? `up to ${item.earnings_percent}%`
                      : `${item.earnings_percent}%`}
                  </div>
                  <div className={s.table__tbody_col}>
                    {prettyFloatMoney(item.earnings)}{' '}
                    {innerWidth < 700 && (
                      <>
                        <span
                          style={{ display: 'inline-block', width: '5px' }}
                        />
                        <Tooltip
                          theme="light"
                          trigger="click"
                          arrow
                          interactive
                          html={
                            <div
                              className="f-16 f-400"
                              style={{ maxWidth: '350px' }}
                            >
                              {groupId === LOAN_GROUPS.conservative.id
                                ? 'Earnings (%) - Up to 1%'
                                : 'Earnings (%) - 9%'}
                            </div>
                          }
                        >
                          <InfoIcon
                            style={{
                              position: 'relative',
                              top: '4px',
                              cursor: 'pointer',
                            }}
                            onClick={e => e.stopPropagation()}
                          />
                        </Tooltip>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={classnames(s.result, 'f-16-24')}>
        <div className="f-500">Total earnings:</div>
        <div className={s.table__tbody_col}>
          {groupId === LOAN_GROUPS.conservative.id && (
            <span style={{ color: '#9E98A3' }}>up to</span>
          )}{' '}
          <span className="f-500">
            {prettyFloatMoney(
              groupId === LOAN_GROUPS.conservative.id
                ? total_earnings_base + props.data.increased_income
                : total_earnings,
              { roundUpper: true }
            )}
          </span>
        </div>
      </div>

      {groupId === LOAN_GROUPS.conservative.id && (
        <div className={classnames(s.hint, 'f-16-24')}>
          Please note that the basic return will be calculated at 0.75%, which
          amounts to{' '}
          <span>
            {prettyFloatMoney(total_earnings_base, { roundUpper: true })}
          </span>
          . You may also be eligible to receive an additional{' '}
          <span>{prettyFloatMoney(props.data.increased_income)}</span> provided
          you meet the conditions for a 1% return. See{' '}
          <MyLink href={APP_LINKS.helpLoanTerms()} underline>
            Loan Terms
          </MyLink>{' '}
          for more details.
        </div>
      )}
    </>
  );
};
