import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useGetAllocationStatus } from '../../../../hooks/useGetAllocationStatus';
import { LOAN_GROUPS } from '../../../../helpers/constants';
import { Table } from './table';
import s from './index.module.scss';

export const EarningSchedule = () => {
  const [tab, setTab] = useState('conservative');

  const { data } = useSelector(
    state => state.investStore.allocatedRepaymentSchedule
  );

  const { allocatedGroupA, allocatedGroupB } = useGetAllocationStatus();

  useEffect(() => {
    if (allocatedGroupA) {
      setTab(LOAN_GROUPS.conservative.id);
    } else if (allocatedGroupB) {
      setTab(LOAN_GROUPS.moderate.id);
    }
  }, [allocatedGroupA, allocatedGroupB]);

  if (!data || !tab) {
    return null;
  }

  return (
    <div className={s.wrapper}>
      <div className="f-16-24">
        Here you can see your planed monthly accrued income and see the
        estimated amount you&apos;ll receive at the end of the 6-month loan
        period.
      </div>
      <div className={s.schedule} style={{ marginTop: '10px' }}>
        <div className={classnames(s.tabs, 'f-20 f-400')}>
          {!!allocatedGroupA && (
            <div
              className={classnames(
                s.tab,
                tab === LOAN_GROUPS.conservative.id && 'active'
              )}
              onClick={() => setTab(LOAN_GROUPS.conservative.id)}
            >
              {LOAN_GROUPS.conservative.full_name}
            </div>
          )}
          {!!allocatedGroupB && (
            <div
              className={classnames(
                s.tab,
                tab === LOAN_GROUPS.moderate.id && 'active'
              )}
              onClick={() => setTab(LOAN_GROUPS.moderate.id)}
            >
              {LOAN_GROUPS.moderate.full_name}
            </div>
          )}
        </div>
        <Table
          tab={tab}
          data={data}
          allocatedAmount={
            tab === LOAN_GROUPS.conservative.id
              ? allocatedGroupA
              : tab === LOAN_GROUPS.moderate.id
              ? allocatedGroupB
              : 0
          }
        />
      </div>
    </div>
  );
};
