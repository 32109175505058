import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { prettyFloatMoney } from '../../../../helpers/utils';
import { DATE_FORMAT_YYYY } from '../../../../helpers/common';
import { setModals } from '../../../../store/actions/commonActions';
import { MODAL_TYPES } from '../../../ModalProvider';
import { RoundButton } from '../../../UI/RoundButton';
import EmptyImage from '../../../../assets/images/startup_mock.svg';
import { MyLink } from '../../../MyLink';
import s from './index.module.scss';

export const StartupCard = ({
  startup,
  href,
  hideFR,
  onMoreDetails,
  className,
}) => {
  const dispatch = useDispatch();
  const isReviewing = startup.fundraising_goal === 0;

  const onCompanyClick = () => {
    typeof startup.id === 'number' &&
      dispatch(
        setModals({
          type: MODAL_TYPES.company_info,
          companyID: startup.id,
        })
      );
  };

  const content = (
    <>
      {isReviewing && (
        <div className={s.review}>
          <span>Reviewing</span>
        </div>
      )}

      <div className={s.image}>
        <img src={startup.promo_image?.file || EmptyImage} alt={startup.name} />
      </div>

      <div className={s.inner}>
        <div className={s.top}>
          <div className={s.header}>
            <div className={s.header_left}>
              <h4 className={classnames('f-26-32 f-500', s.title)}>
                {startup.name}
              </h4>
              <p className={s.desc}>{startup.promo_theme}</p>
            </div>
            {isReviewing ||
              (!hideFR && (
                <div className={classnames(s.header_right, 'f-18-24 f-500')}>
                  <span>
                    {prettyFloatMoney(
                      startup.fundraising_goal || startup.max_allowed_goal
                    )}
                  </span>
                </div>
              ))}
          </div>

          <div className={classnames('f-16 f-400', s.description)}>
            {startup.promo_description}
          </div>
        </div>
        <div
          className={classnames(s.bottom, isReviewing && s.bottom_reviewing)}
        >
          <div className={s.info}>
            <div className={classnames('f-16 f-400', s.country)}>
              {startup.country_code && (
                <img
                  src={`https://flagcdn.com/${startup.country_code?.toLowerCase()}.svg`}
                  alt="flag"
                />
              )}
              {startup.country}
            </div>
            {startup.foundation_date && (
              <div className={classnames('f-16 f-400', s.foundation)}>
                Started in{' '}
                {moment(startup.foundation_date).format(DATE_FORMAT_YYYY)}
              </div>
            )}
          </div>

          <div className={s.buttons}>
            <RoundButton
              label="More details"
              className={classnames(
                s.button,
                typeof startup.id !== 'number' && 'hidden'
              )}
              onClick={() => {
                onMoreDetails && onMoreDetails(startup);
                !href && onCompanyClick();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );

  return href ? (
    <MyLink href={href} className={classnames(s.wrapper, className)}>
      {content}
    </MyLink>
  ) : (
    <div className={classnames(s.wrapper, className)} onClick={onCompanyClick}>
      {content}
    </div>
  );
};
