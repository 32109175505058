import React from 'react';
import classnames from 'classnames';
import { Tooltip } from '../../../../kit/new/Tooltip';
import { LOAN_GROUPS } from '../../../../helpers/constants';
import { prettyFloatMoney } from '../../../../helpers/utils';
import s from './index.module.scss';

export const RoundDetailGroups = ({ loanGroups }) => {
  const getStatusLabel = status => {
    switch (status) {
      case 'no_payments':
        return 'No payments';
      case 'scheduled':
        return 'Scheduled';
      case 'completed':
        return 'Completed';
      case 'in_progress':
        return 'In progress';
      default:
        return status;
    }
  };

  return (
    <div className={s.wrapper}>
      {loanGroups?.map(group => {
        const remaining = group.invested - group.repaid_back;

        return (
          <div key={group.id} className={s.group}>
            <div className={s.group__title}>
              <span className="f-18-24">{LOAN_GROUPS[group.id].full_name}</span>
            </div>
            <div className={s.group__info}>
              <div className={s.group__info_row}>
                <div className={s.group__info_label}>
                  <Tooltip
                    id="investing"
                    content="The total amount of money invested in the round"
                  >
                    <span className={classnames(s.group__info_text, 'f-14')}>
                      Invested
                    </span>
                  </Tooltip>
                </div>
                <div className={classnames(s.group__info_value, 'f-14')}>
                  {prettyFloatMoney(group.invested)}
                </div>
              </div>
              <div className={s.group__info_row}>
                <div className={s.group__info_label}>
                  <Tooltip
                    id="brands"
                    content="The number of brands participating in the round"
                  >
                    <span className={classnames(s.group__info_text, 'f-14')}>
                      Brands
                    </span>
                  </Tooltip>
                </div>
                <div className={classnames(s.group__info_value, 'f-14')}>
                  {group.startups_count}
                </div>
              </div>
              <div className={s.group__info_row}>
                <div className={s.group__info_label}>
                  <Tooltip
                    id="Investors"
                    content="The number of investors participating in the round"
                  >
                    <span className={classnames(s.group__info_text, 'f-14')}>
                      Investors
                    </span>
                  </Tooltip>
                </div>
                <div className={classnames(s.group__info_value, 'f-14')}>
                  {group.investors_count}
                </div>
              </div>
              <div className={s.group__info_row}>
                <div className={s.group__info_label}>
                  <Tooltip
                    id="Repayment status"
                    content="General status of the round"
                  >
                    <span className={classnames(s.group__info_text, 'f-14')}>
                      Repayment status
                    </span>
                  </Tooltip>
                </div>
                <div className={classnames(s.group__info_value, 'f-14')}>
                  <span>{getStatusLabel(group.repayment_status)}</span>
                </div>
              </div>
              <div className={s.group__info_row}>
                <div className={s.group__info_label}>
                  <Tooltip
                    id="Repaid back"
                    content="The total amount of funds successfully repaid to investors"
                  >
                    <span className={classnames(s.group__info_text, 'f-14')}>
                      Repaid back
                    </span>
                  </Tooltip>
                </div>
                <div className={classnames(s.group__info_value, 'f-14')}>
                  {prettyFloatMoney(group.repaid_back)}
                </div>
              </div>
              <div className={s.group__info_row}>
                <div className={s.group__info_label}>
                  <Tooltip
                    id="Investors earnings"
                    content="The total amount of funds earned by investors in the round"
                  >
                    <span className={classnames(s.group__info_text, 'f-14')}>
                      Investors earnings
                    </span>
                  </Tooltip>
                </div>
                <div className={classnames(s.group__info_value, 'f-14')}>
                  {prettyFloatMoney(group.investors_earnings)}
                </div>
              </div>
            </div>
            <div className={s.group__progress}>
              <div className={s.group__progress_header}>
                <div className="f-18-24">Repayment progress</div>
                <div className="f-18-24">{group.repaid_back_percentage}%</div>
              </div>
              <div className={s.group__bar}>
                <div
                  className={s.group__bar_paid}
                  style={{ width: `${group.repaid_back_percentage}%` }}
                />
              </div>

              <div className={s.group__bar_stats}>
                <div className={s.group__bar_row}>
                  <div className={s.group__bar_circle} />
                  <div className="f-12">
                    {prettyFloatMoney(group.repaid_back)}
                  </div>
                  <div className="f-12">Paid</div>
                </div>
                <div className={s.group__bar_row}>
                  <div
                    className={classnames(
                      s.group__bar_circle,
                      s.group__bar_circle_remaining
                    )}
                  />
                  <div className="f-12">{prettyFloatMoney(remaining)}</div>
                  <div className="f-12">Remaining</div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
