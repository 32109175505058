import { SET_BONUS_ALLOCATION_INFO } from '../types/bonusTypes';

const initialState = {
  bonusAllocationInfo: null,
};

const bonusReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BONUS_ALLOCATION_INFO:
      return {
        ...state,
        bonusAllocationInfo: action.payload,
      };

    default:
      return state;
  }
};

export default bonusReducer;
