export const SEO_EVENTS = {
  sign_up_with_google: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'signup_page',
      var_name: 'Sign up',
      var_desc: 'with Google',
    },
  },
  sign_up_with_apple: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'signup_page',
      var_name: 'Sign up',
      var_desc: 'with Apple',
    },
  },
  sign_up_email_input: {
    event: 'click_field',
    event_params: {
      var_pagetype: 'signup_page',
      var_name: 'email',
      var_desc: '',
    },
  },
  sign_up_password_input: {
    event: 'click_field',
    event_params: {
      var_pagetype: 'signup_page',
      var_name: 'password',
      var_desc: '',
    },
  },
  sign_up_register: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'signup_page',
      var_name: 'Register',
      var_desc: '',
    },
  },
  public_header_sign_in: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'signup_page',
      var_name: 'Sign in',
      var_desc: '',
    },
  },
  sign_in_with_google: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'signin_page',
      var_name: 'Sign in',
      var_desc: 'with Google',
    },
  },
  sign_in_with_apple: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'signin_page',
      var_name: 'Sign in',
      var_desc: 'with Apple',
    },
  },
  sign_in_email_input: {
    event: 'click_field',
    event_params: {
      var_pagetype: 'signin_page',
      var_name: 'email',
      var_desc: '',
    },
  },
  sign_in_password_input: {
    event: 'click_field',
    event_params: {
      var_pagetype: 'signin_page',
      var_name: 'password',
      var_desc: '',
    },
  },
  sign_in_login: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'signin_page',
      var_name: 'Login',
      var_desc: '',
    },
  },
  sign_in_forgot_pwd: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'signin_page',
      var_name: 'Forgot password',
      var_desc: '',
    },
  },
  public_header_sign_up: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'signin_page',
      var_name: 'Sign up',
      var_desc: '',
    },
  },
  sign_up_personal_data_submit: {
    event: 'input_data',
    event_params: {
      var_pagetype: 'registration_page',
      var_name: 'Personal data',
      var_desc: '',
    },
  },
  sign_up_contacts_data_submit: {
    event: 'input_data',
    event_params: {
      var_pagetype: 'registration_page',
      var_name: 'Contacts data',
      var_desc: '',
    },
  },
  sign_up_phone_vrification: {
    event: 'phone_verified',
    event_params: {
      var_pagetype: 'registration_page',
      var_name: 'Phone verified',
      var_desc: '',
    },
  },
  veriff_pass: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'personal_verification',
      var_name: 'Start session',
      var_desc: '',
    },
  },
  root_page: (linkName = '') => ({
    event: 'click_btn',
    event_params: {
      var_pagetype: 'personal_cabinet',
      var_name: 'link_click',
      var_desc: linkName,
    },
  }),
  page_navigation: (pageName = '', pageType = 'personal_cabinet') => ({
    event: 'click_btn',
    event_params: {
      var_pagetype: pageType,
      var_name: pageName,
      var_desc: '',
    },
  }),
  add_cash_amount: (variantName = '') => ({
    event: 'click_btn',
    event_params: {
      var_pagetype: 'finance_operations',
      var_name: variantName,
      var_desc: '',
    },
  }),
  add_cash_payment_method: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'finance_operations',
      var_name: 'Payment method change',
      var_desc: '',
    },
  },
  add_cash_go_to_next_payment: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'finance_operations',
      var_name: 'Go to next step',
      var_desc: '',
    },
  },
  withdraw_add_new_bank_account: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'finance_operations',
      var_name: 'Add a new bank account',
      var_desc: '',
    },
  },
  withdraw_withdraw_submit: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'finance_operations',
      var_name: 'Withdraw',
      var_desc: '',
    },
  },
  investing_join: (description = '', event = 'click_btn') => ({
    event,
    event_params: {
      var_pagetype: 'investors',
      var_name: 'Join the round',
      var_desc: description,
    },
  }),
  investing_preview_brand: (brandName = '') => ({
    event: 'click_btn',
    event_params: {
      var_pagetype: 'investors',
      var_name: brandName,
      var_desc: '',
    },
  }),
  investing_strategy_step: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'investors',
      var_name: 'Strategy',
      var_desc: '',
    },
  },
  investing_back_button: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'investors',
      var_name: 'Back',
      var_desc: '',
    },
  },
  investing_allocate_step: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'investors',
      var_name: 'Allocate cash',
      var_desc: '',
    },
  },
  investing_allocate_group: (groupName = '') => ({
    event: 'click_btn',
    event_params: {
      var_pagetype: 'investors',
      var_name: 'Allocate cash',
      var_desc: groupName,
    },
  }),
  investing_review_step: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'investors',
      var_name: 'Review the settings',
      var_desc: '',
    },
  },
  investing_review_save_settings: {
    event: 'click_btn',
    event_params: {
      var_pagetype: 'investors',
      var_name: 'Save settings',
      var_desc: '',
    },
  },
  sign_in_page: {
    event: 'login_start',
    event_params: {
      var_pagetype: 'investors',
      var_name: 'Sign in page',
      var_desc: 'Начало входа в кабинет',
    },
  },
  add_cash_page: {
    event: 'add_funds_started',
    event_params: {
      var_pagetype: 'investors',
      var_name: 'Add Cash page',
      var_desc: 'Пополнение денег',
    },
  },
  investing_page: {
    event: 'investing_look',
    event_params: {
      var_pagetype: 'investors',
      var_name: 'Investing page',
      var_desc: 'Переход в раздел Инвестрования',
    },
  },
  sign_up_page: {
    event: 'registration_start',
    event_params: {
      var_pagetype: 'investors',
      var_name: 'Sign Up page',
      var_desc: 'Начало регистрации',
    },
  },
};
