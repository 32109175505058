import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage } from '../../helpers/utils';
import {
  GET_BALANCE,
  GET_EARNINGS,
  GET_FR_DATES,
  GET_HAPPY_FOUNDERS,
  GET_LOAN_GROUPS,
} from './actionTypes';

const getLoanGroupsRequest = () => ({ type: GET_LOAN_GROUPS.REQUEST });
const getLoanGroupsSuccess = payload => ({
  type: GET_LOAN_GROUPS.SUCCESS,
  payload,
});
const getLoanGroupsFailure = error => ({
  type: GET_LOAN_GROUPS.FAILURE,
  error,
});

export const getBalance = () => {
  return dispatch => {
    return axios
      .get(Pathes.Dashboard.balance)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch({ type: GET_BALANCE, balance: data });
          return data;
        }
        throw new Error(message);
      })
      .catch(e => ({ error: e.message }));
  };
};

export const getFundraisingDates = () => {
  return dispatch => {
    return axios
      .get(Pathes.Dashboard.fundraisingDates)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch({ type: GET_FR_DATES, payload: data });
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => ({ error: e.message }));
  };
};

export const getEarnings = () => {
  return dispatch => {
    return axios
      .get(Pathes.Dashboard.earnings)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch({ type: GET_EARNINGS, earnings: data });
          return data;
        }

        throw new Error(message);
      })
      .catch(e => ({ error: e.message }));
  };
};

export const getHappyFounders = () => {
  return dispatch => {
    return axios
      .get(Pathes.Dashboard.happyFounders)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch({ type: GET_HAPPY_FOUNDERS, founders: data });
          return data;
        }

        throw new Error(message);
      })
      .catch(e => ({ error: e.message }));
  };
};

export const getLoanGroups = () => {
  return dispatch => {
    dispatch(getLoanGroupsRequest());
    return axios
      .get(Pathes.Dashboard.loanGroups)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getLoanGroupsSuccess(data));
          return data;
        }

        throw new Error(message);
      })
      .catch(e => dispatch(getLoanGroupsFailure(e.message)));
  };
};
