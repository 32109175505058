import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { LatestArticles } from './latest';
import Preloader from '../../components/Preloader';
import {
  getBlogArticleDetail,
  getBlogBanners,
} from '../../store/actions/blogActions';
import { APP_LINKS } from '../../helpers/links';
import { DATE_FORMAT_DD_MMM_YYYY } from '../../helpers/common';
import { prettyDate } from '../../helpers/utils';
import s from './index.module.scss';

const BlogDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { slug } = useParams();
  const { data: post, loading } = useSelector(
    state => state.blogStore.blogArticleDetail
  );

  const latestPosts = useSelector(state => state.blogStore.blogBanners);

  useEffect(() => {
    if (slug) {
      dispatch(getBlogArticleDetail(slug));
      dispatch(getBlogBanners());
    } else {
      history.replace(APP_LINKS.dashboard);
    }
  }, [slug]);

  if (loading || !post) {
    return (
      <div className="container-inner">
        <Preloader className={s.preloader} />
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      <div className="container-inner">
        <div className={s.header}>
          <div className={s.container}>
            <div className={classnames('f-16-24', s.top)}>
              <div className={s.type}>{post.subtitle}</div>
              <div style={{ color: '#666666' }}>
                {prettyDate(post.created_at, DATE_FORMAT_DD_MMM_YYYY)}
              </div>
            </div>
            <h1 className={classnames('f-32', 'f-500', s.title)}>
              {post.title}
            </h1>
            <p className={classnames('f-16-24', s.subtitle)}>
              {post.description}
            </p>
          </div>
        </div>

        <div className={s.container}>
          <div className={s.image}>
            <img
              src={post.main_image.replace('test-investor', 'investor')}
              alt={post.title}
              width={850}
              height={340}
              loading="lazy"
            />
          </div>
        </div>

        <div className={s.container}>
          <div className={s.content}>
            <div className={s.container}>
              <div
                className="unreset"
                dangerouslySetInnerHTML={{ __html: post.text }}
              />
            </div>
          </div>
        </div>
        <LatestArticles posts={latestPosts || []} />
      </div>
    </div>
  );
};

export default BlogDetailPage;
