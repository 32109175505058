import React from 'react';
import classnames from 'classnames';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

import { RoundLink } from '../../../../components/UI/RoundLink';
import { APP_LINKS } from '../../../../helpers/links';

import 'swiper/css';
import s from './index.module.scss';

export const Slider = ({ slides }) => {
  return (
    <Swiper
      slidesPerView={1}
      autoHeight
      loop
      spaceBetween={20}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 7000,
        disableOnInteraction: false,
      }}
      navigation
      modules={[Pagination, Navigation, Autoplay]}
      className={s.slider}
    >
      {slides.map((slide, idx) => {
        return (
          <SwiperSlide key={idx}>
            <div className={s.card}>
              <div className={s.card__content}>
                <div className={s.card__inner}>
                  <div className={s.card__info}>
                    <div className={classnames(s.card__title, 'f-32 f-500')}>
                      {slide.title}
                    </div>
                    <div className={classnames(s.card__desc, 'f-16-24')}>
                      {slide.description}
                    </div>
                  </div>
                  <div className={s.card__image}>
                    <img src={slide.main_image} alt="Image card" />
                  </div>
                </div>
                <div className={s.card__buttons}>
                  <RoundLink
                    path={APP_LINKS.blogDetail(slide.slug)}
                    className={s.card__button}
                    showArrow
                    variant="black"
                    label="Read article"
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
