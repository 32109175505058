import React, { useEffect, useMemo, useRef, useState } from 'react';
import Decimal from 'decimal.js-light';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { prettyFloatMoney } from '../../../helpers/utils';
import { getLoanEarningsByFundraisingID } from '../../../store/services/loanServices';
import { LOAN_EARNINGS_STATUSES } from '../../../helpers/loan';
import { EMPTY_DASH } from '../../../helpers/constants';
import s from './index.module.scss';

export const LoanDetails = ({ fundraisingID }) => {
  const mounted = useRef(true);
  const [accrued, setAccrued] = useState({
    moderate: null,
    conservative: null,
  });

  const { data: loans } = useSelector(
    state => state.loanStore.loansByFundraisingID
  );

  const { data } = useSelector(
    state => state.loanStore.repaymentScheduleByFundraisingID
  );

  const isPaid = useMemo(
    () =>
      !!(
        data &&
        data.list.filter(s => s.status === 'paid').length === data.list.length
      ),
    [data]
  );

  useEffect(() => {
    if (loans?.length) {
      loans.map(loan => {
        getLoanEarningsByFundraisingID(fundraisingID, loan.id).then(res => {
          const accruedSum = res.data?.list.reduce((acc, item) => {
            if (item.status === LOAN_EARNINGS_STATUSES.accrued) {
              acc = Decimal(item.earnings_currency).add(acc).toFixed(2);
            }
            return acc;
          }, 0);
          mounted && setAccrued(prev => ({ ...prev, [loan.id]: accruedSum }));
        });
      });
    }
    () => {
      setMounted(false);
    };
  }, [loans?.length]);

  if (!loans?.length) {
    return null;
  }

  return (
    <div className={s.details}>
      <h2 className="f-26 f-500">Loan details</h2>

      <div className={s.table_wrapper}>
        <div className={s.table_inner}>
          <div className={s.table}>
            <div className={classnames(s.thead)}>
              <div className={s.thead_col}>Loan group</div>
              <div className={s.thead_col}>Loan amount</div>
              <div className={s.thead_col}>Outstanding loan</div>
              <div className={s.thead_col}>Flat fee accrued</div>
              <div className={s.thead_col}>Flat fee paid</div>
            </div>

            <div className={s.tbody}>
              {loans.map((loan, idx) => (
                <div className={classnames(s.tbody_row)} key={idx}>
                  <div className={s.tbody_col}>{loan.title}</div>
                  <div className={s.tbody_col}>
                    {prettyFloatMoney(loan.loans.total_loan_amount)}
                  </div>
                  <div className={s.tbody_col}>
                    {prettyFloatMoney(loan.loans.total_remain_amount)}
                  </div>
                  <div className={s.tbody_col}>
                    {isPaid
                      ? '€0'
                      : accrued[loan.id] === null
                      ? EMPTY_DASH
                      : `€${accrued[loan.id]}`}
                  </div>
                  <div className={s.tbody_col}>
                    {prettyFloatMoney(loan.loans.flat_fee_paid)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
