import React, { useEffect } from 'react';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { connect, useDispatch } from 'react-redux';
import ROUTES from './routes';
import { setInnerWidth } from './store/actions/commonActions';
import { ModalProvider } from './components/ModalProvider';
import { removeLastRoute, saveLastRoute, scrollTop } from './helpers/utils';
import { EMAIL_REGEX } from './helpers/common';
import { saveUTMtoCookie } from './helpers/utm';

const allowedRoutes = user =>
  ROUTES.filter(route => (route.auth ? route.auth(user) : true)) || [];

function App({ user }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    dispatch(setInnerWidth(window.innerWidth));
    document.querySelector(':root').style.setProperty('--vh', `${vh}px`);
  };

  const collectUTM = pathname => {
    const { utm_medium, utm_source, utm_campaign, click_id, ref } = qs.parse(
      pathname.search.replace('?', '')
    );
    if (utm_medium || utm_source || utm_campaign || click_id) {
      saveUTMtoCookie({ utm_medium, utm_source, utm_campaign, click_id, ref });
    }
  };

  useEffect(() => {
    const { email } = qs.parse(location.search.replace('?', ''));
    if (email) {
      if (EMAIL_REGEX.test(email)) {
        sessionStorage.setItem('existingEmail', email);
      }
    }
  }, []);

  useEffect(() => {
    saveLastRoute(location);
    collectUTM(location);
  }, [location]);

  useEffect(() => {
    history.listen(() => {
      scrollTop();
    });

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      removeLastRoute();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.document.body.insertAdjacentHTML(
      'beforeend',
      `<div id="build" class="${process.env.REACT_APP_RELEASE}">${process.env.REACT_APP_RELEASE}</div>`
    );
  }, []);

  return (
    <>
      {renderRoutes(allowedRoutes(user))}
      <ModalProvider />
    </>
  );
}

const mapStateToProps = state => ({
  user: state.userStore.user,
});

const mapDispatchToProps = dispatch => ({
  setInnerWidth: width => dispatch(setInnerWidth(width)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
