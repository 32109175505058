import React from 'react';
import moment from 'moment';
import { DATE_FORMAT_DD_MMM_YYYY } from '../../../../../helpers/common';
import { prettyFloatMoney } from '../../../../../helpers/utils';
import s from './index.module.scss';

export const ExampleList = ({ data }) => (
  <div className={s.example_list}>
    <div className={s.example_list__list}>
      {data.list.map((item, idx) => (
        <div className={s.example_list__card} key={idx}>
          <div className={s.example_list__card__info}>
            <div>
              №{idx + 1}. {moment(item.date).format(DATE_FORMAT_DD_MMM_YYYY)}
            </div>

            {item.outstanding_principal && (
              <div className={s.example_list__card__row}>
                <div className="c-grey f-14 f-400">Outstanding principal</div>
                <div className="f-16 f-500">
                  {item.outstanding_principal
                    ? prettyFloatMoney(item.outstanding_principal)
                    : '-'}
                </div>
              </div>
            )}

            {item.flat_fee_accrued && (
              <div className={s.example_list__card__row}>
                <div className="c-grey f-14 f-400"> Basic Flat Fee Accrued</div>
                <div className="f-16 f-500">
                  {item.flat_fee_accrued
                    ? prettyFloatMoney(item.flat_fee_accrued)
                    : '-'}
                </div>
              </div>
            )}

            <div className={s.example_list__card__row}>
              <div className="c-grey f-14 f-400">Flat Fee Paid</div>
              <div className="f-16 f-500">
                {item.fee ? prettyFloatMoney(item.fee) : '-'}
              </div>
            </div>

            <div className={s.example_list__card__row}>
              <div className="c-grey f-14 f-400">Principal</div>
              <div className="f-16 f-500">
                {item.principal ? prettyFloatMoney(item.principal) : '-'}
              </div>
            </div>

            <div className={s.example_list__card__row}>
              <div className="c-grey f-14 f-400">Total</div>
              <div className="f-18 f-500">{prettyFloatMoney(item.total)}</div>
            </div>
          </div>
        </div>
      ))}
    </div>

    <div className={s.example_list__summary}>
      <div className={s.example_list__summary_row}>
        <div className="f-16 f-500">Total</div>
        <div className="f-18 f-500">{prettyFloatMoney(data.total)}</div>
      </div>

      <div className={s.example_list__summary_row}>
        <div className="f-16 f-500">Interest</div>
        <div className="f-18 f-500">
          {prettyFloatMoney(data.total_interest)}
        </div>
      </div>

      {data.total_outstanding_principal && (
        <div className={s.example_list__summary_row}>
          <div className="f-16 f-500">Outstanding principal</div>
          <div className="f-18 f-500">
            {prettyFloatMoney(data.total_outstanding_principal)}
          </div>
        </div>
      )}

      {data.total_flat_fee_accrued && (
        <div className={s.example_list__summary_row}>
          <div className="f-16 f-500">Basic Flat Fee Accrued</div>
          <div className="f-18 f-500">
            {prettyFloatMoney(data.total_flat_fee_accrued)}
          </div>
        </div>
      )}

      <div className={s.example_list__summary_row}>
        <div className="f-16 f-500">Flat Fee Paid</div>
        <div className="f-18 f-500">
          {prettyFloatMoney(data.total_flat_fee)}
        </div>
      </div>

      <div className={s.example_list__summary_row}>
        <div className="f-16 f-500">Principal</div>
        <div className="f-18 f-500">
          {prettyFloatMoney(data.total_principal)}
        </div>
      </div>
    </div>
  </div>
);
