import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import {
  IconCompleted,
  IconExtended,
  IconPartCompleted,
  IconUnderpaid,
  IconWaiting,
} from './icon';
import { Tooltip } from '../../../../../kit/new/Tooltip';
import s from './index.module.scss';

const REPAYMENT_STATUSES = {
  completed: 'completed',
  extended: 'extended',
  underpaid: 'underpaid',
  paid_overdue: 'paid_overdue',
  postponed: 'postponed',
  waiting: 'waiting',
  no_payments: 'no_payments',
};

export const MonthStatus = ({ month, index, className }) => {
  const getIcon = status => {
    switch (status) {
      case REPAYMENT_STATUSES.extended:
        return <IconExtended />;
      case REPAYMENT_STATUSES.postponed:
        return <IconPartCompleted />;
      case REPAYMENT_STATUSES.underpaid:
        return <IconUnderpaid />;
      case REPAYMENT_STATUSES.completed:
        return <IconCompleted />;
      default:
        return <IconWaiting />;
    }
  };

  const getStatusLabel = status => {
    switch (status) {
      case REPAYMENT_STATUSES.extended:
        return 'Extended';
      case REPAYMENT_STATUSES.postponed:
        return 'Completed';
      case REPAYMENT_STATUSES.underpaid:
        return 'Underpaid';
      case REPAYMENT_STATUSES.completed:
        return 'Completed';
      default:
        return 'Waiting';
    }
  };

  const getTooltip = status => {
    const icon = (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: 'pointer' }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.79989 13.1768C10.8927 13.1768 13.3999 10.6696 13.3999 7.57681C13.3999 4.48401 10.8927 1.97681 7.79989 1.97681C4.7071 1.97681 2.19989 4.48401 2.19989 7.57681C2.19989 10.6696 4.7071 13.1768 7.79989 13.1768ZM8.39286 7.28971C7.95073 7.52529 7.30763 7.91793 7.30763 9.09585H8.51344C8.51344 8.70321 8.63402 8.50689 8.99576 8.31057C9.0326 8.28898 9.07079 8.26739 9.10983 8.24531C9.28379 8.14696 9.47473 8.039 9.63886 7.87867C10.1212 7.4075 10.2418 6.70074 9.96041 6.03326C9.63886 5.28724 8.83499 4.77681 7.91054 4.77681C6.38318 4.77681 5.78028 6.03326 5.69989 6.70074L6.9057 6.8578C6.9057 6.70074 7.06647 5.95473 7.91054 5.95473C8.43305 5.95473 8.71441 6.22958 8.83499 6.50442C8.87518 6.58295 8.95557 6.8578 8.7546 7.05412C8.67421 7.13265 8.55363 7.21118 8.39286 7.28971ZM8.65855 10.5796C8.69784 10.4823 8.71683 10.3783 8.71441 10.2738C8.70966 10.0686 8.62287 9.87339 8.47263 9.72994C8.32239 9.58649 8.12063 9.50619 7.91054 9.50625C7.70045 9.5063 7.49873 9.58669 7.34857 9.73021C7.1984 9.87374 7.11171 10.069 7.10707 10.2742C7.1047 10.3787 7.12374 10.4827 7.16308 10.58C7.20242 10.6773 7.26126 10.7659 7.33614 10.8407C7.41102 10.9155 7.50044 10.9749 7.59915 11.0154C7.69786 11.056 7.80386 11.0768 7.91094 11.0768C8.01802 11.0768 8.12402 11.0559 8.2227 11.0153C8.32139 10.9747 8.41078 10.9152 8.48562 10.8404C8.56047 10.7656 8.61926 10.677 8.65855 10.5796Z"
          fill="#00B67A"
        />
      </svg>
    );

    switch (status) {
      case REPAYMENT_STATUSES.extended:
        return (
          <Tooltip
            id="earnings"
            content={`${month.extended_brands
              .map(b => `"${b.name}"`)
              .join(', ')} brand${
              month.extended_brands.length > 1 ? 's' : ''
            } ${month.status}`}
          >
            {icon}
          </Tooltip>
        );
      case REPAYMENT_STATUSES.postponed:
        return (
          <Tooltip
            id="earnings"
            content={`${month.postponed_brands
              .map(b => `"${b.name}"`)
              .join(', ')} brand${
              month.postponed_brands.length > 1 ? 's' : ''
            } ${month.status}`}
          >
            {icon}
          </Tooltip>
        );
      case REPAYMENT_STATUSES.underpaid:
        return (
          <Tooltip id="earnings" content="Underpaid">
            {icon}
          </Tooltip>
        );
      default:
        return null;
    }
  };

  return (
    <div className={classnames(s.status__wrapper, className, month.status)}>
      <div className={classnames(s.status__icon, month.status, 'status_icon')}>
        {getIcon(month.status)}
      </div>
      <div className={s.status__content}>
        <div className={s.status__info}>
          <div className={classnames(s.status__month_number, month.status)}>
            {index} month
          </div>
          <div className={classnames(s.status__month, month.status)}>
            {moment(month.deadline).format('MMMM')}’
            {moment(month.deadline).format('YY')}
          </div>
        </div>
        <div className={classnames(s.status__badge, month.status)}>
          <span>{getStatusLabel(month.status)}</span>
          {getTooltip(month.status)}
        </div>
      </div>
    </div>
  );
};
