import React from 'react';
import { TAX_REPORT_CELLS } from './cells';
import { CURRENCY } from '../../../helpers/constants';
import s from './index.module.scss';

export const Table = ({ data }) => {
  const list = Object.keys(data).map(key => {
    return {
      label: TAX_REPORT_CELLS[key].label,
      value: TAX_REPORT_CELLS[key].value(data),
    };
  });

  return (
    <div className={s.activity_module__table_wrap}>
      <div className={s.activity_module__table_inner}>
        <div className={s.activity_module__table}>
          <div className={s.activity_module__thead}>
            <div className={s.activity_module__thead_col}>Activity</div>
            <div className={s.activity_module__thead_col}>
              Amount ({CURRENCY})
            </div>
          </div>

          <div className={s.activity_module__tbody}>
            {list.map((item, index) => (
              <div
                className={s.activity_module__tbody_row}
                key={item.id || index}
              >
                <div
                  className={s.activity_module__tbody_col}
                  style={
                    [
                      'Principal repayment',
                      'Interest repayment',
                      'Delayed interest repayment',
                      'Campaign bonuses',
                      'Rewards',
                    ].includes(item.label)
                      ? {
                          fontStyle: 'italic',
                          paddingLeft: '30px',
                        }
                      : {}
                  }
                >
                  {item.label}
                </div>
                <div className={s.activity_module__tbody_col}>{item.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
