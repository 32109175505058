import React from 'react';
import classnames from 'classnames';
import WarningIcon from '../../assets/icons/icon_warning_20.svg';
import s from './index.module.scss';

export const InformationBlock = ({ text, children, type, id, className }) => (
  <div className={classnames(s.warning_block, className)} id={id}>
    {type && (
      <div className={classnames(s.warning_block__icon, 'icon')}>
        {type === 'warning' && <img src={WarningIcon} alt="icon" />}
      </div>
    )}
    <div className={s.warning_block__content}>
      {(text && <span className="'f-16-24'">{text}</span>) || children}
    </div>
  </div>
);
