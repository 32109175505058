import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage, getQuery } from '../../helpers/utils';
import {
  GET_BLOG_ARTICLE_DETAIL,
  GET_BLOG_ARTICLES,
  SET_BLOG_BANNERS,
} from '../types/blogTypes';

const setBlogBanners = payload => ({ type: SET_BLOG_BANNERS, payload });

const getBlogArticlesRequest = () => ({ type: GET_BLOG_ARTICLES.REQUEST });
const getBlogArticlesSuccess = payload => ({
  type: GET_BLOG_ARTICLES.SUCCESS,
  payload,
});
const getBlogArticlesFailure = error => ({
  type: GET_BLOG_ARTICLES.FAILURE,
  error,
});

const getBlogArticleDetailRequest = () => ({
  type: GET_BLOG_ARTICLE_DETAIL.REQUEST,
});
const getBlogArticleDetailSuccess = payload => ({
  type: GET_BLOG_ARTICLE_DETAIL.SUCCESS,
  payload,
});
const getBlogArticleDetailFailure = error => ({
  type: GET_BLOG_ARTICLE_DETAIL.FAILURE,
  error,
});

export const getBlogBanners = () => {
  return dispatch => {
    return axios
      .get(Pathes.Blog.articles + getQuery({ page: 1, limit: 3 }))
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          dispatch(setBlogBanners(data?.list || []));
          return { data, success: true };
        }
        throw new Error('Fetch error');
      })
      .catch(() => dispatch(setBlogBanners([])));
  };
};

export const getBlogArticles = params => {
  return dispatch => {
    dispatch(getBlogArticlesRequest());
    return axios
      .get(Pathes.Blog.articles + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getBlogArticlesSuccess(data));
          return { data, success: true, message };
        }

        throw new Error(message);
      })
      .catch(e => dispatch(getBlogArticlesFailure(e.message)));
  };
};

export const getBlogArticleDetail = slug => {
  return dispatch => {
    dispatch(getBlogArticleDetailRequest());
    return axios
      .get(Pathes.Blog.article(slug))
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          dispatch(getBlogArticleDetailSuccess(data));
          return { data, success: true };
        }
        throw new Error('Fetch error');
      })
      .catch(e => dispatch(getBlogArticleDetailFailure(e.message)));
  };
};
