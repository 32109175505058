import React from 'react';
import { Tooltip } from 'react-tippy';
import { useCalculateEarnings } from '../../../hooks/useCalculateEarnings';
import { BonusFirstInvestHint } from '../../../components/BonusFirstInvestHint';
import { InfoIcon } from '../../../components/UI/Icons';
import { MyLink } from '../../../components/MyLink';
import { prettyFloatMoney } from '../../../helpers/utils';

export const PlannedEarnings = () => {
  const { plannedEarning, apr, increasedIncome, earnings } =
    useCalculateEarnings();

  return (
    <div className="investing_summary__earnings">
      <div className="investing_summary__earnings_item">
        <div className="f-16">
          Planned earnings{' '}
          <Tooltip
            theme="light"
            trigger="click"
            arrow
            interactive
            html={
              <div className="f-16 f-400" style={{ maxWidth: '350px' }}>
                Return for group A is calculated on the basis of fulfilment of
                the condition of receiving 1% monthly income. If the conditions
                are not met in a particular month, the return will be
                recalculated at a base rate of 0.75% per month.
                <div style={{ marginTop: '10px' }} />
                Return for group B is calculated as 9% for a 6 month period.
                <div style={{ marginTop: '10px' }} />
                More details in{' '}
                <MyLink isExternal href="/help/loan-terms" underline>
                  Loan Terms
                </MyLink>
                {/* Please note that the basic return will be calculated at 0.75%, */}
                {/* which amounts to {prettyFloatMoney(earnings)} You may also be */}
                {/* eligible to receive an additional{' '} */}
                {/* {prettyFloatMoney(increasedIncome)} provided you meet the */}
                {/* conditions for a 1% return. See{' '} */}
                {/* <MyLink isExternal href="/help/loan-terms" underline> */}
                {/*   Loan Terms */}
                {/* </MyLink>{' '} */}
                {/* for more details. */}
              </div>
            }
          >
            <InfoIcon
              style={{ position: 'relative', top: '4px', cursor: 'pointer' }}
              onClick={e => e.stopPropagation()}
            />
          </Tooltip>
        </div>
        <div className="f-42 f-500 tl">
          {prettyFloatMoney(plannedEarning, { roundUpper: true })}
        </div>
      </div>
      <div className="investing_summary__earnings_item">
        <BonusFirstInvestHint className="investing_summary__earnings_bonus" />
      </div>
      <div className="investing_summary__earnings_item">
        <div className="f-16">
          Planned APR{' '}
          <Tooltip
            theme="light"
            trigger="click"
            arrow
            interactive
            html={
              <div className="f-16 f-400" style={{ maxWidth: '350px' }}>
                Please note that the APR shown is based on planned calculations.
                Actual returns may vary depending on investor decisions and the
                timing of brand repayments.
              </div>
            }
          >
            <InfoIcon
              style={{ position: 'relative', top: '4px', cursor: 'pointer' }}
              onClick={e => e.stopPropagation()}
            />
          </Tooltip>
        </div>
        <div className="f-42 f-500 tl">
          <span>{apr}</span>%
        </div>
      </div>
    </div>
  );
};
