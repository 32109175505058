import { APP_LINKS } from '../../../../helpers/links';

export const TERMS = [
  {
    id: 'conservative',
    title: 'Group A',
    seniority: 'Senior',
    investor_annual_rate: '12%',
    annual_base_return: 6.0,
    principal_payment_start_month: 7,
    info_term: '6 months',
    success_investor_fee: 4.0,
    is_active: true,
    downside_protection: 'Triple-secured',
    flat_fee: '5%',
    extra_flat_fee: '+ up to 4.4%',
    details_link: APP_LINKS.helpLoanTermsGroup('v2-group-a'),
  },
  {
    id: 'moderate',
    title: 'Group B',
    seniority: 'Junior',
    investor_annual_rate: 'Up to 30%',
    annual_base_return: 6.0,
    principal_payment_start_month: 12,
    info_term: '6 months',
    success_investor_fee: 17.33,
    is_active: true,
    downside_protection: 'Single-secured',
    flat_fee: '9%',
    extra_flat_fee: '+ up to 49%',
    details_link: APP_LINKS.helpLoanTermsGroup('v2-group-b'),
  },
  {
    id: 'growth',
    title: 'Group C',
    seniority: '-',
    investor_annual_rate: '15.000',
    annual_base_return: 6.0,
    principal_payment_start_month: 7,
    info_term: null,
    success_investor_fee: 26.0,
    is_active: false,
    downside_protection: '-',
    flat_fee: null,
    extra_flat_fee: null,
    details_link: APP_LINKS.helpLoanTermsGroup('v2-group-c'),
  },
];
