import React from 'react';
import classnames from 'classnames';
import { prettyDate } from '../../../../helpers/utils';
import { AverageTime } from '../../../../components/AverageTime';
import { RoundLink } from '../../../../components/UI/RoundLink';
import { APP_LINKS } from '../../../../helpers/links';
import s from './index.module.scss';

export const ArticleMainCard = ({ article }) => {
  return (
    <div className={s.wrapper} data-post-id={article.id}>
      <div className={s.image}>
        <img
          src={article.main_image.replace('test-investor', 'investor')}
          alt={article.title}
          loading="lazy"
          width={490}
          height={340}
        />
      </div>
      <div className={s.content}>
        <h2 className="h2 f-500">{article.title}</h2>
        <p className={classnames('text_subtitle_2', s.description)}>
          {article.description}
        </p>
        <div className={classnames('single_2xs', 'f-500', s.info)}>
          <div style={{ color: '#666666' }}>
            {prettyDate(article.created_at)}
          </div>
          <AverageTime text={article.reading_time} />
        </div>

        <div className={s.buttons}>
          <RoundLink
            label={
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  justifyContent: 'center',
                }}
              >
                Read article
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M2 9.91224L17.126 9.91224M17.126 9.91224L11.4537 15.5845M17.126 9.91224L11.4537 4.24"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            }
            path={APP_LINKS.blogDetail(article.slug)}
            className="btn_black_border"
            variant="black"
          />
        </div>
      </div>
    </div>
  );
};
