import React from 'react';
import classnames from 'classnames';
import { TERM_TOOLTIPS } from './tooltips';
import { LOAN_GROUPS } from '../../helpers/constants';
import s from './mobile.module.scss';

export const MobileTermView = () => {
  const GROUPS = [LOAN_GROUPS.conservative, LOAN_GROUPS.moderate];

  return (
    <div className={s.wrapper}>
      <div className={s.controls}>
        {GROUPS.map((group, index) => (
          <h3 className={classnames(s.name, 'f-24 f-500')} key={index}>
            {group.full_name}
          </h3>
        ))}
      </div>

      <div className={s.info}>
        <div className={s.row}>
          <div className={s.header}>
            <div className={s.label}>Investor earnings</div>
            {TERM_TOOLTIPS.earnings({ className: s.m_earning })}
          </div>

          <div className={s.line}>
            <span>{GROUPS[0].investor_earnings}</span>
            <span>{GROUPS[1].investor_earnings}</span>
          </div>
        </div>

        <div className={s.row}>
          <div className={s.header}>
            <div className={s.label}>Net annual return</div>
            {TERM_TOOLTIPS.net_annual_return({ className: s.m_return })}
          </div>
          <div className={s.line}>
            <span>{GROUPS[0].target_annual_return}</span>
            <span>{GROUPS[1].target_annual_return}</span>
          </div>
        </div>

        <div className={s.row}>
          <div className={s.header}>
            <div className={s.label}>Term</div>
            {TERM_TOOLTIPS.term({ className: s.m_term })}
          </div>
          <div className={s.line}>
            <span>6 months</span>
            <span>6 months</span>
          </div>
        </div>

        <div className={s.row}>
          <div className={s.header}>
            <div className={s.label}>Repayments</div>
            {TERM_TOOLTIPS.repayments({ className: s.m_repayments })}
          </div>
          <div className={s.line}>
            <span>{GROUPS[0].repayment_type}</span>
            <span>{GROUPS[1].repayment_type}</span>
          </div>
        </div>

        <div className={s.row}>
          <div className={s.header}>
            <div className={s.label}>Skin in the game</div>
            {TERM_TOOLTIPS.skin({ className: s.m_skin })}
          </div>
          <div className={s.line}>
            <span>{GROUPS[0].skin_in_the_game}</span>
            <span>{GROUPS[1].skin_in_the_game}</span>
          </div>
        </div>

        <div className={s.row}>
          <div className={s.header}>
            <div className={s.label}>Protection</div>
          </div>
          <div className={s.line}>
            <span>{GROUPS[0].protection}</span>
            <span>{GROUPS[1].protection}</span>
          </div>
        </div>
      </div>

      {/* <div className={s.buttons}> */}
      {/*   <RoundButton */}
      {/*     label={() => <span>Calculate my earnings</span>} */}
      {/*     className={s.button} */}
      {/*     onClick={() => { */}
      {/*       Analytics.investingCalculateLoanMobile(); */}
      {/*       onCalculate(); */}
      {/*     }} */}
      {/*     fillBackground */}
      {/*   /> */}
      {/* </div> */}
    </div>
  );
};
