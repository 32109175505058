import Cookies from 'js-cookie';
import config from '../config';

const COOKIE_FIRST_VISIT_NAME = 'first_visit_utm_data';
const COOKIE_REGISTRATION_VISIT_NAME = 'registration_visit_utm_data';
const COOKIE_PARENT_DOMAIN =
  config.release === 'development' ? 'localhost' : 'scrambleup.com';
const COOKIE_EXPIRE_DAYS = 10000000;
const COOKIE_SETTINGS = {
  expires: COOKIE_EXPIRE_DAYS,
  domain: COOKIE_PARENT_DOMAIN,
};

export const saveUTMtoCookie = params => {
  try {
    const current = {
      utm_medium:
        typeof params.utm_medium === 'string' ? params.utm_medium : null,
      utm_source:
        typeof params.utm_source === 'string' ? params.utm_source : null,
      utm_campaign:
        typeof params.utm_campaign === 'string' ? params.utm_campaign : null,
      click_id: typeof params.click_id === 'string' ? params.click_id : null,
    };

    const payload = JSON.stringify({
      ...current,
      created_date: new Date().toISOString(),
    });

    const data = Cookies.get(COOKIE_FIRST_VISIT_NAME, {
      domain: COOKIE_PARENT_DOMAIN,
    });
    const prevData = data && JSON.parse(data);

    if (!prevData) {
      // Cookie first time save
      Cookies.remove(COOKIE_FIRST_VISIT_NAME, { domain: COOKIE_PARENT_DOMAIN });
      Cookies.remove(COOKIE_REGISTRATION_VISIT_NAME, {
        domain: COOKIE_PARENT_DOMAIN,
      });
      Cookies.set(COOKIE_FIRST_VISIT_NAME, payload, COOKIE_SETTINGS);
      Cookies.set(COOKIE_REGISTRATION_VISIT_NAME, payload, COOKIE_SETTINGS);
    } else {
      // Update registration visit cookie
      Cookies.remove(COOKIE_REGISTRATION_VISIT_NAME, {
        domain: COOKIE_PARENT_DOMAIN,
      });
      Cookies.set(COOKIE_REGISTRATION_VISIT_NAME, payload, COOKIE_SETTINGS);
    }
  } catch (e) {
    console.error(e.message);
  }
};

export const getFirstVisitUTM = () => {
  try {
    const data = Cookies.get(COOKIE_FIRST_VISIT_NAME, {
      domain: COOKIE_PARENT_DOMAIN,
    });
    return data ? JSON.parse(data) : null;
  } catch (e) {
    console.error('getFirstVisitUTM', e.message);
    return null;
  }
};

export const getRegistrationVisitUTM = () => {
  try {
    const data = Cookies.get(COOKIE_REGISTRATION_VISIT_NAME, {
      domain: COOKIE_PARENT_DOMAIN,
    });
    return data ? JSON.parse(data) : null;
  } catch (e) {
    console.error('getRegistrationVisitUTM', e.message);
    return null;
  }
};

export const collectDetailsUTM = () => ({
  first_visit_utm_data: getFirstVisitUTM(),
  registration_visit_utm_data: getRegistrationVisitUTM(),
});
