import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { getTermsOfUseStatus } from '../../../store/services/userServices';
import { InformationBlock } from '../../../kit/InformationBlock';
import { RoundButton } from '../../UI/RoundButton';
import { confirmTermOfUse } from '../../../modals/TermsOfUseModal/action';
import { getUserProfile } from '../../../store/actions/profileActions';
import { prettyFloatMoney } from '../../../helpers/utils';
import s from './index.module.scss';

export const TermsAcceptWarning = ({ className }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState(0);

  React.useEffect(() => {
    getTermsOfUseStatus().then(res => {
      if (
        res.success &&
        res.data.has_active_loans &&
        res.data.amount > 0 &&
        res.data.status === 'pending'
      ) {
        setAmount(res.data.amount);
        setShow(true);
      }
    });
  }, []);

  const onAccept = async has_accepted_terms => {
    const res = await confirmTermOfUse({ has_accepted_terms });
    if (res?.success) {
      setShow(false);
      dispatch(getUserProfile());
    }
  };

  return show ? (
    <InformationBlock
      type="warning"
      className={classnames(s.wrapper, className)}
      id="tou_warning"
    >
      <div className={s.content}>
        <h1 className="f-24 f-500">Increase your investment guarantees</h1>
        <div className={classnames(s.disclaimer, 'f-16')}>
          Dear Investor,
          <br />
          By authorizing us to assign your current loans to other investors, you
          increase the probability of timely repayment within the first 6
          months. This repayment is supported not only by the funds of the
          brands, but also by the funds of other investors who buy your loans.
          <br />
          <br />
          You can currently assign{' '}
          <span className="f-500">{prettyFloatMoney(amount)}</span> from your
          active loans.
        </div>
        <div className={s.buttons}>
          <RoundButton
            label="I agree"
            fillBackground
            onClick={() => onAccept(true)}
          />
          <div className={s.decline} onClick={() => onAccept(false)}>
            I disagree
          </div>
        </div>
      </div>
    </InformationBlock>
  ) : null;
};
