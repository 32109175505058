import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { getPaymentInfoByPaymentRef } from '../../store/services/lhvServices';
import MiniFaqs, { MINIFAQ_SOURCES } from '../../containers/MiniFaqs';
import Preloader from '../../components/Preloader';
import { scrollTop } from '../../helpers/utils';
import { PaymentSuccess } from './success';
import { PaymentFailure } from './error';
import s from './index.module.scss';

const PAYMENT_STATUSES = {
  settled: 'settled',
  failed: 'failed',
  waiting_for_3ds_response: 'waiting_for_3ds_response',
};

const FundsAddResultPage = () => {
  const location = useLocation();
  const [payment, setPayment] = useState(null);
  const { payment_reference } = qs.parse(location.search.replace('?', ''));

  useEffect(() => {
    scrollTop();
    init();
  }, []);

  const init = async () => {
    const res = await getPaymentInfoByPaymentRef(payment_reference);
    res?.success && setPayment(res.data);
  };

  if (!payment) {
    return (
      <div className={s.wrapper}>
        <Preloader className={s.preloader} />
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      {payment.payment_state === PAYMENT_STATUSES.settled && (
        <PaymentSuccess amount={payment.initial_amount} />
      )}

      {payment.payment_state === PAYMENT_STATUSES.failed && (
        <PaymentFailure amount={payment.initial_amount} />
      )}

      <MiniFaqs source={MINIFAQ_SOURCES.addCash} />
    </div>
  );
};

export default FundsAddResultPage;
