import React, { useRef } from 'react';
import classnames from 'classnames';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { calculateCash, prettyMoneyHybrid } from '../../../../helpers/utils';
import { SpecTriangleIcon } from '../../../../components/UI/Icons';
import CashDetailBox from '../../../../components/CashDetailBox';
import './index.scss';

export const BalanceInfo = ({ balance }) => {
  const ref = useRef();
  const [show, toggle] = React.useState(false);
  useOutsideClick(ref, () => toggle(false), show);

  const cash = calculateCash(balance);

  return (
    <div className="balance_info">
      <p className="f-16">Your total balance</p>
      <p className="balance_info__balance f-500 f-36 tl">
        {prettyMoneyHybrid(balance?.total)}
      </p>
      <p className="balance_info__label f-16">Invested in loans</p>
      <p className="balance_info__white f-500 f-26 tl">
        {prettyMoneyHybrid(balance?.invested)}
      </p>
      <p className="balance_info__label f-16">Cash</p>
      <div
        className="balance_info__cash"
        ref={ref}
        onClick={() => toggle(!show)}
      >
        <div className="balance_info__cash_inner">
          <p
            className={classnames(
              'balance_info__white',
              'balance_info__cash_number',
              show && 'active',
              'f-500 f-26 tl'
            )}
            style={{ cursor: 'pointer' }}
          >
            {prettyMoneyHybrid(cash)}
          </p>
          {show && (
            <div
              className="balance_info__popup"
              onClick={e => e.stopPropagation()}
            >
              <SpecTriangleIcon className="balance_info__popup_triangle" />
              <CashDetailBox balance={balance} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
