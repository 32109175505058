import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import GroupedAvatars from '../../UI/GroupedAvatars';
import {
  getFlagURL,
  getNameInitials,
  prettyMoney,
} from '../../../helpers/utils';
import { setModals } from '../../../store/actions/commonActions';
import { MODAL_TYPES } from '../../ModalProvider';
import { ReactComponent as IconArrow } from './assets/icon_arrow_right_black_14.svg';
import s from './index.module.scss';

export const BrandCard = ({ brand, goalHidden, className }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      setModals({ type: MODAL_TYPES.company_info, companyID: brand.id })
    );
  };

  return (
    <div className={classnames(s.wrapper, className)} onClick={onClick}>
      <div className={s.card}>
        <div className={s.badges}>
          {brand.statuses?.map(status => (
            <div
              key={status}
              className={classnames(
                s.badges_status,
                s[`badges_status_${status}`]
              )}
            >
              {status}
            </div>
          ))}
          <div className={s.badges_flag}>
            <img
              className={s.badges_flag_container}
              src={getFlagURL(brand.country_code) || ''}
              alt={brand.country_code}
              loading="lazy"
              width={12}
              height={8}
            />
          </div>
        </div>

        <div
          className={classnames(
            s.brand_image,
            brand.statuses?.includes('reviewing') && s.brand_image_fade
          )}
        >
          <img
            src={brand.promo_image?.file}
            alt={brand.name}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 415px"
            loading="lazy"
          />
        </div>

        <GroupedAvatars
          data={
            brand?.founders.map(f => ({
              id: f.id,
              src: f.avatar?.file || '',
              alt: f.avatar?.name || '',
              nameInitials: getNameInitials(f.full_name),
            })) || []
          }
          size={44}
          className={s.brand_founders}
        />

        <div className={s.card_bottom}>
          <div className={s.card_bottom_inner}>
            <div className={s.card_info}>
              <div className={s.card_info__left}>
                <div className={classnames(s.brand_title, 'f-12 f-500 tl')}>
                  {brand.name}
                </div>
                <div className={classnames(s.brand_subtitle, 'f-10', 'tl')}>
                  {brand.promo_theme}
                </div>
              </div>
              <div className={s.card_info__right}>
                {!goalHidden && (
                  <div className={s.brand_goal}>
                    <div>Financial goal</div>
                    <div className="f-12 f-500">
                      {prettyMoney(brand.active_limit)}
                    </div>
                  </div>
                )}

                <div className={s.brand_started}>
                  <div>Started</div>
                  <div className="f-12 f-500">
                    {moment(brand.foundation_date).format('YYYY')}
                  </div>
                </div>
              </div>
            </div>

            <div className={classnames(s.brand_description, 'f-10 f-400')}>
              {brand.description}
            </div>

            <div className={s.brand_learn_more}>
              Learn more <IconArrow />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
