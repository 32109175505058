import React from 'react';
import classnames from 'classnames';
import ImageComplete from './icon_complete.svg';
import ImagePartComplete from './icon_part_complete.svg';
import ImageExtend from './icon_extended.svg';
import ImageUnderpaid from './icon_underpaid.svg';
import ImageWaiting from './icon_waiting.svg';
import s from './index.module.scss';

export const IconCompleted = ({ className }) => (
  <div className={classnames(s.icon, className)}>
    <img src={ImageComplete} alt="icon" width={40} height={40} />
  </div>
);

export const IconPartCompleted = ({ className }) => (
  <div className={classnames(s.icon, className)}>
    <img src={ImagePartComplete} alt="icon" width={40} height={40} />
  </div>
);

export const IconExtended = ({ className }) => (
  <div className={classnames(s.icon, className)}>
    <img src={ImageExtend} alt="icon" width={40} height={40} />
  </div>
);

export const IconUnderpaid = ({ className }) => (
  <div className={classnames(s.icon, className)}>
    <img src={ImageUnderpaid} alt="icon" width={40} height={40} />
  </div>
);

export const IconWaiting = ({ className }) => (
  <div className={classnames(s.icon, className)}>
    <img src={ImageWaiting} alt="icon" width={40} height={40} />
  </div>
);
