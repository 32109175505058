import React, { useEffect } from 'react';
import SignIn from '../../containers/SignIn';
import BasicLayout from '../../layouts/BasicLayout';
import Analytics from '../../analyticsGA';

const SignInPage = () => {
  useEffect(() => {
    Analytics.signInPage();
  }, []);

  return (
    <BasicLayout>
      <div className="container">
        <SignIn />
      </div>
    </BasicLayout>
  );
};

export default SignInPage;
