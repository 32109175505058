import React from 'react';
import { useDispatch } from 'react-redux';
import { RoundLink } from '../../../../components/UI/RoundLink';
import { APP_LINKS } from '../../../../helpers/links';
import { setModals } from '../../../../store/actions/commonActions';
import { MODAL_TYPES } from '../../../../components/ModalProvider';
import s from './index.module.scss';

export const NoAvailableTopUpMethods = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.sepa_info_modal,
      })
    );
  };

  return (
    <div className={s.wrapper}>
      <span className="f-16-24">
        Unfortunately there are no available top-up methods for your residence
        country. To open available methods you can add a bank account of a bank
        within the{' '}
        <button type="button" onClick={onClick} className="underline">
          SEPA area
        </button>
        .
      </span>

      <div className={s.buttons}>
        <RoundLink
          path={APP_LINKS.bankAccountsCreate}
          label="Add a bank account"
          fillBackground
        />
      </div>
    </div>
  );
};
