import React from 'react';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import classnames from 'classnames';
import { PageArrow } from './arrow';
import s from './index.module.scss';

export const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  className,
}) => {
  const { innerWidth } = useSelector(state => state.commonStore);

  return (
    <div className={classnames(s.wrapper, className)}>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<PageArrow />}
        previousLabel={<PageArrow direction="left" />}
        pageClassName={s.page}
        containerClassName={s.container}
        activeClassName={s.active}
        disabledClassName={s.disabled}
        onPageChange={onPageChange}
        pageCount={totalPages}
        forcePage={currentPage}
        renderOnZeroPageCount={null}
        pageRangeDisplayed={innerWidth > 1024 ? 5 : 1}
        marginPagesDisplayed={innerWidth > 1024 ? 5 : 3}
      />
    </div>
  );
};
