import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { NavLink } from 'react-router-dom';
import { APP_LINKS } from '../../helpers/links';
import { getInvestingStatus } from '../../store/actions/investActions';
import {
  getMissingProfileDetails,
  getUserProfile,
} from '../../store/actions/profileActions';
import s from './index.module.scss';

const TABS = [
  {
    label: 'Activity',
    path: APP_LINKS.activity,
  },
  {
    label: 'Tax Report',
    path: APP_LINKS.taxReports,
  },
];

const ReportsPage = ({ route, user }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMissingProfileDetails());
    dispatch(getUserProfile());
    dispatch(getInvestingStatus());
  }, []);

  return (
    <div className={s.reports_page}>
      <div className="container-inner">
        <h1 className={classnames(s.reports_page__title, 'f-42 f-500')}>
          Reports
        </h1>
        <div className={s.reports_page__links}>
          {TABS.map(tab => (
            <NavLink
              key={tab.path}
              to={tab.path}
              className={s.reports_page__link}
              activeClassName={s.reports_page__link_selected}
            >
              {tab.label}
            </NavLink>
          ))}
        </div>
        {renderRoutes(route.routes, { user })}
      </div>
    </div>
  );
};

export default ReportsPage;
