import { METADATA } from '../../helpers/metadata';
import {
  SET_FUNDRAISING_STATS,
  GET_INVESTING_LOAN_GROUPS,
  GET_INVESTING_RESULTS,
  GET_INVESTING_STATUS,
  SET_INVESTING_STATUS,
  GET_PARTICIPATING_STARTUPS,
  SET_FUNDRAISING_COUNTRIES_STATS,
  GET_INCREASED_INCOME_STATUS,
  GET_ALLOCATED_REPAYMENT_SCHEDULE,
} from '../types/investTypes';

export const initialState = {
  isParticipating: false,
  investingStatus: { ...METADATA.default, data: null },
  investingResult: { ...METADATA.default, data: null },
  investingLoanGroups: { ...METADATA.default, data: null },
  fundraisingStats: { currently_allocated: 0, total_goal: 0, min_goal: null },
  fundraisingCountriesStats: null,
  participatingStartups: { ...METADATA.default, data: null },
  increasedIncomeStatus: { ...METADATA.default, data: null },
  allocatedRepaymentSchedule: { ...METADATA.default, data: null },
};

const investReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVESTING_STATUS:
      return {
        ...state,
        investingStatus: { ...state.investingStatus, data: action.status },
        isParticipating: action.status?.is_participating,
      };
    case GET_INVESTING_STATUS.REQUEST:
      return {
        ...state,
        investingStatus: { ...state.investingStatus, ...METADATA.request },
      };
    case GET_INVESTING_STATUS.SUCCESS:
      return {
        ...state,
        investingStatus: { ...METADATA.success, data: action.payload },
        isParticipating: action.payload.is_participating,
      };
    case GET_INVESTING_STATUS.FAILURE:
      return {
        ...state,
        investingStatus: {
          ...state.investingStatus,
          ...METADATA.error,
          error: action.error,
        },
        isParticipating: false,
      };
    case GET_INVESTING_RESULTS.REQUEST:
      return {
        ...state,
        investingResult: { ...state.investingResult, ...METADATA.request },
      };
    case GET_INVESTING_RESULTS.SUCCESS:
      return {
        ...state,
        investingResult: { ...METADATA.success, data: action.payload },
      };
    case GET_INVESTING_RESULTS.FAILURE:
      return {
        ...state,
        investingResult: {
          ...state.investingResult,
          ...METADATA.error,
          error: action.error,
          data: null,
        },
      };
    case GET_INVESTING_LOAN_GROUPS.REQUEST:
      return {
        ...state,
        investingLoanGroups: {
          ...state.investingLoanGroups,
          ...METADATA.request,
        },
      };
    case GET_INVESTING_LOAN_GROUPS.SUCCESS:
      return {
        ...state,
        investingLoanGroups: { ...METADATA.success, data: action.payload },
      };
    case GET_INVESTING_LOAN_GROUPS.FAILURE:
      return {
        ...state,
        investingLoanGroups: {
          ...state.investingLoanGroups,
          ...METADATA.error,
          error: action.error,
        },
      };
    case SET_FUNDRAISING_STATS:
      return { ...state, fundraisingStats: action.payload };

    case SET_FUNDRAISING_COUNTRIES_STATS:
      return { ...state, fundraisingCountriesStats: action.payload };

    case GET_PARTICIPATING_STARTUPS.REQUEST:
      return {
        ...state,
        participatingStartups: {
          ...state.participatingStartups,
          ...METADATA.request,
        },
      };
    case GET_PARTICIPATING_STARTUPS.SUCCESS:
      return {
        ...state,
        participatingStartups: { ...METADATA.success, data: action.payload },
      };
    case GET_PARTICIPATING_STARTUPS.FAILURE:
      return {
        ...state,
        participatingStartups: {
          ...state.participatingStartups,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_INCREASED_INCOME_STATUS.REQUEST:
      return {
        ...state,
        increasedIncomeStatus: {
          ...state.increasedIncomeStatus,
          ...METADATA.request,
        },
      };
    case GET_INCREASED_INCOME_STATUS.SUCCESS:
      return {
        ...state,
        increasedIncomeStatus: { ...METADATA.success, data: action.payload },
      };
    case GET_INCREASED_INCOME_STATUS.FAILURE:
      return {
        ...state,
        increasedIncomeStatus: {
          ...METADATA.error,
          error: action.error,
          data: null,
        },
      };

    case GET_ALLOCATED_REPAYMENT_SCHEDULE.REQUEST:
      return {
        ...state,
        allocatedRepaymentSchedule: {
          ...state.allocatedRepaymentSchedule,
          ...METADATA.request,
        },
      };
    case GET_ALLOCATED_REPAYMENT_SCHEDULE.SUCCESS:
      return {
        ...state,
        allocatedRepaymentSchedule: {
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_ALLOCATED_REPAYMENT_SCHEDULE.FAILURE:
      return {
        ...state,
        allocatedRepaymentSchedule: {
          ...METADATA.error,
          error: action.error,
          data: null,
        },
      };

    default:
      return state;
  }
};

export default investReducer;
