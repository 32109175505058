import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { RoundSummary } from './components/summary';
import { RoundDetail } from './components/detail';
import Preloader from '../../components/Preloader';
import {
  getAllRounds,
  getRoundsSummary,
} from '../../store/actions/roundHistoryActions';
import s from './index.module.scss';

const RoundHistory = () => {
  const dispatch = useDispatch();
  const { data: allRounds } = useSelector(
    state => state.roundHistoryStore.allRounds
  );
  const { loading } = useSelector(
    state => state.roundHistoryStore.pastRoundStats
  );
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    dispatch(getRoundsSummary());
    !allRounds && dispatch(getAllRounds());
  }, []);

  useEffect(() => {
    const rootEl = document.getElementById('main');
    const footerEl = document.querySelector('.auth-footer');

    if (rootEl) {
      rootEl.style.background = '#F3F2F4';
    }

    if (footerEl) {
      footerEl.style.marginTop = 0;
    }

    return () => {
      if (rootEl) {
        rootEl.style.background = 'unset';
      }

      if (footerEl) {
        footerEl.style.marginTop = '50px';
      }
    };
  }, []);

  useEffect(() => {
    if (!selected && allRounds) {
      setSelected(allRounds[allRounds.length - 1]);
    }
  }, [selected, allRounds]);

  return (
    <div className="container-inner">
      <div className={s.page}>
        <div className={s.header}>
          <h2 className="f-42 f-500">Rounds history summary</h2>
          <p className={classnames(s.header__right, 'f-16-24', 'f-400')}>
            Here you can see the results of our previous investment rounds,
            which we&apos;ve been actively organizing since June 2022. Through
            this approach, we enable high-potential consumer goods brands to
            quickly obtain funding for their growth and progress. By giving them
            access to fast and reliable capital, we ensure their development is
            unhindered. At the same time, our investors enjoy exceptional
            market-leading returns on short-term loans.
          </p>
        </div>
        <RoundSummary />
        {!loading && !selected ? (
          <Preloader className={s.preloader} />
        ) : (
          selected && (
            <RoundDetail selected={selected} setSelected={setSelected} />
          )
        )}
      </div>
    </div>
  );
};

export default RoundHistory;
