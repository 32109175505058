import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Decimal from 'decimal.js-light';
import WithdrawForm from '../../components/Forms/WithdrawForm';
import {
  createWithdrawRequest,
  getWithdrawInfo,
} from '../../store/actions/fundActions';
import Preloader from '../../components/Preloader';
import { getBalance } from '../../store/actions/dashboardActions';
import { RoundLink } from '../../components/UI/RoundLink';
import MiniFaqs, { MINIFAQ_SOURCES } from '../../containers/MiniFaqs';
import AnalyticsGA from '../../analyticsGA';
import { USER_STATUSES } from '../../helpers/constants';
import { isReadyForVerification } from '../../helpers/user';
import { NOTIFICATIONS } from '../../components/Notification/list';
import NotificationBlock from '../../containers/NotificationBlock';
import { APP_LINKS } from '../../helpers/links';
import WithdrawHistory from '../../containers/WithdrawHistory';
import { SuspendResidenceWarning } from '../../components/Warnings/SuspendResidenceWarning';
import { BonusBanner } from '../../containers/BonusProgram/banner';
import { setModals } from '../../store/actions/commonActions';
import { MODAL_TYPES } from '../../components/ModalProvider';
import './index.scss';

const FundsWithdrawPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [historyUpdater, setHistoryUpdated] = useState(
    new Date().toISOString()
  ); // Used to force update Withdrawals history
  const { data, loading } = useSelector(state => state.fundStore.withdrawInfo);
  const profile = useSelector(state => state.profileStore.profile.data);
  const notifications = [];

  useEffect(() => {
    dispatch(getWithdrawInfo());
    AnalyticsGA.pageNavigation('Withdraw', 'finance_operations');
  }, []);

  const onSubmit = (values, formikBag) => {
    const amount = new Decimal(values.amount).toNumber();

    if (!values.account.is_sepa && amount < 500) {
      return formikBag.setErrors({
        amount:
          'Please note that for withdrawals to banks located outside of the SEPA zone, a minimum withdrawal amount of 500 euros applies. Withdrawal requests to non-SEPA banks must be 500 euros or more.',
      });
    }

    dispatch(
      setModals({
        type: MODAL_TYPES.confirm_modal,
        confirmLabel: 'Withdraw',
        onConfirm: () => {
          onWithdraw(values, formikBag);
          dispatch(setModals([]));
        },
        onCancel: () => history.push(APP_LINKS.investing),
        title: 'Are you sure you want to withdraw your funds?',
        desc: "Keep in mind that once withdrawn, you won't be able to benefit from any potential further investment gains. If you're ready to withdraw, simply hit 'Withdraw'. If you'd like to continue investing, hit 'Cancel'.",
      })
    );
  };

  const onWithdraw = async (values, formikBag) => {
    AnalyticsGA.withdrawWithdrawSubmit();
    const amount = new Decimal(values.amount).toNumber();

    if (amount > values.cash) {
      formikBag.setErrors({ amount: 'Not enough money' });
    }

    const payload = {
      account: values.account.id,
      amount: new Decimal(values.amount).toNumber(),
    };

    const res = await dispatch(createWithdrawRequest(payload));
    if (res?.success) {
      dispatch(getBalance());
      setHistoryUpdated(new Date().toISOString()); // Force update Withdrawals History
      formikBag.resetForm();
      formikBag.setValues({ account: values.account, amount: '' });
    }
  };

  const hasExternalAcc =
    data && data.external_accounts && !!data.external_accounts.length;

  if (!profile || loading) {
    return (
      <div className="funds_withdraw_page">
        <Preloader
          className="funds_withdraw_page__preloader"
          style={{ marginTop: '20px', height: '40vh' }}
        />
      </div>
    );
  }

  if (profile?.status === USER_STATUSES.suspended) {
    return (
      <div className="funds_withdraw_page">
        <SuspendResidenceWarning />
      </div>
    );
  }

  if (profile.status === USER_STATUSES.new) {
    isReadyForVerification(profile)
      ? notifications.push(
          NOTIFICATIONS.checking_transactions_available_to_verified_users()
        )
      : notifications.push(
          NOTIFICATIONS.transactions_available_to_verified_users()
        );
  }

  return (
    <div className="funds_withdraw_page">
      <NotificationBlock
        className="funds_withdraw_page__notifications"
        notifications={notifications}
      />

      {profile.status === USER_STATUSES.verified && (
        <>
          <BonusBanner className="funds_withdraw_page__bonus" />

          {hasExternalAcc ? (
            <WithdrawForm
              balance={data?.cash}
              accounts={data?.external_accounts}
              onSubmit={onSubmit}
            >
              <WithdrawHistory
                resetTime={historyUpdater}
                className="funds_withdraw_page__history"
              />
            </WithdrawForm>
          ) : (
            <div className="funds_withdraw_page__empty">
              <div className="funds_withdraw_page__empty-title f-20 f-400">
                You have no available bank accounts.
              </div>
              <RoundLink
                label="Add a new bank account"
                path={APP_LINKS.bankAccountsCreate}
                onClick={() => AnalyticsGA.withdrawAddNewBankAccount()}
                className="funds_withdraw_page__empty-link"
              />
            </div>
          )}
        </>
      )}
      <MiniFaqs source={MINIFAQ_SOURCES.withdraw} />
    </div>
  );
};

export default FundsWithdrawPage;
