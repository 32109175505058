import React from 'react';
import { NavLink } from 'react-router-dom';
import { APP_LINKS } from '../../../helpers/links';
import s from './index.module.scss';

const TABS = [
  {
    label: 'Brands gallery',
    path: APP_LINKS.startups,
  },
  {
    label: 'Founders',
    path: APP_LINKS.founders,
  },
];

export const BrandTabs = () => (
  <div className={s.links}>
    {TABS.map(tab => (
      <NavLink
        key={tab.path}
        to={tab.path}
        className={s.link}
        activeClassName={s.link_selected}
      >
        {tab.label}
      </NavLink>
    ))}
  </div>
);
