import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { getParticipatingFounders } from '../../store/actions/founderActions';
import FounderCard from '../../components/Cards/FounderCard';

const FoundersActive = () => {
  const dispatch = useDispatch();
  const founders = useSelector(
    state => state.founderStore.participatingFounders
  );

  useEffect(() => {
    dispatch(getParticipatingFounders({ page: 1, limit: 40 }));
  }, []);

  const hasFounders = !!(founders && founders.list.length);

  if (!hasFounders) {
    return null;
  }

  return (
    <div className="founders-active">
      <h2 className="f-26 f-500">
        Active founders {hasFounders && `(${founders.total_count})`}
      </h2>
      <p className={classnames('founders-active__desc', 'f-16 f-400')}>
        The participants of the round representing the brands of the current
        batch.
      </p>
      {hasFounders ? (
        <div className="founders-active__list">
          {founders.list.map(founder => (
            <FounderCard key={founder.id} founder={founder} active hideFR />
          ))}
        </div>
      ) : (
        <div className="founders-active__empty">
          <span className="founders-active__empty-title f-16 f-400">
            No active founders yet.
          </span>
        </div>
      )}
    </div>
  );
};

export default FoundersActive;
