import React, { useEffect } from 'react';
import classnames from 'classnames';
import Analytics from '../../analyticsGA';
import { QuestionsList } from './list';
import { NewQuestion } from './new';
import s from './index.module.scss';

const ClientQuestionsModule = () => {
  useEffect(() => {
    Analytics.pageNavigation('Investors questions');
  }, []);

  return (
    <div className={s.wrapper}>
      <div className="container-inner">
        <h1 className="h1">Investor&apos;s questions</h1>
        <p className={classnames(s.description)}>
          With our diverse community, you&apos;ll have the chance to broaden
          your knowledge of the platform and interact with investors from
          different backgrounds and levels of experience. Feel free to ask your
          questions and join discussions with other users!
        </p>
        <QuestionsList />
        <NewQuestion />
      </div>
    </div>
  );
};

export default ClientQuestionsModule;
