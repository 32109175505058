import React, { useEffect } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { prettyFloatMoney } from '../../helpers/utils';
import { getIncreasedIncomeInfo } from '../../store/services/investingServices';
import s from './index.module.scss';

export const IncreasedIncomeWarning = ({ amount, currentDate, className }) => {
  const mounted = React.useRef(true);
  const [incomeAmount, setIncomeAmount] = React.useState(0);

  useEffect(() => {
    if (currentDate) {
      getIncreasedIncomeInfo().then(res => {
        if (res?.success && mounted.current) {
          const isActive = moment(currentDate).isSameOrAfter(
            res.data.start_date_for_income
          );
          isActive && setIncomeAmount(res.data.increased_income);
        }
      });
    }
    setIncomeAmount(amount || 0);
    return () => {
      mounted.current = false;
    };
  }, [currentDate]);

  if (currentDate && !incomeAmount) return null;

  return (
    <div className={classnames(s.wrapper, className)}>
      {incomeAmount ? (
        <>
          <div className="f-16-24 f-500">{`Want +${prettyFloatMoney(
            incomeAmount
          )} more?`}</div>
          <div className="f-16-24 f-400">
            Get increased income of 1% per month on the outstanding loan
            principal.
          </div>
          <div className="f-16-24 f-400">
            The increased income is accrued in the last month on a very simple
            condition: regular monthly replenishments and investments must be at
            least €100 per month during the full loan repayment period.
          </div>
        </>
      ) : (
        <>
          <div className="f-16-24 f-500">
            Get increased income of 1% per month on the outstanding loan
            principal.
          </div>
          <div className="f-16-24 f-400">
            The increased income is accrued in the last month on a very simple
            condition: regular monthly replenishments and investments must be at
            least €100 per month during the full loan repayment period.
          </div>
        </>
      )}
    </div>
  );
};
