import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import MiniFaqs, { MINIFAQ_SOURCES } from '../../containers/MiniFaqs';
import BankDetailsModule from '../../containers/BankDetailsModule';
import { USER_STATUSES } from '../../helpers/constants';
import { SuspendResidenceWarning } from '../../components/Warnings/SuspendResidenceWarning';
import AnalyticsGA from '../../analyticsGA';
import s from './index.module.scss';

const FundsBankDetailsPage = () => {
  const { user } = useSelector(state => state.userStore);

  useEffect(() => {
    AnalyticsGA.pageNavigation('Bank Accounts', 'finance_operations');
  }, []);

  if (user?.status === USER_STATUSES.suspended) {
    return (
      <div className={s.wrapper}>
        <SuspendResidenceWarning />
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      <BankDetailsModule />
      <MiniFaqs source={MINIFAQ_SOURCES.bankDetails} />
    </div>
  );
};

export default FundsBankDetailsPage;
