import React, { useMemo } from 'react';
import { CountrySelectField } from '../../../../kit/form/select/CountrySelect';
import { checkCountryForSepa } from '../../../../store/services/fundServices';

export const SepaZoneCountrySelect = ({ onChange, ...props }) => {
  const checkForSepaZone = async country => {
    const res = await checkCountryForSepa(country.code);
    if (res?.success) {
      onChange({
        country,
        in_sepa_zone: res.data.in_sepa_zone,
      });
    }
  };

  const excludedCountries = useMemo(
    () => [
      'AM',
      'BY',
      'CF',
      'CD',
      'CG',
      'IR',
      'KZ',
      'KG',
      'MD',
      'RS',
      'SO',
      'SD',
      'SS',
      'AC',
      'UZ',
    ],
    []
  );

  return (
    <CountrySelectField
      exclude={excludedCountries}
      onChange={checkForSepaZone}
      {...props}
    />
  );
};
