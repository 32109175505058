import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import IconRounds from '../icons/icon_rounds.svg';
import IconBrands from '../icons/icon_brands.svg';
import IconCash from '../icons/icon_cash.svg';
import IconCountries from '../icons/icon_countries.svg';
import IconCards from '../icons/icon_credit_cards.svg';
import IconFunds from '../icons/icon_funds.svg';
import IconInvesting from '../icons/icon_investing.svg';
import IconUserGroup from '../icons/icon_user_groups.svg';
import { prettyFloatMoney } from '../../../../helpers/utils';
import { EMPTY_DASH } from '../../../../helpers/constants';
import Preloader from '../../../../components/Preloader';
import s from './index.module.scss';

const HISTORY = {
  count: {
    label: 'Rounds',
    icon: IconRounds,
    value: ({ count }) => count || EMPTY_DASH,
  },
  total_funded: {
    label: 'Total funded',
    icon: IconFunds,
    value: ({ total_funded }) =>
      total_funded ? prettyFloatMoney(total_funded) : EMPTY_DASH,
  },
  startups_count: {
    label: 'Brands',
    icon: IconBrands,
    value: ({ startups_count }) => startups_count || EMPTY_DASH,
  },
  repaid_back: {
    label: 'Repaid back',
    icon: IconCash,
    value: ({ repaid_back, repaid_back_percentage }) =>
      repaid_back
        ? `${prettyFloatMoney(repaid_back)} (${repaid_back_percentage}%)`
        : '—',
  },
  investors_earnings: {
    label: 'Investors earnings',
    icon: IconInvesting,
    value: ({ investors_earnings }) =>
      investors_earnings ? prettyFloatMoney(investors_earnings) : '—',
  },
  repayments_on_time_percentage: {
    label: 'Repayments on time Group A/B',
    icon: IconCards,
    value: ({
      repayments_group_a_on_time_percentage,
      repayments_group_b_on_time_percentage,
    }) => {
      let groupA = EMPTY_DASH;
      let groupB = EMPTY_DASH;
      if (typeof repayments_group_a_on_time_percentage === 'number') {
        groupA = `${repayments_group_a_on_time_percentage}%`;
      }
      if (typeof repayments_group_b_on_time_percentage === 'number') {
        groupB = `${repayments_group_b_on_time_percentage}%`;
      }
      return `${groupA} / ${groupB}`;
    },
  },
  countries_count: {
    label: 'Countries',
    icon: IconCountries,
    value: ({ countries }) => countries || EMPTY_DASH,
  },
  total_investors: {
    label: 'Total investors',
    icon: IconUserGroup,
    value: ({ investors_count }) => investors_count || EMPTY_DASH,
  },
};

export const RoundSummary = () => {
  const { data: pastRoundStats, loading } = useSelector(
    state => state.roundHistoryStore.pastRoundStats
  );

  return loading ? (
    <Preloader className={s.preloader} />
  ) : pastRoundStats ? (
    <div className={s.cards}>
      {Object.keys(HISTORY).map(key => (
        <div className={s.card} key={key}>
          <div className={s.card__left}>
            <img src={HISTORY[key].icon} alt="icon" width={36} height={36} />
          </div>
          <div className={s.card__right}>
            <div className={classnames('f-16-24', s.card__label)}>
              {HISTORY[key].label}
            </div>
            <div className={classnames('f-14', 'f-500', s.card__value)}>
              {HISTORY[key].value(pastRoundStats || {})}
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : null;
};
