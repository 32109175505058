import React from 'react';
import classnames from 'classnames';
import s from './index.module.scss';

export const SlideCounter = ({
  current = 0,
  total = 0,
  delimiter = '—',
  className,
}) => (
  <div className={classnames(s.counter, className)}>
    <span>{current < 10 ? `0${current}` : current}</span>
    <span>{delimiter}</span>
    <span>{total < 10 ? `0${total}` : total}</span>
  </div>
);
