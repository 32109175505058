import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import classnames from 'classnames';
import * as Yup from 'yup';
import NotificationBlock from '../../NotificationBlock';
import { STRATEGIES } from '../InvSelectStrategy';
import { ParticipatingStartupsList } from '../Batches/Startup/participate';
import PriorityFoundersGroupedList from '../InvAllocateFunds/priority';
import withScroll from '../../../hoc/withScroll';
import Dropdown from '../../../components/Dropdown';
import AnalyticsGA from '../../../analyticsGA';
import { NOTIFICATIONS } from '../../../components/Notification/list';
import InvestingSummary from '../../InvestingSummary';
import Checkbox from '../../../components/UI/Checkbox';
import { MyLink } from '../../../components/MyLink';
import { APP_LINKS } from '../../../helpers/links';
import { isExpiredResetDate } from '../../../helpers/date';
import { VERIFF_CODES } from '../../../helpers/constants';
import { RoundButton } from '../../../components/UI/RoundButton';
import { BonusFirstInvestHint } from '../../../components/BonusFirstInvestHint';
import { confirmInvestment } from '../../../store/services/investingServices';
import {
  getIncreasedIncomeStatus,
  getInvestingStatus,
} from '../../../store/actions/investActions';
import { getPreAllocateInfo } from '../../../store/actions/fundActions';
import WarningBlock from '../../../components/UI/WarningBlock';
import { getBalance } from '../../../store/actions/dashboardActions';
import {
  getUserProfile,
  getVeriffStatus,
} from '../../../store/actions/profileActions';
import {
  isPassedVeriff,
  isSubmittedVeriff,
  isVerifiedByAdmin,
  isVerifiedPhoneNumber,
} from '../../../helpers/user';
import { ResetInvesting } from '../components/ResetInvesting';
import { useGetAllocationStatus } from '../../../hooks/useGetAllocationStatus';
import { setModals } from '../../../store/actions/commonActions';
import { MODAL_TYPES } from '../../../components/ModalProvider';
import { useCheckPlannedPayments } from '../../../components/Notification/hooks/useCheckPlannedPayments';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object().shape({
  has_accepted_terms: Yup.bool()
    .oneOf([true], 'I have to accept Group terms')
    .required('This field is required'),
});

const InvReview = ({ status, setStep, onReset, setProcessing }) => {
  const intervalID = useRef(null);
  const dispatch = useDispatch();
  const { veriffStatusCode } = useSelector(state => state.profileStore);
  const { data: profile } = useSelector(state => state.profileStore.profile);
  const { referralCode } = useSelector(state => state.referralStore);
  const { fundraisingDates } = useSelector(state => state.dashboardStore);
  const isActiveReferralBanner = !!referralCode.data?.is_referral_banner_active;
  const { hasPlannedPayment } = useCheckPlannedPayments();

  const { allocatedGroupA, allocatedGroupB } = useGetAllocationStatus();
  const notifications = [];

  notifications.push(NOTIFICATIONS.round_results(status.end_date));

  isExpiredResetDate(status.editable_until) &&
    notifications.push(NOTIFICATIONS.now_you_can_add_more_funds());

  hasPlannedPayment && notifications.push(NOTIFICATIONS.october_weekends());

  fundraisingDates?.id === 101 &&
    fundraisingDates?.is_active &&
    notifications.push(NOTIFICATIONS.regular_top_ups());

  useEffect(() => {
    isSubmittedVeriff(veriffStatusCode) && checkVeriffResult();
    return () => {
      clearInterval(intervalID.current);
    };
  }, [veriffStatusCode]);

  useEffect(() => {
    AnalyticsGA.investingReviewStep();
  }, []);

  const checkVeriffResult = () => {
    clearInterval(intervalID.current);
    intervalID.current = setInterval(() => {
      dispatch(getVeriffStatus()).then(
        res =>
          res &&
          res.success &&
          res.data.code === VERIFF_CODES.verified &&
          dispatch(getUserProfile())
      );
    }, 2000);
  };

  const preSubmit = (values, formik) => {
    onSubmit(values, formik);

    // TODO SD-328 - Questionnaire
    // const hasInvestedBefore = !!status?.has_previously_invested;
    // if (
    //   hasInvestedBefore ||
    //   profile.assessment_status === 'assessment_completed'
    // ) {
    //   onSubmit(values, formik);
    // } else {
    //   dispatch(
    //     setModals({
    //       type: MODAL_TYPES.questionnaire,
    //       onSuccess: () => onSubmit(values, formik),
    //     })
    //   );
    // }
  };

  const onSubmit = async (values, formik) => {
    AnalyticsGA.investingReviewSaveSettings();
    const { setValues } = formik;
    const payload = {
      has_accepted_terms_a: !!allocatedGroupA,
      has_accepted_terms_b: !!allocatedGroupB,
    };

    setProcessing(true);
    confirmInvestment(payload)
      .then(res => {
        if (res && res.success) {
          setValues({ ...values, ...payload });
          dispatch(getBalance());
          dispatch(getInvestingStatus());
          dispatch(getPreAllocateInfo());
          dispatch(getIncreasedIncomeStatus());

          isActiveReferralBanner &&
            setTimeout(() => {
              dispatch(
                setModals({
                  type: MODAL_TYPES.bonus_invite_friend_investing,
                })
              );
            }, 20000);
        }
      })
      .finally(() => setProcessing(false));
  };

  const disabled = !(
    profile &&
    isPassedVeriff(profile) &&
    isVerifiedPhoneNumber(profile) &&
    isVerifiedByAdmin(profile)
  );

  return (
    <Formik
      onSubmit={preSubmit}
      enableReinitialize
      validationSchema={VALIDATION_SCHEMA}
      initialValues={{
        has_accepted_terms: false,
      }}
    >
      {({
        values,
        errors,
        dirty,
        touched,
        isSubmitting,
        setFieldValue,
        handleSubmit,
      }) => (
        <form className="inv-review" onSubmit={handleSubmit}>
          <h1 className="f-42 f-500">
            Review and save your investment settings
          </h1>

          {profile && isSubmittedVeriff(veriffStatusCode) && (
            <WarningBlock withBorder className="inv-review__warning_veriff">
              <div className="f-16 f-400">
                We are currently checking your profile information, it may take
                up to two business days. <br />
                We will notify you by email when the results are ready.
              </div>
            </WarningBlock>
          )}

          <NotificationBlock
            className="inv-review__notifications"
            notifications={notifications}
          />

          <BonusFirstInvestHint className="inv-review__bonus" />

          <InvestingSummary
            status={status}
            isReview
            className="inv-review__summary"
            onStrategyEdit={() => {
              setStep(1);
            }}
            onLoansEdit={() => {
              setStep(2);
            }}
          />

          {!!(allocatedGroupA || allocatedGroupB) && (
            <div className="inv-review__agreement">
              <Checkbox
                name="has_accepted_terms"
                isChecked={values.has_accepted_terms}
                error={
                  errors.has_accepted_terms &&
                  touched.has_accepted_terms &&
                  errors.has_accepted_terms
                }
                onChange={() =>
                  setFieldValue(
                    'has_accepted_terms',
                    !values.has_accepted_terms
                  )
                }
                render={() =>
                  allocatedGroupB || (allocatedGroupA && allocatedGroupB) ? (
                    <span
                      className={classnames(
                        'inv-review__agreement-text f-16',
                        errors.has_accepted_terms &&
                          touched.has_accepted_terms &&
                          'error',
                        values.has_accepted_terms && 'checked'
                      )}
                    >
                      I acknowledge and agree to the{' '}
                      <MyLink href={APP_LINKS.termOfUse} isExternal underline>
                        Terms of Use
                      </MyLink>
                      ,{' '}
                      <MyLink
                        href={APP_LINKS.docAssignmentAgreement}
                        isExternal
                        underline
                      >
                        Assignment Agreement
                      </MyLink>{' '}
                      and the terms and conditions of the{' '}
                      <MyLink
                        href={APP_LINKS.docGuaranteeLetter}
                        isExternal
                        underline
                      >
                        Guarantee Letter
                      </MyLink>
                      .
                    </span>
                  ) : (
                    <span
                      className={classnames(
                        'inv-review__agreement-text f-16',
                        errors.has_accepted_terms &&
                          touched.has_accepted_terms &&
                          'error',
                        values.has_accepted_terms && 'checked'
                      )}
                    >
                      I acknowledge and agree to the{' '}
                      <MyLink href={APP_LINKS.termOfUse} isExternal underline>
                        Terms of Use
                      </MyLink>
                      , and I agree to enter into the{' '}
                      <MyLink
                        href={APP_LINKS.docAssignmentAgreement}
                        isExternal
                        underline
                      >
                        Assignment Agreement
                      </MyLink>{' '}
                      with other users on the Platform in the current round.
                    </span>
                  )
                }
              />
            </div>
          )}

          <div className="inv-review__founders">
            {status.option === STRATEGIES.standard && (
              <Dropdown title="Consumer Goods Brands batch">
                <ParticipatingStartupsList />
              </Dropdown>
            )}
            {status.option === STRATEGIES.priority && (
              <PriorityFoundersGroupedList />
            )}
          </div>

          {profile && isSubmittedVeriff(veriffStatusCode) && (
            <WarningBlock
              withBorder
              className="inv-review__warning_veriff_bottom"
            >
              <div className="f-16 f-400">
                We are currently checking your profile information, it may take
                up to two business days. <br />
                We will notify you by email when the results are ready.
              </div>
            </WarningBlock>
          )}

          <div className="inv-review__buttons">
            <RoundButton
              label="Go back"
              onClick={() => {
                setStep(2);
                AnalyticsGA.investingBackButton();
              }}
              className="inv-review__buttons-back"
            />
            <RoundButton
              label="Save settings"
              type="submit"
              onSubmit={handleSubmit}
              fillBackground
              fullWidth
              className="inv-review__buttons-continue"
              disabled={
                !dirty ||
                disabled ||
                isSubmitting ||
                !!Object.keys(errors).length
              }
            />
          </div>

          <ResetInvesting
            status={status}
            onReset={onReset}
            className="inv-review__reset"
          />
        </form>
      )}
    </Formik>
  );
};

export default withScroll(InvReview);
