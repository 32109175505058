import React from 'react';
import classnames from 'classnames';
import { BrandSlider } from './slider';
import s from './index.module.scss';

export const RoundDetailBrands = ({ brands }) => {
  return (
    <div className={s.wrapper}>
      <div>Brands</div>
      {brands?.list.length ? (
        <BrandSlider brands={brands?.list || []} />
      ) : (
        <div className={classnames(s.empty, 'f-16-24')}>No brands yet</div>
      )}
    </div>
  );
};
