import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { APP_LINKS } from '../../../helpers/links';
import { prettyFloatMoney } from '../../../helpers/utils';
import WarningBlock from '../../../components/UI/WarningBlock';
import { RoundLink } from '../../../components/UI/RoundLink';
import { roundStarts } from '../../../helpers/date';
import s from './index.module.scss';

export const PaymentSuccess = ({ amount }) => {
  const { data: status } = useSelector(
    state => state.investStore.investingStatus
  );

  return (
    <div className={s.wrapper}>
      <h1 className={classnames(s.title, 'f-26 f-500')}>Payment successful!</h1>

      <WarningBlock withBorder className={s.message} isSuccess>
        <div className="f-16 f-400">
          Your payment of{' '}
          <span className="f-500">{prettyFloatMoney(amount)}</span> was
          successfully transferred to your Scramble account.
        </div>
      </WarningBlock>

      <p className={classnames(s.desc, 'f-16 f-500')}>Congratulations!</p>

      {status &&
        (status.is_active ? (
          <p className="f-16 f-400">
            You need to do some setting adjustments to take part in the round.
          </p>
        ) : (
          <p className="f-16 f-400">
            {`The round starts on ${roundStarts(
              status.start_date
            )}. We will notify you in advance. In the meantime, check the
            businesses participating in the upcoming round or learn more about
            loan terms.`}
          </p>
        ))}

      <div className={s.buttons}>
        <RoundLink
          path={APP_LINKS.investing}
          label="Go to investing"
          fillBackground
          className={s.button}
        />
        <RoundLink
          path={APP_LINKS.addFunds}
          label="Add more cash"
          className={s.button}
        />
      </div>
    </div>
  );
};
