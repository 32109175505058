import React from 'react';
import classnames from 'classnames';
import { prettyDate, prettyFloatMoney } from '../../../../../helpers/utils';
import { EMPTY_DASH } from '../../../../../helpers/constants';
import { DATE_FORMAT_DD_MM_YYYY } from '../../../../../helpers/common';
import s from './index.module.scss';

export const Table = props => {
  const {
    repayment_schedule,
    total,
    total_earnings,
    total_principals,
    increased_income,
  } = props.data;

  return (
    <div className={s.wrapper}>
      <div className={s.inner}>
        <div className={s.table}>
          <div className={s.table__thead}>
            <div className={s.table__thead_col}>Month</div>
            <div className={s.table__thead_col}>Your earnings</div>
            <div className={s.table__thead_col}>Principal</div>
            <div className={classnames(s.table__thead_col, s.table__hide)}>
              Total
            </div>
          </div>

          <div className={s.table__tbody}>
            {repayment_schedule.map((loan, index) => {
              const isLastMonth = index === repayment_schedule.length - 1;

              return (
                <div key={index} className={classnames(s.table__tbody_row)}>
                  <div className={s.table__tbody_col}>
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {prettyDate(loan.date, DATE_FORMAT_DD_MM_YYYY)}
                    </span>
                  </div>
                  <div className={s.table__tbody_col}>
                    {loan.earnings
                      ? prettyFloatMoney(
                          isLastMonth
                            ? loan.earnings + increased_income
                            : loan.earnings,
                          { roundUpper: true }
                        )
                      : EMPTY_DASH}
                  </div>
                  <div className={s.table__tbody_col}>
                    {loan.principal
                      ? prettyFloatMoney(loan.principal, { roundUpper: true })
                      : EMPTY_DASH}
                  </div>
                  <div
                    className={classnames(s.table__tbody_col, s.table__hide)}
                  >
                    {loan.total
                      ? prettyFloatMoney(
                          isLastMonth
                            ? loan.total + increased_income
                            : loan.total,
                          { roundUpper: true }
                        )
                      : EMPTY_DASH}
                  </div>
                </div>
              );
            })}

            <div
              className={classnames(
                s.table__tbody_row,
                s.table__tbody_row_result,
                'f-500'
              )}
            >
              <div className={s.table__tbody_col}>Total:</div>
              <div className={s.table__tbody_col}>
                {total_earnings
                  ? prettyFloatMoney(total_earnings + increased_income, {
                      roundUpper: true,
                    })
                  : '–'}
              </div>
              <div className={s.table__tbody_col}>
                {total_principals
                  ? prettyFloatMoney(total_principals, {
                      roundUpper: true,
                    })
                  : '–'}
              </div>
              <div className={classnames(s.table__tbody_col, s.table__hide)}>
                {total
                  ? prettyFloatMoney(total + increased_income, {
                      roundUpper: true,
                    })
                  : '–'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
