import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { AFFILIATE_STEPS } from '../data';
import s from './index.module.scss';

export const AffiliateStepsMobile = props => {
  const { referralCode } = useSelector(state => state.referralStore);
  const isActiveReferralBanner = !!referralCode.data?.is_referral_banner_active;

  return (
    <div className={classnames(s.wrapper, props.className)}>
      <div className={s.steps}>
        {AFFILIATE_STEPS.map((item, index) => (
          <div key={item.label} className={s.step}>
            <div className={s.step_number}>
              <div className={s.step_num}>0{index + 1}</div>
            </div>
            <div className={s.step_content}>
              <div className="f-18 f-500">{item.label}</div>
              <div className={classnames(s.desc, 'f-16 f-400')}>
                {item.desc}
              </div>
              {isActiveReferralBanner && item.banner}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
