import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { ArrowUp } from '../Icons';
import { prettyFloatMoney } from '../../../helpers/utils';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { ACCOUNT_TYPES } from '../../../helpers/constants';
import './index.scss';

const BalanceSelect = ({
  value,
  options,
  onSelect,
  disabled,
  disabledGroups,
  className,
}) => {
  const [show, setShow] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setShow(false), show);

  return (
    <div
      className={classnames(
        'balance-select',
        disabled && 'balance-select__disabled',
        show && !disabled && 'balance-select__active',
        className
      )}
      onClick={() => setShow(!show)}
      ref={ref}
    >
      <div className="balance-select__inner">
        <div className="balance-select__left">
          <p className="f-20">
            {value
              ? `${value.group_title} ${
                  value.type === ACCOUNT_TYPES.loan ? 'account' : ''
                }`
              : '--------'}
          </p>
          <p className="f-500 f-16">
            {value ? `${prettyFloatMoney(value.amount)}` : '----'}
          </p>
        </div>
        <ArrowUp className="balance-select__right" />
      </div>

      <div className="balance-select__menu">
        <div className="balance-select__menu-inner">
          {options.map(option => (
            <div
              key={option.group}
              className={classnames(
                'balance-select__option',
                disabledGroups?.includes(option.group) && 'disabled'
              )}
              onClick={() => onSelect(option)}
            >
              <p className="f-20">
                {option.group_title}{' '}
                {option.type === ACCOUNT_TYPES.loan && 'account'}
              </p>
              <p className="f-500 f-16">{prettyFloatMoney(option.amount)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BalanceSelect;
