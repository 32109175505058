import React from 'react';
import classnames from 'classnames';
import { RoundButton } from '../../components/UI/RoundButton';
import s from './index.module.scss';

const ConfirmModal = ({
  title,
  desc,
  confirmLabel = 'Confirm',
  onDelete,
  onConfirm,
  onCancel,
  onClose,
}) => (
  <div className={s.wrapper}>
    <div className={classnames(s.title, 'f-20 f-500')}>{title}</div>
    <div className={classnames(s.desc, 'f-14 f-400')}>{desc}</div>
    <div className={s.controls}>
      <RoundButton
        className={classnames(s.button_cancel, 'hov')}
        type="button"
        label="Cancel"
        onClick={onCancel || onClose}
      />
      {onDelete && (
        <RoundButton
          className={classnames(s.button_delete, 'hov')}
          type="button"
          label="Delete"
          onClick={onDelete}
        />
      )}
      {onConfirm && (
        <RoundButton
          className={classnames(s.button_confirm, 'hov')}
          type="button"
          label={confirmLabel}
          onClick={onConfirm}
        />
      )}
    </div>
  </div>
);

export default ConfirmModal;
