import React from 'react';
import { getFullname, getNameInitials } from '../../../../helpers/utils';
import { SmallHeartIcon } from '../../../../components/UI/Icons';
import GroupedAvatars from '../../../../components/UI/GroupedAvatars';
import { APP_LINKS } from '../../../../helpers/links';
import { MyLink } from '../../../../components/MyLink';
import './index.scss';

const existText =
  'Well done! You have helped founders receive funds for their businesses!';
const notExistText =
  'No founder has received funds from you yet :( Participate in the next round!';

export const HappyFounders = ({ founders }) => {
  const count = (founders && founders.total) || 0;
  const images = founders
    ? founders.founders.map(f => ({
        id: f.id,
        src: f.photo && f.photo.file,
        alt: getFullname(f.first_name, f.last_name),
        nameInitials: getNameInitials(f.first_name, f.last_name),
      }))
    : [];

  return (
    <div className="happy_founders">
      <p className="f-16">Happy founders</p>
      <MyLink to={APP_LINKS.loans} className="happy_founders__people f-500">
        {founders ? founders.total : 0} ppl. <SmallHeartIcon />
      </MyLink>
      <p className="happy_founders__desc f-16">
        {founders && founders.total ? existText : notExistText}
      </p>
      {founders && !!founders.founders.length && (
        <MyLink to={APP_LINKS.loans}>
          <GroupedAvatars
            data={images}
            limit={5}
            count={count}
            showCounter={5}
            size={50}
          />
        </MyLink>
      )}
    </div>
  );
};
