import { METADATA } from '../../helpers/metadata';
import {
  CREATE_WITHDRAW_REQUEST,
  GET_ALLOCATE_INFO,
  GET_PRE_ALLOCATE_INFO,
  GET_WITHDRAW_HISTORY,
  GET_WITHDRAW_INFO,
  SET_BANK_DETAILS_LIST,
  SET_PENDING_TRANSACTIONS,
} from '../types/fundTypes';

export const initialState = {
  allocateInfo: { ...METADATA.default, data: null },
  preAllocateInfo: { ...METADATA.default, data: null },
  withdrawInfo: { ...METADATA.default, data: null },
  withdrawHistory: { ...METADATA.default, data: null },
  pendingTransactions: null,
  bankDetailsList: [],
};

const fundReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLOCATE_INFO.REQUEST:
      return {
        ...state,
        allocateInfo: { ...state.allocateInfo, ...METADATA.request },
      };
    case GET_ALLOCATE_INFO.SUCCESS:
      return {
        ...state,
        allocateInfo: { ...METADATA.success, data: action.payload },
      };
    case GET_ALLOCATE_INFO.FAILURE:
      return {
        ...state,
        allocateInfo: {
          ...state.allocateInfo,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_PRE_ALLOCATE_INFO.REQUEST:
      return {
        ...state,
        preAllocateInfo: { ...state.preAllocateInfo, ...METADATA.request },
      };
    case GET_PRE_ALLOCATE_INFO.SUCCESS:
      return {
        ...state,
        preAllocateInfo: { ...METADATA.success, data: action.payload },
      };
    case GET_PRE_ALLOCATE_INFO.FAILURE:
      return {
        ...state,
        preAllocateInfo: {
          ...state.preAllocateInfo,
          ...METADATA.error,
          data: null,
          error: action.error,
        },
      };
    case GET_WITHDRAW_INFO.REQUEST:
      return {
        ...state,
        withdrawInfo: { ...state.withdrawInfo, ...METADATA.request },
      };
    case GET_WITHDRAW_INFO.SUCCESS:
      return {
        ...state,
        withdrawInfo: { ...METADATA.success, data: action.payload },
      };
    case GET_WITHDRAW_INFO.FAILURE:
      return {
        ...state,
        withdrawInfo: {
          ...state.withdrawInfo,
          ...METADATA.error,
          error: action.error,
        },
      };
    case CREATE_WITHDRAW_REQUEST:
      return {
        ...state,
        withdrawInfo: { ...state.withdrawInfo, data: action.data },
      };
    case GET_WITHDRAW_HISTORY.REQUEST:
      return {
        ...state,
        withdrawHistory: { ...state.withdrawHistory, ...METADATA.request },
      };
    case GET_WITHDRAW_HISTORY.SUCCESS:
      return {
        ...state,
        withdrawHistory: { ...METADATA.success, data: action.payload },
      };
    case GET_WITHDRAW_HISTORY.FAILURE:
      return {
        ...state,
        withdrawHistory: {
          ...state.withdrawHistory,
          ...METADATA.error,
          error: action.error,
        },
      };
    case SET_PENDING_TRANSACTIONS:
      return {
        ...state,
        pendingTransactions: action.payload,
      };
    case SET_BANK_DETAILS_LIST:
      return {
        ...state,
        bankDetailsList: action.payload,
      };

    default:
      return state;
  }
};

export default fundReducer;
