import React, { useState } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import s from './index.module.scss';

const months = new Array(12).fill('');

export const FundraisingSwitch = ({ selected, setSelected }) => {
  const [selectedYear, setSelectedYear] = useState(
    moment(selected.date).format('YYYY')
  );
  const { data: allRounds = [] } = useSelector(
    state => state.roundHistoryStore.allRounds
  );

  const groupedData = allRounds.reduce((acc, item) => {
    const year = moment(item.date).format('YYYY');

    if (acc[year]) {
      acc[year].push(item);
    } else {
      acc[year] = [item];
    }
    return acc;
  }, {});

  const selectedMonth = Number(moment(selected.date).format('M'));

  const availableMonths = groupedData[selectedYear].reduce(
    (acc, fundraising) => {
      acc[moment(fundraising.date).month()] = fundraising.id;
      return acc;
    },
    {}
  );

  const onYearSelect = year => {
    setSelectedYear(year);
    const lastFR = groupedData[year][groupedData[year].length - 1];
    setSelected(lastFR);
  };

  const onMonthSelect = fundraisingId => {
    const fundraising = allRounds.find(r => r.id === fundraisingId);
    if (fundraising) {
      setSelected(fundraising);
    }
  };

  return (
    <div>
      <div className={s.years}>
        {Object.keys(groupedData).map(year => (
          <div
            key={year}
            onClick={() => onYearSelect(year)}
            className={classnames(
              s.years__item,
              selectedYear === year && 'selected'
            )}
          >
            {year}
          </div>
        ))}
      </div>

      <div className={s.months}>
        {months.map((_, index) => {
          const fundraisingId = availableMonths[index];
          return fundraisingId ? (
            <div
              key={index}
              onClick={() => onMonthSelect(fundraisingId)}
              className={classnames(
                s.years__item,
                selectedMonth === index + 1 && 'selected'
              )}
            >
              {moment().month(index).format('MMMM')}
            </div>
          ) : (
            <span className={classnames(s.years__item, 'disabled')} key={index}>
              {moment().month(index).format('MMMM')}
            </span>
          );
        })}
      </div>
    </div>
  );
};
