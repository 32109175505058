import { METADATA } from '../../helpers/metadata';
import {
  GET_BLOG_ARTICLE_DETAIL,
  GET_BLOG_ARTICLES,
  SET_BLOG_BANNERS,
} from '../types/blogTypes';

const initialState = {
  blogBanners: [],
  blogArticles: { ...METADATA.default, data: null },
  blogArticleDetail: { ...METADATA.default, data: null },
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BLOG_BANNERS:
      return { ...state, blogBanners: action.payload };
    case GET_BLOG_ARTICLES.REQUEST:
      return {
        ...state,
        activityList: { ...state.blogArticles, ...METADATA.request },
      };
    case GET_BLOG_ARTICLES.SUCCESS:
      return {
        ...state,
        blogArticles: { ...METADATA.success, data: action.payload },
      };
    case GET_BLOG_ARTICLES.FAILURE:
      return {
        ...state,
        blogArticles: {
          ...state.blogArticles,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_BLOG_ARTICLE_DETAIL.REQUEST:
      return {
        ...state,
        blogArticleDetail: { ...METADATA.request, data: null },
      };
    case GET_BLOG_ARTICLE_DETAIL.SUCCESS:
      return {
        ...state,
        blogArticleDetail: { ...METADATA.success, data: action.payload },
      };
    case GET_BLOG_ARTICLE_DETAIL.FAILURE:
      return {
        ...state,
        blogArticleDetail: {
          ...METADATA.error,
          data: null,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default blogReducer;
