import React from 'react';
import { useSelector } from 'react-redux';
import { AffiliateStepsDesktop } from './desktop';
import { AffiliateStepsMobile } from './mobile';

const AffiliateSteps = props => {
  const { innerWidth } = useSelector(state => state.commonStore);

  return innerWidth <= 900 ? (
    <AffiliateStepsMobile {...props} />
  ) : (
    <AffiliateStepsDesktop {...props} />
  );
};

export default AffiliateSteps;
