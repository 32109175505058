import React, { useRef } from 'react';

export const useAccumulator = () => {
  const storage = useRef([]);

  const setAccumulatorItem = React.useCallback(name => {
    if (!storage.current.includes(name)) {
      storage.current.push(name);
    }
  }, []);

  const clearAccumulator = React.useCallback(() => {
    storage.current = [];
  }, []);

  return [storage.current, setAccumulatorItem, clearAccumulator];
};
