import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { FundraisingSwitch } from './controls';
import { RoundDetailStats } from '../stats';
import { RoundDetailBrands } from '../brands';
import { RoundDetailCountries } from '../countries';
import { RoundDetailRepaymentProgress } from '../repayment';
import { RoundDetailGroups } from '../groups';
import { ROUND_STATUSES } from '../../constants';
import Preloader from '../../../../components/Preloader';
import {
  getBrandsBatchById,
  getRoundCountriesById,
  getRoundLoanGroupsById,
  getRoundRepaymentProgressById,
} from '../../../../store/actions/roundHistoryActions';
import s from './index.module.scss';

export const RoundDetail = ({ selected, setSelected }) => {
  const dispatch = useDispatch();

  const { data: countries, loading: loadingCountries } = useSelector(
    state => state.roundHistoryStore.countries
  );
  const { data: repaymentProgress, loading: loadingRepaymentProgress } =
    useSelector(state => state.roundHistoryStore.repaymentProgress);

  const { data: brands, loading: loadingBrands } = useSelector(
    state => state.roundHistoryStore.brands
  );

  const { data: loanGroups, loading: loadingLoanGroups } = useSelector(
    state => state.roundHistoryStore.loanGroups
  );

  const isPastRound = ![
    ROUND_STATUSES.upcoming.code,
    ROUND_STATUSES.in_progress.code,
  ].includes(selected.status);

  useEffect(() => {
    dispatch(
      getBrandsBatchById(selected.id, {
        page: 1,
        limit: 1000,
      })
    );
    if (isPastRound) {
      dispatch(getRoundCountriesById(selected.id));
      dispatch(getRoundRepaymentProgressById(selected.id));
      dispatch(getRoundLoanGroupsById(selected.id));
    }
  }, [selected]);

  const loading =
    loadingCountries ||
    loadingRepaymentProgress ||
    loadingBrands ||
    loadingLoanGroups;

  return (
    <div className={s.wrapper}>
      <h2 className="f-32 f-500">
        {moment(selected.date).format('MMMM YYYY')}
      </h2>
      <FundraisingSwitch selected={selected} setSelected={setSelected} />
      <RoundDetailStats selectedFundraising={selected} />
      {loading ? (
        <Preloader className={s.preloader} />
      ) : (
        <>
          <RoundDetailBrands brands={brands} />
          {isPastRound && (
            <>
              <RoundDetailCountries data={countries} />
              <RoundDetailRepaymentProgress
                graph={repaymentProgress}
                selected={selected}
              />
              <RoundDetailGroups loanGroups={loanGroups} />
            </>
          )}
        </>
      )}
    </div>
  );
};
