import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import MiniFaqs, { MINIFAQ_SOURCES } from '../../containers/MiniFaqs';
import { StartupsActive } from './active';
import { StartupsAll } from './all';
import { SuspendResidenceWarning } from '../../components/Warnings/SuspendResidenceWarning';
import { USER_STATUSES } from '../../helpers/constants';
import { getUserProfile } from '../../store/actions/profileActions';
import { BrandTabs } from '../../components/Tabs/BrandTabs';
import AnalyticsGA from '../../analyticsGA';
import s from './index.module.scss';

const StartupsPage = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profileStore.profile.data);

  useEffect(() => {
    dispatch(getUserProfile());
    AnalyticsGA.pageNavigation('Brands');
  }, []);

  return (
    <div className={s.startups_page}>
      <div className="container-inner">
        <h1 className={classnames(s.startups_page__title, 'f-42 f-500')}>
          Consumer Goods Brands
        </h1>
        <p className={classnames(s.startups_page__desc, 'f-16 f-400')}>
          We collaborate with cutting-edge consumer goods brands from the UK and
          continental Europe. Our partnered brands are pioneers of the new
          generation, creating trend-setting products that also have a positive
          impact on the environment and the community.
        </p>
        <BrandTabs />
        {profile?.status === USER_STATUSES.suspended && (
          <SuspendResidenceWarning className={s.startups_page__warning} />
        )}
        <div className={s.startups_page__sections}>
          <StartupsActive />
          <StartupsAll />
        </div>
        <MiniFaqs source={MINIFAQ_SOURCES.founders} />
      </div>
    </div>
  );
};

export default StartupsPage;
