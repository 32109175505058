import {
  SET_INACTIVE_INVESTORS,
  SET_PARTICIPATING_INVESTORS,
} from '../types/investorTypes';

const initialState = {
  participatingInvestors: null,
  inactiveInvestors: null,
};

const investorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARTICIPATING_INVESTORS:
      return { ...state, participatingInvestors: action.payload };
    case SET_INACTIVE_INVESTORS:
      return { ...state, inactiveInvestors: action.payload };

    default:
      return state;
  }
};

export default investorReducer;
