import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { getRepaymentScheduleByFundraisingID } from '../../../store/actions/loanActions';
import RepScheduleTable from '../../RepaymentScheduleModule/table';
import s from './index.module.scss';

export const RepaymentSchedule = ({ fundraisingID, group }) => {
  const dispatch = useDispatch();
  const { repaymentScheduleByFundraisingID } = useSelector(
    state => state.loanStore
  );

  useEffect(() => {
    dispatch(getRepaymentScheduleByFundraisingID(fundraisingID, { group }));
  }, [fundraisingID, group]);

  const { data } = repaymentScheduleByFundraisingID;

  return (
    <div className={s.wrapper}>
      <h2 className="f-26 f-500">Repayment schedule</h2>
      {!!data?.list.length && data?.show_extend_disclaimer && (
        <div className={classnames(s.disclaimer, 'f-16 f-400')}>
          The repayment schedule is subject to changes in case a startup extends
          loan terms (to a maximum of 24 months) or moves a monthly repayment to
          the last month of the current loan term.
        </div>
      )}

      {data?.list.length ? (
        <RepScheduleTable data={data.list} />
      ) : (
        <div className={classnames(s.empty_message, 'f-16 f-400')}>
          No repayments
        </div>
      )}
    </div>
  );
};
