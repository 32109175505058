export const GET_REFERRALS_LIST = {
  REQUEST: 'GET_REFERRALS_LIST_REQUEST',
  SUCCESS: 'GET_REFERRALS_LIST_SUCCESS',
  FAILURE: 'GET_REFERRALS_LIST_FAILURE',
};

export const GET_REFERRAL_CODE = {
  REQUEST: 'GET_REFERRAL_CODE_REQUEST',
  SUCCESS: 'GET_REFERRAL_CODE_SUCCESS',
  FAILURE: 'GET_REFERRAL_CODE_FAILURE',
};
