import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { SET_BONUS_ALLOCATION_INFO } from '../types/bonusTypes';

const setBonusAllocationInfo = payload => ({
  type: SET_BONUS_ALLOCATION_INFO,
  payload,
});

export const getBonusAllocationInfo = () => {
  return dispatch => {
    return axios
      .get(Pathes.Bonus.bonusAllocation)
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          dispatch(setBonusAllocationInfo(data));
          return { data, success: true };
        }
        throw new Error('Fetch error');
      })
      .catch(() => dispatch(setBonusAllocationInfo(null)));
  };
};
