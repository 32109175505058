import React from 'react';
import IconStar from './assets/star.svg';
import s from './index.module.scss';

const YellowBanner = ({ children }) => {
  return (
    <div className={s.wrapper}>
      <img src={IconStar} alt="star" />
      {children}
    </div>
  );
};

export const FiveForHundredBanner = () => (
  <YellowBanner>
    <span className="f-14">
      <span className="f-500">€5 bonus</span> for every €100 first investment
    </span>
  </YellowBanner>
);

export const AdditionalFiveForFriendBanner = () => (
  <YellowBanner>
    <span className="f-14">
      Additional <span className="f-500">5% bonus</span> on your friend&apos;s
      initial investment
    </span>
  </YellowBanner>
);
