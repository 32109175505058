import React from 'react';
import moment from 'moment';
import { prettyFloatMoney } from '../../../helpers/utils';
import { DATE_FORMAT_DD_MMM_YYYY } from '../../../helpers/common';
import { REPAYMENT_STATUSES } from '../index';
import Tooltip from '../../../components/Tooltip';
import { InfoIcon } from '../../../components/UI/Icons';
import { MyLink } from '../../../components/MyLink';
import { APP_LINKS } from '../../../helpers/links';
import './index.scss';

const RepScheduleList = ({ data }) => (
  <div className="rep_schedule_list">
    <div className="rep_schedule_list__list">
      {data.map((item, idx) => {
        const paidPercentage =
          (item.payment_received / item.total_amount) * 100;
        return (
          <div className="rep_schedule_list__list_item" key={idx}>
            <div className="loan_card__row">
              <div className="c-grey f-14 f-400">Due date</div>
              <div className="f-18 f-500">
                {moment(item.deadline).format(DATE_FORMAT_DD_MMM_YYYY)}
              </div>
            </div>
            <div className="loan_card__row">
              <div className="loan_card__tooltip">
                <div className="c-grey f-14 f-400">Amount</div>
                {item.is_extendable && (
                  <Tooltip
                    clickMode
                    icon={InfoIcon}
                    position="bottom center"
                    renderContent={() => (
                      <div className="rep_schedule_list__tooltip f-16  f-400">
                        This scheduled payment can be changed if a startup
                        decides to extend the repayment period for an additional
                        6 months. In this case, you will receive an additional
                        flat fee for the extension. Learn more in the{' '}
                        <MyLink href={APP_LINKS.helpLoanTerms()} underline>
                          Loan terms
                        </MyLink>
                        .
                      </div>
                    )}
                  />
                )}
              </div>
              <div className="f-18 f-500">
                {prettyFloatMoney(item.total_amount)}
              </div>
            </div>
            <div className="loan_card__row">
              <div className="c-grey f-14 f-400">Payment Date</div>
              <div className="f-18 f-500">
                {' '}
                {moment(item.deadline).format(DATE_FORMAT_DD_MMM_YYYY)}
              </div>
            </div>
            <div className="loan_card__row">
              <div className="c-grey f-14 f-400">Payment Received</div>
              <div
                className="f-18 f-500"
                style={{ color: REPAYMENT_STATUSES[item.status].color }}
              >
                {item.payment_received
                  ? prettyFloatMoney(item.payment_received)
                  : '—'}

                {/* {paidPercentage > 0 && paidPercentage < 100 && ( */}
                {/*   <span>&nbsp;({parseInt(paidPercentage)}%)</span> */}
                {/* )} */}
              </div>
            </div>
            <div className="loan_card__row">
              <div className="c-grey f-14 f-400">Status</div>
              <div
                className="f-18 f-500"
                style={{ color: REPAYMENT_STATUSES[item.status].color }}
              >
                {REPAYMENT_STATUSES[item.status].label}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export default RepScheduleList;
