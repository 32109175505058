import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { getVeriffStatus } from '../../../store/actions/profileActions';
import { APP_LINKS } from '../../../helpers/links';
import {
  getNameInitials,
  isParticipatingInvesting,
} from '../../../helpers/utils';
import { BonusBanner } from '../../BonusProgram/banner';
import { MyLink } from '../../../components/MyLink';
import Avatar from '../../../components/UI/Avatar';
import VerificationProcessWizard from './VeriffProcessWizard';
import NotificationBlock from '../../NotificationBlock';
import { MinInvestAmountWarning } from '../../../components/Warnings/MinInvestAmountWarning';
import s from './index.module.scss';

export const NewUsersView = ({ isActiveFR }) => {
  const dispatch = useDispatch();
  const { balance } = useSelector(state => state.dashboardStore);
  const { data: profile } = useSelector(state => state.profileStore.profile);
  const { data: status } = useSelector(
    state => state.investStore.investingStatus
  );

  const notifications = [];

  useEffect(() => {
    dispatch(getVeriffStatus());
  }, []);

  return (
    <>
      <BonusBanner className={s.banner} />
      <div className={s.header}>
        <div className={s.header__row}>
          <MyLink href={APP_LINKS.profile} className={s.avatar__link}>
            <Avatar
              src={profile.photo && profile.photo.file}
              size={65}
              alt={getNameInitials(profile.first_name, profile.last_name)}
              nameInitials={getNameInitials(
                profile.first_name,
                profile.last_name
              )}
              className={s.avatar}
              active={isParticipatingInvesting(status)}
            />
          </MyLink>
        </div>

        <div className={classnames('f-42 f-500', s.title)}>
          {profile.first_name
            ? `Hey ${profile.first_name}, \n complete this checklist to start investing`
            : 'Hi there, \n complete this checklist to start investing'}
        </div>
      </div>

      <NotificationBlock
        className={s.notifications}
        notifications={notifications}
      />

      {profile.country_code === 'US' &&
        status.min_investment_amount === 1000 && (
          <MinInvestAmountWarning className={s.amount_warning} />
        )}

      {profile && balance && (
        <VerificationProcessWizard isActiveFR={isActiveFR} />
      )}
    </>
  );
};
