import React from 'react';
import classnames from 'classnames';
import Tooltip from '../../../../components/Tooltip';
import { MyLink } from '../../../../components/MyLink';
import Dropdown from '../../../../components/Dropdown';
import { LOAN_GROUPS } from '../../../../helpers/constants';
import { LOAN_GROUPS_V1 } from '../../../LoanTermsPage/versions/v1/data';
import ProtectADesktop from './assets/protection_group_a_desktop.svg';
import ProtectAMobile from './assets/protection_group_a_mobile.svg';
import ProtectBDesktop from './assets/protection_group_b_desktop.svg';
import ProtectBMobile from './assets/protection_group_b_mobile.svg';
import { GroupAExample, GroupBExample } from './tableData';
import { ExampleList } from './ExampleList';
import { ExampleTable } from './ExampleTable';
import s from './index.module.scss';

export const GROUP_DETAIL = {
  [LOAN_GROUPS.conservative.id]: innerWidth => ({
    title: LOAN_GROUPS_V1[0].title,
    termsSection: (
      <section className={s.page__terms}>
        <h2 className="f-24 f-500">Loan terms</h2>
        <div className={s.page__first_row}>
          <div className={s.page__annual_return}>
            <div className={s.page__annual_return_header}>
              <div
                className={classnames(
                  s.page__annual_return_title,
                  'f-16 f-400'
                )}
              >
                Target annual return
              </div>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className="f-16 f-400">
                    Target annual return calculations include reinvested
                    repayments. Reinvesting significantly boosts your returns
                    over time.
                  </div>
                )}
              />
            </div>
            <div
              className={classnames(s.page__annual_return_value, 'f-24 f-500')}
            >
              {LOAN_GROUPS_V1[0].rate}
            </div>
          </div>

          <div className={s.page__term}>
            <div className={classnames(s.page__term_title, 'f-16 f-400')}>
              Term
            </div>
            <div className={classnames(s.page__term_value, 'f-24 f-500')}>
              {LOAN_GROUPS_V1[0].repaymentPeriod}
            </div>
          </div>
        </div>
        <div className={s.page__second_row}>
          <div
            className={classnames(s.page__nominal_return_title, 'f-16 f-400')}
          >
            Nominal return
          </div>
          <div className={s.page__nominal_return}>
            <div className={s.page__nominal_return_item}>
              <div className="f-24 f-500">6% annual interest</div>
              <div className="f-16 f-400">paid monthly</div>
            </div>
            <div className={s.page__nominal_return_item}>
              <div className="f-24 f-500">4% success return</div>
              <div className="f-16 f-400">
                paid upon loan principal repayment
              </div>
            </div>
          </div>
        </div>
      </section>
    ),
    protectionSection: (
      <section className={s.page__protection}>
        <h2 className="f-24 f-500">Triple-secured downside protection</h2>
        <p className="f-16 f-400">
          Loans of group A - Senior loans - are the first ones to be repaid. So
          if a business fails, investors in loans A have the highest chances to
          be repaid.
        </p>

        <div className={s.page__protection_content}>
          {[
            {
              label: 'Co-founder team guarantees',
              content: (
                <div className="f-16 f-400">
                  Each loan is paid back to investors using the co-founders
                  team’s future personal income.It means each co-founder is
                  ultimately personally responsible for the repayment of loans
                  taken by their business.{' '}
                  <MyLink href="#">
                    Learn more about co-founders guarantee
                  </MyLink>
                </div>
              ),
            },
            {
              label: 'Mutual security deposit',
              content: (
                <div className="f-16 f-400">
                  All businesses contribute 5% of the funding amount a mutual
                  security deposit against possible founder defaults. If any
                  founder fails to repay the loan from their incomes within the
                  specified period, the security deposit will cover the
                  investors’ losses.{' '}
                  <MyLink href="#">
                    Learn more about mutual security deposit
                  </MyLink>
                </div>
              ),
            },
            {
              label: 'First loss capital',
              content: (
                <div className="f-16 f-400">
                  Group B loans contribute 15% first-loss capital for all Group
                  A loans.If the number of failed founders exceeds a certain
                  limit, and the security deposit amount is insufficient to
                  repay the loan, investors issued loans B provide 15%
                  first-loss capital as additional protection against the losses
                  of investors.{' '}
                  <MyLink href="#">Learn more about first-loss capital</MyLink>
                </div>
              ),
            },
          ].map((item, idx) => (
            <Dropdown
              key={item.label}
              label={<span className="f-20 f-500">{item.label}</span>}
              data-index={`0${idx + 1}`}
              disableToggle={innerWidth > 992}
              defaultShow={innerWidth > 992}
            >
              {item.content}
            </Dropdown>
          ))}
        </div>
      </section>
    ),
    repaymentSection: (
      <section className={s.page__repayments}>
        <h2 className="f-24 f-500">Loan repayments</h2>
        <p className="f-16 f-400">
          Monthly loan repayments are transferred to your Scramble cash account
          on the <span className="f-500">5th day</span>, the month after the
          loan is issued.
          <br />
          Regular repayment scenario:
        </p>
        <img
          src={innerWidth > 650 ? ProtectADesktop : ProtectAMobile}
          alt="Group A"
          className={s.page__repayments_image}
        />
        <div className={s.page__repayments_description}>
          <ul className="scr-circle-list">
            <li>
              <div className="f-16 f-500">From 1st to 6th months</div>
              <div className="f-16 f-400">
                During the first 6 months you only get monthly interest payments
                (6% annual interest means 0.5% of remaining principal each
                month).
              </div>
            </li>
            <li>
              <div className="f-16 f-500">From 7th to 12th months</div>
              <div className="f-16 f-400">
                During months 7th to 12th you get the 6% annual interest, loan
                principal in parts, and corresponding portion of the success
                return.
              </div>
            </li>
          </ul>
        </div>
        <div className={classnames(s.page__repayments_note1, 'f-16 f-400')}>
          If a business cannot repay the loan in full and on time, we apply
          additional <span className="f-500">late charges</span>. We charge 3%
          of the loan issued for each missed monthly repayment. These late
          charges are paid in the last month of the loan period.
        </div>
        <div className={classnames(s.page__repayments_note2, 'f-16 f-400')}>
          <span className="f-500">No fees on investor capital:</span> annual
          management, transaction, or profit-sharing fees for the investors.
        </div>
        <Dropdown
          label={<span className="f-20 f-400">Loan repayments example</span>}
          className={s.page__example}
          defaultShow={false}
        >
          <>
            <div className={classnames(s.page__example_subtitle, 'f-16 f-400')}>
              For example, you invested <span className="f-500">€1’000</span> in{' '}
              <span className="f-500">loan A</span> on{' '}
              <span className="f-500">January 5</span>, then the repayment
              schedule will be as follows:
            </div>

            <div className={s.page__example_summary}>
              <div className={s.page__example_summary_left}>
                <div className="f-16">
                  <div>Loan amount</div>
                  <div className="f-600">
                    <b>€1’000</b>
                  </div>
                </div>
                <div className="f-16">
                  <div>Original date</div>
                  <div className="f-600">
                    <b>05.01.2022</b>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.page__example_table}>
              {window.innerWidth < 992 ? (
                <ExampleList data={GroupAExample} />
              ) : (
                <ExampleTable data={GroupAExample} />
              )}
            </div>
          </>
        </Dropdown>
      </section>
    ),
  }),

  [LOAN_GROUPS.moderate.id]: innerWidth => ({
    title: LOAN_GROUPS_V1[1].title,
    termsSection: (
      <section className={s.page__terms}>
        <h2 className="f-24 f-500">Loan terms</h2>
        <div className={s.page__first_row}>
          <div className={s.page__annual_return}>
            <div className={s.page__annual_return_header}>
              <div
                className={classnames(
                  s.page__annual_return_title,
                  'f-16 f-400'
                )}
              >
                Target annual return
              </div>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className="loan-group-card__tooltip f-16 f-400">
                    Target annual return calculations include reinvested
                    repayments. Reinvesting significantly boosts your returns
                    over time.
                  </div>
                )}
              />
            </div>
            <div
              className={classnames(s.page__annual_return_value, 'f-24 f-500')}
            >
              {LOAN_GROUPS_V1[1].rate}
            </div>
          </div>

          <div className={s.page__term}>
            <div className={classnames(s.page__term_title, 'f-16 f-400')}>
              Term
            </div>
            <div className={classnames(s.page__term_value, 'f-24 f-500')}>
              {LOAN_GROUPS_V1[1].repaymentPeriod}
            </div>
          </div>
        </div>
        <div className={s.page__second_row}>
          <div
            className={classnames(s.page__nominal_return_title, 'f-16 f-400')}
          >
            Nominal return
          </div>
          <div className={s.page__nominal_return}>
            <div className={s.page__nominal_return_item}>
              <div className="f-24 f-500">6% annual interest</div>
              <div className="f-16 f-400">paid monthly</div>
            </div>
            <div className={s.page__nominal_return_item}>
              <div className="f-24 f-500">17.33% success return</div>
              <div className="f-16 f-400">paid from 7th to 12th months</div>
            </div>
          </div>
        </div>
      </section>
    ),
    protectionSection: (
      <section className={s.page__protection}>
        <h2 className="f-24 f-500">Double-secured downside protection</h2>
        <p className="f-16 f-400">
          Loans of group B - Junior loans - are repaid after loans A. Investors
          in loans B have higher risk of loan non-repayment than investors in
          loans A.
        </p>

        <div className={s.page__protection_content}>
          {[
            {
              label: 'Co-founder team guarantees',
              content: (
                <div className="f-16 f-400">
                  Each loan is paid back to investors using the co-founders
                  team’s future personal income.It means each co-founder is
                  ultimately personally responsible for the repayment of loans
                  taken by their business.{' '}
                  <MyLink href="#">
                    Learn more about co-founders guarantee
                  </MyLink>
                </div>
              ),
            },
            {
              label: 'Mutual security deposit',
              content: (
                <div className="f-16 f-400">
                  All businesses contribute 5% of the funding amount a mutual
                  security deposit against possible founder defaults. If any
                  founder fails to repay the loan from their incomes within the
                  specified period, the security deposit will cover the
                  investors’ losses.{' '}
                  <MyLink href="#">
                    Learn more about mutual security deposit
                  </MyLink>
                </div>
              ),
            },
          ].map((item, idx) => (
            <Dropdown
              key={item.label}
              label={<span className="f-20 f-500">{item.label}</span>}
              data-index={`${idx + 1}`}
              disableToggle={innerWidth > 992}
              defaultShow={innerWidth > 992}
            >
              {item.content}
            </Dropdown>
          ))}
        </div>
      </section>
    ),
    repaymentSection: (
      <section className={s.page__repayments}>
        <h2 className="f-24 f-500">Loan repayments</h2>
        <p className="f-16 f-400">
          Monthly loan repayments are transferred to your Scramble cash account
          on the <span className="f-500">5th day</span>, the month after the
          loan is issued.
          <br />
          Regular repayment scenario:
        </p>
        <img
          src={innerWidth > 650 ? ProtectBDesktop : ProtectBMobile}
          alt="Group B"
          className={s.page__repayments_image}
        />
        <div className={s.page__repayments_description}>
          <ul className="scr-circle-list">
            <li>
              <div className="f-16 f-500">From 1st to 6th months</div>
              <div className="f-16 f-400">
                During the first 6 months you only get monthly interest payments
                (6% annual interest means 0.5% of remaining principal each
                month).
              </div>
            </li>
            <li>
              <div className="f-16 f-500">From 7th to 11th months</div>
              <div className="f-16 f-400">
                During months 7th to 11th you get the 6% annual interest and a
                corresponding portion of the success return.
              </div>
            </li>
            <li>
              <div className="f-16 f-500">At 12th month</div>
              <div className="f-16 f-400">
                At 12th month you get the 6% annual interest, full amount of
                loan principal and a corresponding portion of the success
                return.
              </div>
            </li>
          </ul>
        </div>
        <div className={classnames(s.page__repayments_note1, 'f-16 f-400')}>
          If a business cannot repay the loan in full and on time, we apply
          additional <span className="f-500">late charges</span>. We charge 3%
          of the loan issued for each missed monthly repayment. These late
          charges are paid in the last month of the loan period.
        </div>
        <div className={classnames(s.page__repayments_note2, 'f-16 f-400')}>
          <span className="f-500">No fees on investor capital:</span> annual
          management, transaction, or profit-sharing fees for the investors.
        </div>
        <Dropdown
          label={<span className="f-20 f-400">Loan repayments example</span>}
          className={s.page__example}
          defaultShow={false}
        >
          <>
            <div className={classnames(s.page__example_subtitle, 'f-16 f-400')}>
              For example, you invested <span className="f-500">€1’000</span> in{' '}
              <span className="f-500">loan B</span> on{' '}
              <span className="f-500">January 5</span>, then the repayment
              schedule will be as follows:
            </div>

            <div className={s.page__example_summary}>
              <div className={s.page__example_summary_left}>
                <div className="f-16">
                  <div>Loan amount</div>
                  <div className="f-600">
                    <b>€1’000</b>
                  </div>
                </div>
                <div className="f-16">
                  <div>Original date</div>
                  <div className="f-600">
                    <b>05.01.2022</b>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.page__example_results}>
              {window.innerWidth < 992 ? (
                <ExampleList data={GroupBExample} />
              ) : (
                <ExampleTable data={GroupBExample} />
              )}
            </div>
          </>
        </Dropdown>
      </section>
    ),
  }),
};
