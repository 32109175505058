export const GET_ALLOCATE_INFO = {
  REQUEST: 'GET_ALLOCATE_INFO_REQUEST',
  SUCCESS: 'GET_ALLOCATE_INFO_SUCCESS',
  FAILURE: 'GET_ALLOCATE_INFO_FAILURE',
};

export const GET_PRE_ALLOCATE_INFO = {
  REQUEST: 'GET_PRE_ALLOCATE_INFO_REQUEST',
  SUCCESS: 'GET_PRE_ALLOCATE_INFO_SUCCESS',
  FAILURE: 'GET_PRE_ALLOCATE_INFO_FAILURE',
};

export const GET_WITHDRAW_INFO = {
  REQUEST: 'GET_WITHDRAW_INFO_REQUEST',
  SUCCESS: 'GET_WITHDRAW_INFO_SUCCESS',
  FAILURE: 'GET_WITHDRAW_INFO_FAILURE',
};

export const UPDATE_REINVEST_STATUS = {
  REQUEST: 'UPDATE_REINVEST_STATUS_REQUEST',
  SUCCESS: 'UPDATE_REINVEST_STATUS_SUCCESS',
  FAILURE: 'UPDATE_REINVEST_STATUS_FAILURE',
};

export const GET_WITHDRAW_HISTORY = {
  REQUEST: 'GET_WITHDRAW_HISTORY_REQUEST',
  SUCCESS: 'GET_WITHDRAW_HISTORY_SUCCESS',
  FAILURE: 'GET_WITHDRAW_HISTORY_FAILURE',
};

export const SET_BANK_DETAILS_LIST = 'SET_BANK_DETAILS_LIST';
export const CREATE_WITHDRAW_REQUEST = 'CREATE_WITHDRAW_REQUEST';
export const SET_PENDING_TRANSACTIONS = 'SET_PENDING_TRANSACTIONS';
