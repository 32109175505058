import { isNumber, prettyFloatMoney } from '../../../helpers/utils';

export const TAX_REPORT_CELLS = {
  account_value: {
    label: 'Account Value',
    value: ({ account_value }) =>
      isNumber(account_value) ? prettyFloatMoney(account_value) : '—',
  },
  invested_funds: {
    label: 'Invested Funds',
    value: ({ invested_funds }) =>
      isNumber(invested_funds) ? prettyFloatMoney(invested_funds) : '—',
  },
  opening_balance: {
    label: 'Opening Balance',
    value: ({ opening_balance }) =>
      isNumber(opening_balance) ? prettyFloatMoney(opening_balance) : '—',
  },
  top_up: {
    label: 'Top ups',
    value: ({ top_up }) => (isNumber(top_up) ? prettyFloatMoney(top_up) : '—'),
  },
  withdrawals: {
    label: 'Withdrawals',
    value: ({ withdrawals }) =>
      isNumber(withdrawals) ? prettyFloatMoney(withdrawals) : '—',
  },
  received_payments: {
    label: 'Received payments:',
    value: ({ received_payments }) =>
      isNumber(received_payments) ? prettyFloatMoney(received_payments) : '—',
  },
  principal_repayment: {
    label: 'Principal repayment',
    value: ({ principal_repayment }) =>
      isNumber(principal_repayment)
        ? prettyFloatMoney(principal_repayment)
        : '—',
  },
  interest_repayment: {
    label: 'Interest repayment',
    value: ({ interest_repayment }) =>
      isNumber(interest_repayment) ? prettyFloatMoney(interest_repayment) : '—',
  },
  delayed_interest_repayment: {
    label: 'Delayed interest repayment',
    value: ({ delayed_interest_repayment }) =>
      isNumber(delayed_interest_repayment)
        ? prettyFloatMoney(delayed_interest_repayment)
        : '—',
  },
  campaign_bonuses: {
    label: 'Campaign bonuses',
    value: ({ campaign_bonuses }) =>
      isNumber(campaign_bonuses) ? prettyFloatMoney(campaign_bonuses) : '—',
  },
  rewards: {
    label: 'Rewards',
    value: ({ rewards }) =>
      isNumber(rewards) ? prettyFloatMoney(rewards) : '—',
  },
  cancelled_bonuses: {
    label: 'Cancelled bonuses',
    value: ({ cancelled_bonuses }) =>
      isNumber(cancelled_bonuses) ? prettyFloatMoney(cancelled_bonuses) : '—',
  },
  active_investments_at_the_start_date_of_the_activity_report: {
    label: 'Active Investments Before Timeframe',
    value: ({ active_investments_at_the_start_date_of_the_activity_report }) =>
      isNumber(active_investments_at_the_start_date_of_the_activity_report)
        ? prettyFloatMoney(
            active_investments_at_the_start_date_of_the_activity_report
          )
        : '—',
  },
  active_investments_at_the_end_date_of_the_activity_report: {
    label: 'Active Investments At The End Of TimeFrame',
    value: ({ active_investments_at_the_end_date_of_the_activity_report }) =>
      isNumber(active_investments_at_the_end_date_of_the_activity_report)
        ? prettyFloatMoney(
            active_investments_at_the_end_date_of_the_activity_report
          )
        : '—',
  },
  closing_balance: {
    label: 'Closing Balance',
    value: ({ closing_balance }) =>
      isNumber(closing_balance) ? prettyFloatMoney(closing_balance) : '—',
  },
};
