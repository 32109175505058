export const SET_BLOG_BANNERS = 'SET_BLOG_BANNERS';

export const GET_BLOG_ARTICLES = {
  REQUEST: 'GET_BLOG_ARTICLES_REQUEST',
  SUCCESS: 'GET_BLOG_ARTICLES_SUCCESS',
  FAILURE: 'GET_BLOG_ARTICLES_FAILURE',
};

export const GET_BLOG_ARTICLE_DETAIL = {
  REQUEST: 'GET_BLOG_ARTICLE_DETAIL_REQUEST',
  SUCCESS: 'GET_BLOG_ARTICLE_DETAIL_SUCCESS',
  FAILURE: 'GET_BLOG_ARTICLE_DETAIL_FAILURE',
};
