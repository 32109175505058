import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../../components/Dropdown';
import { getLoanSecurityByFundraisingId } from '../../../store/actions/loanActions';
import { prettyDate, prettyFloatMoney } from '../../../helpers/utils';
import s from './index.module.scss';

export const LoanSecurity = ({ fundraisingID }) => {
  const dispatch = useDispatch();
  // TODO add pagination
  const [params, setParams] = React.useState({
    page: 1,
    limit: 10,
  });

  const { loanSecurityByFundraisingID } = useSelector(state => state.loanStore);

  useEffect(() => {
    dispatch(getLoanSecurityByFundraisingId(fundraisingID, params));
  }, []);

  const getNext = totalPages => {
    if (params.page < totalPages) {
      const nextPage = params.page + 1;
      dispatch(
        getLoanSecurityByFundraisingId(
          fundraisingID,
          { ...params, page: nextPage },
          true
        )
      );
      setParams(prevState => ({ ...prevState, page: nextPage }));
    }
  };

  const { data } = loanSecurityByFundraisingID;

  if (!data?.list.length) return null;

  return (
    <div className={s.wrapper}>
      <Dropdown
        label={
          <div className="loan-info-contracts__header">
            <h2 className="f-26 f-500">Loan security</h2>
          </div>
        }
      >
        {data?.list.length ? (
          <>
            <ol className={s.list}>
              {data.list.map((item, index) => (
                <li key={item.id}>
                  {index + 1}. Guarantee letter No.{item.id}; guarantee letter
                  date {prettyDate(item.created_at)}, guarantee letter amount{' '}
                  {prettyFloatMoney(item.amount)};
                </li>
              ))}
            </ol>

            {data && params.page < data.total_pages && (
              <div className={s.buttons}>
                <button
                  type="button"
                  className="'f-24 f-500'"
                  onClick={() => getNext(data.total_pages)}
                >
                  Load more
                </button>
              </div>
            )}
          </>
        ) : (
          <div className={classnames(s.empty, 'f-16')}>
            No loan security yet.
          </div>
        )}
      </Dropdown>
    </div>
  );
};
