import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { RoundButton } from '../../../components/UI/RoundButton';
import { CheckboxButton } from '../../../kit/form/Checkbox';
import { updateQuestionnaire } from '../../../store/services/questionnaireService';
import { setModals } from '../../../store/actions/commonActions';
import { getUserProfile } from '../../../store/actions/profileActions';
import s from './index.module.scss';
import classnames from 'classnames';

export const QuestionnaireDisclaimer = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const onSubmit = async values => {
    const payload = {
      has_accept_warning: values.accept,
    };
    const res = await updateQuestionnaire(payload);
    if (res?.success) {
      onSuccess && onSuccess();
      dispatch(setModals([]));
      dispatch(getUserProfile());
    }
  };

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: { accept: false },
    onSubmit,
  });

  return (
    <form className={s.wrapper} onSubmit={handleSubmit}>
      <h1 className="f-24 f-500">Risk notice</h1>
      <div className={classnames(s.disclaimer, 'f-16')}>
        We have qualified you as a{' '}
        <span className="f-500">non-sophisticated</span> investor. As a
        non-sophisticated investor, you are entitled to the highest level of
        investor protections as specified in EU regulations. However, we must
        warn you that investing in a crowdfunding project carries the risk of
        losing the entire amount of money invested.
        <br />
        <br />
        Non-sophisticated investors benefit from a reflection period during
        which they can, at any time, revoke their offer to invest or expression
        of interest in the crowdfunding offer without giving a reason and
        without incurring a penalty. The reflection period starts at the moment
        when the investor sets investing settings for the first time and expires
        after four calendar days therefrom.
      </div>
      <div className={s.checkboxes}>
        <CheckboxButton
          value="accept"
          checked={values.accept}
          onChange={() => setFieldValue('accept', !values.accept)}
        >
          I have received and understood the warning
        </CheckboxButton>
      </div>
      <div className={s.buttons}>
        <RoundButton
          type="submit"
          label="Acknowledge and continue"
          onSubmit={handleSubmit}
          disabled={!values.accept}
          className={s.submit}
          fillBackground
        />
      </div>
    </form>
  );
};
