export const GET_ACTIVITY_LIST = {
  REQUEST: 'GET_ACTIVITY_LIST_REQUEST',
  SUCCESS: 'GET_ACTIVITY_LIST_SUCCESS',
  FAILURE: 'GET_ACTIVITY_LIST_FAILURE',
};

export const GET_TAX_REPORT = {
  REQUEST: 'GET_TAX_REPORT_REQUEST',
  SUCCESS: 'GET_TAX_REPORT_SUCCESS',
  FAILURE: 'GET_TAX_REPORT_FAILURE',
};

export const SET_ACTIVITY_TYPES = 'SET_ACTIVITY_TYPES';
