import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BasicLayout from '../../layouts/BasicLayout';
import Preloader from '../../components/Preloader';
import { RoundLink } from '../../components/UI/RoundLink';
import { APP_LINKS } from '../../helpers/links';
import { verifyUserEmail } from '../../store/services/userServices';
import IconSuccess from '../../assets/icons/icon_success_122.svg';
import IconFailed from '../../assets/icons/icon_failed_122.svg';
import s from './index.module.scss';

const ERROR_CODES = {
  email_verification_code_must_be_uuid: 'email_verification_code_must_be_uuid',
  email_verification_not_found: 'email_verification_not_found',
  email_verification_has_expired: 'email_verification_has_expired',
  email_verification_already_verified: 'email_verification_already_verified',
};

const EmailVerificationStatusPage = () => {
  const { code } = useParams();
  const { token } = useSelector(state => state.userStore);
  const [state, setState] = useState({
    loading: true,
    error_code: null,
    message: null,
  });

  useEffect(() => {
    checkVerificationCode(code);
  }, [code]);

  const checkVerificationCode = async code => {
    setState(prev => ({ ...prev, loading: true }));
    const res = await verifyUserEmail(code);
    if (res?.success) {
      return setState(() => ({
        loading: false,
        error_code: res.error_code,
        message: res.data?.message,
      }));
    }
    setState(prev => ({
      ...prev,
      message: res?.message,
      error_code: res.error_code,
      loading: false,
    }));
  };

  const { error_code, loading, message } = state;

  const successIcon =
    error_code === ERROR_CODES.email_verification_already_verified ||
    error_code === null;

  return (
    <BasicLayout>
      <div className="container">
        {loading ? (
          <Preloader className={s.preloader} />
        ) : (
          <div className={s.content}>
            <img
              src={successIcon ? IconSuccess : IconFailed}
              className={s.icon}
              alt="icon"
            />
            <h2 className="f-32 f-500">{message}</h2>
            <RoundLink
              path={token ? APP_LINKS.dashboard : APP_LINKS.signIn}
              fillBackground
              className={s.link}
              label={token ? 'Go to Dashboard' : 'Sign in'}
            />
          </div>
        )}
      </div>
    </BasicLayout>
  );
};

export default EmailVerificationStatusPage;
