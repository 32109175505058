import * as React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import SocialNetworks from '../../../containers/SocialNetworks';
import { InputTextField } from '../../UI/InputTextField';
import { PasswordField } from '../../UI/PasswordField';
import { RoundButton } from '../../UI/RoundButton';
import { RoundLink } from '../../UI/RoundLink';
import { CONTACT_EMAIL } from '../../../helpers/constants';
import { APP_LINKS } from '../../../helpers/links';
import { MyLink } from '../../MyLink';
import { useAccumulator } from '../../../hooks/useAccumulator';
import Analytics from '../../../analyticsGA';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .max(50, 'Invalid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const LoginForm = ({ onSubmit, initialEmail, error }) => {
  const [storage, setStorageItem] = useAccumulator();

  const onEmailFocus = () => {
    if (storage.includes('email')) Analytics.signInEmailInput();
    setStorageItem('email');
  };

  const onPasswordFocus = () => {
    if (storage.includes('password')) Analytics.signInPasswordInput();
    setStorageItem('password');
  };

  return (
    <Formik
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
      initialValues={{
        email: initialEmail || '',
        password: '',
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form className="login-form" onSubmit={handleSubmit}>
          <h1 className="login-form__title f-500">Sign In as an investor</h1>
          <SocialNetworks className="login-form__social" />
          <div className="login-form__divider" />
          <h4 className="login-form__subtitle f-18-24 f-400">
            Sign in with email
          </h4>
          <InputTextField
            label="Email"
            name="email"
            value={values.email}
            onChange={e => setFieldValue('email', e.target.value.trim())}
            onClear={() => setFieldValue('email', '')}
            className="login-form__email"
            onFocus={onEmailFocus}
            error={errors.email && touched.email && errors.email}
          />
          <PasswordField
            value={values.password}
            onChange={handleChange}
            onClear={() => setFieldValue('password', '')}
            onFocus={onPasswordFocus}
            error={errors.password && touched.password && errors.password}
            className="login-form__password"
          />
          {error && typeof error === 'string' && error.includes(CONTACT_EMAIL) && (
            <p className="login-form__error f-16 f-500">
              There was suspicious activity on your profile and we have blocked
              it. <br />
              Please, contact us at{' '}
              <MyLink href={`mailto:${CONTACT_EMAIL}`} isExternal>
                {CONTACT_EMAIL}
              </MyLink>
            </p>
          )}
          <RoundButton
            label="Login"
            className="login-form__submit"
            type="submit"
            onSubmit={handleSubmit}
            fillBackground
            fullWidth
            withArrow
          />
          <MyLink
            href={APP_LINKS.forgotPassword}
            onClick={() => Analytics.signInForgotPassword()}
            className="login-form__link"
          >
            Forgot password?
          </MyLink>
          <div className="login-form__bottom">
            <span>Not a member ?</span>
            <RoundLink label="Sign up" path={APP_LINKS.signUp} />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
