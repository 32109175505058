export const ROUND_STATUSES = {
  upcoming: {
    code: 'upcoming',
    label: 'Upcoming',
  },
  in_progress: {
    code: 'in_progress',
    label: 'In progress',
  },
  closed: {
    code: 'closed',
    label: 'Closed',
  },
  receiving_repayments: {
    code: 'receiving_repayments',
    label: 'Receiving repayments',
  },
};
