import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { DropdownBase } from '../../../components/Dropdown/base';
import { MyLink } from '../../../components/MyLink';
import { APP_LINKS } from '../../../helpers/links';
import { prettyFloatMoney } from '../../../helpers/utils';
import { getIncreasedIncomeStatus } from '../../../store/actions/investActions';
import { BANNER_STATUSES } from '../../../helpers/constants';
import s from './index.module.scss';

export const IncreasedIncomeBanner = ({ className }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { increasedIncomeStatus } = useSelector(state => state.investStore);
  const { data: payload } = increasedIncomeStatus;

  useEffect(() => {
    dispatch(getIncreasedIncomeStatus());
  }, []);

  if (!payload?.status || location.pathname === APP_LINKS.affiliate)
    return null;

  return (
    <div className={classnames(s.wrapper, className)}>
      {payload.status === BANNER_STATUSES.get_increased_income && (
        <IncreasedIncome />
      )}
      {payload.status === BANNER_STATUSES.keep_benefiting_save_type_a && (
        <BenefitingSaveA data={payload} />
      )}
      {payload.status === BANNER_STATUSES.keep_benefiting_save_type_b && (
        <BenefitingSaveB data={payload} />
      )}
      {payload.status === BANNER_STATUSES.keep_benefiting_save_type_c && (
        <BenefitingSaveC data={payload} />
      )}
      {payload.status === BANNER_STATUSES.keep_benefiting_done_type_a && (
        <BenefitingDoneA data={payload} />
      )}
      {payload.status === BANNER_STATUSES.keep_benefiting_done_type_b && (
        <BenefitingDoneB data={payload} />
      )}
      {payload.status === BANNER_STATUSES.keep_benefiting_done_type_c && (
        <BenefitingDoneC data={payload} />
      )}
    </div>
  );
};

const IncreasedIncome = () => (
  <DropdownBase
    render={({ open, setOpen }) => (
      <div className={s.income}>
        <div className="f-16-24 f-400">
          <span className="f-500">Increase your income by 1% per month</span> on
          the remaining Group A loans!{' '}
          {!open && (
            <button
              type="button"
              onClick={() => setOpen(!open)}
              className="underline"
            >
              Learn more
            </button>
          )}
        </div>
        <div
          className={classnames(s.drop, open && s.drop_open, 'f-16-24 f-400')}
          onClick={() => setOpen(false)}
        >
          <div
            style={{
              paddingTop: '15px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <div>
              The increased income of 1% is accrued monthly on the outstanding
              Loan A principal and paid in the last month of the loan term.
            </div>
            <ul className="scr-circle-list">
              <li>
                Achieved by topping up and investing a minimum of €100 per
                month.
              </li>
              <li>
                Or automatically applied when your active investment balance is
                €10,000 or more. When your balance falls below €10,000,
                you&apos;ll need to top up your account and invest at least €100
                per month to keep the 1% rate. Otherwise, the return for that
                month will revert to the base income rate of 0.75%.
              </li>
            </ul>
          </div>
        </div>
      </div>
    )}
  />
);

const BenefitingDoneA = () => (
  <DropdownBase
    render={({ open, setOpen }) => (
      <div className={s.income}>
        <div className="f-16-24 f-400">
          All set! Now you can{' '}
          <span className="f-500">
            keep benefiting from the Increased income of 1% per month
          </span>{' '}
          on your remaining Group A loans!{' '}
          {!open && (
            <button
              type="button"
              onClick={() => setOpen(!open)}
              className="underline"
            >
              Learn more
            </button>
          )}
        </div>
        <div
          className={classnames(s.drop, open && s.drop_open, 'f-16-24 f-400')}
          onClick={() => setOpen(false)}
        >
          <div style={{ paddingTop: '15px' }}>
            You’ve successfully met all the requirements to receive an Increased
            income in this round!
            <br />
            The Increased income of 1% is accrued monthly on the outstanding
            loan A principal and paid in the last month of the loan term.
            <br />
            Simply maintain regular monthly deposits and investments of at least
            €100 per month during the full loan repayment period.
            <br />
            Otherwise, you will receive a Basic income of 0.75% per month on the
            outstanding principal amount of loan A.
          </div>
        </div>
      </div>
    )}
  />
);

const BenefitingDoneB = () => (
  <div className={s.benefit_save}>
    <div className="f-16-24 f-400">
      Congratulations, your active investment balance is above 10,000 euros. You
      receive your Increased income of 1% per month on your remaining Group A
      loans!{' '}
      <div className="f-500">
        Keep your active investment balance at least €10,000 and enjoy increased
        returns.
      </div>
    </div>
  </div>
);

const BenefitingDoneC = ({ data }) => (
  <div className={s.benefit_save}>
    <div className="f-16-24 f-400">
      Your current balance is above €10,000, but it will drop below this amount
      after the upcoming repayment. To keep the 1% increased income for Group A,
      you&apos;ll need to invest at least{' '}
      <span className="f-500">
        {prettyFloatMoney(data.required_investments_to_10000)}
      </span>{' '}
      in the current round.
    </div>
  </div>
);

const BenefitingSaveA = ({ data }) => (
  <DropdownBase
    render={({ open, setOpen }) => (
      <div className={s.benefit_save}>
        <div className="f-16-24 f-400">
          <span className="f-500">
            Save your{' '}
            <button
              type="button"
              onClick={() => setOpen(!open)}
              className="underline"
            >
              Increased income
            </button>{' '}
            of 1% per month
          </span>{' '}
          on your remaining Group A loans!
          {!!(data.required_investments || data.required_monthly_top_ups) && (
            <ul className={s.benefit_save__list}>
              {!!data.required_monthly_top_ups && (
                <li>
                  Top up your account with at least{' '}
                  {prettyFloatMoney(data.required_monthly_top_ups)} by the end
                  of this round.{' '}
                  <MyLink href={APP_LINKS.addFunds} underline>
                    Add funds
                  </MyLink>
                </li>
              )}
              {!!data.required_investments && (
                <li>
                  Invest an additional{' '}
                  {prettyFloatMoney(data.required_investments)} in the current
                  round.{' '}
                  <MyLink href={APP_LINKS.investing} underline>
                    Allocate funds
                  </MyLink>
                </li>
              )}
            </ul>
          )}
        </div>
        <div
          className={classnames(s.drop, open && s.drop_open, 'f-16-24 f-400')}
          onClick={() => setOpen(false)}
        >
          <div
            style={{
              paddingTop: '15px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <div>
              The Increased income of 1% is accrued monthly on the outstanding
              loan A principal and paid in the last month of the loan term.{' '}
              <br />
              Simply maintain regular monthly deposits and investments of at
              least €100 per month during the full loan repayment period. <br />
              Otherwise, you will receive a Basic income of 0.75% per month on
              the outstanding principal amount of loan A.
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

const BenefitingSaveB = ({ data }) => (
  <DropdownBase
    render={({ open, setOpen }) => (
      <div className={s.benefit_save}>
        <div className="f-16-24 f-400">
          <span className="f-500">
            Get your{' '}
            <button
              type="button"
              onClick={() => setOpen(!open)}
              className="underline"
            >
              Increased income
            </button>{' '}
            of 1% per month
          </span>{' '}
          on your remaining Group A loans!
          {!!(data.required_investments || data.required_monthly_top_ups) && (
            <ul className={s.benefit_save__list}>
              {!!data.required_monthly_top_ups && (
                <li>
                  Top up your account with at least{' '}
                  {prettyFloatMoney(data.required_monthly_top_ups)} by the end
                  of this round.{' '}
                  <MyLink href={APP_LINKS.addFunds} underline>
                    Add funds
                  </MyLink>
                </li>
              )}
              {!!data.required_investments && (
                <li>
                  Invest an additional{' '}
                  {prettyFloatMoney(data.required_investments)} in the current
                  round.{' '}
                  <MyLink href={APP_LINKS.investing} underline>
                    Allocate funds
                  </MyLink>
                </li>
              )}
            </ul>
          )}
        </div>
        <div
          className={classnames(s.drop, open && s.drop_open, 'f-16-24 f-400')}
          onClick={() => setOpen(false)}
        >
          <div
            style={{
              paddingTop: '15px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <div>
              The Increased income of 1% is accrued monthly on the outstanding
              loan A principal and paid in the last month of the loan term.
              <br />
              Simply maintain regular monthly deposits and investments of at
              least €100 per month during the full loan repayment period.
              <br />
              Otherwise, you will receive a Basic income of 0.75% per month on
              the outstanding principal amount of loan A.
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

const BenefitingSaveC = ({ data }) => (
  <DropdownBase
    render={({ open, setOpen }) => (
      <div className={s.benefit_save}>
        <div className="f-16-24 f-400">
          <span className="f-500">
            Save your{' '}
            <button
              type="button"
              onClick={() => setOpen(!open)}
              className="underline"
            >
              Increased income
            </button>{' '}
            of 1% per month
          </span>{' '}
          on your remaining Group A loans!
          <ul className={s.benefit_save__list}>
            {!!data.required_monthly_top_ups && (
              <li>
                Top up your account with at least{' '}
                {prettyFloatMoney(data.required_monthly_top_ups)} by the end of
                this round.{' '}
                <MyLink href={APP_LINKS.addFunds} underline>
                  Add funds
                </MyLink>
              </li>
            )}
            {!!data.required_investments && (
              <li>
                Invest an additional{' '}
                {prettyFloatMoney(data.required_investments)} in the current
                round.{' '}
                <MyLink href={APP_LINKS.investing} underline>
                  Allocate funds
                </MyLink>
              </li>
            )}
            {!!data.required_investments_to_10000 && (
              <>
                <span style={{ left: '-14px', position: 'relative' }}>or</span>
                <li>
                  <span className="f-500" style={{ color: 'red' }}>
                    NEW!
                  </span>{' '}
                  Invest an additional{' '}
                  {prettyFloatMoney(data.required_investments_to_10000)} in the
                  current round.{' '}
                  <MyLink href={APP_LINKS.investing} underline>
                    Allocate funds
                  </MyLink>
                </li>
              </>
            )}
          </ul>
        </div>
        <div
          className={classnames(s.drop, open && s.drop_open, 'f-16-24 f-400')}
          onClick={() => setOpen(false)}
        >
          <div>
            The increased income of 1% is accrued monthly on the outstanding
            Loan A principal and paid in the last month of the loan term.
          </div>
          <ul className="scr-circle-list">
            <li>
              Achieved by topping up and investing a minimum of €100 per month.
            </li>
            <li>
              Or automatically applied when your active investment balance is
              €10,000 or more. When your balance falls below €10,000,
              you&apos;ll need to top up your account and invest at least €100
              per month to keep the 1% rate. Otherwise, the return for that
              month will revert to the base income rate of 0.75%.
            </li>
          </ul>
        </div>
      </div>
    )}
  />
);
