import React from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import {
  isExpiredResetDate,
  resetInvestingExpireDate,
} from '../../../../helpers/date';
import { useGetAllocationStatus } from '../../../../hooks/useGetAllocationStatus';
import { setModals } from '../../../../store/actions/commonActions';
import { MODAL_TYPES } from '../../../../components/ModalProvider';
import s from './index.module.scss';

export const ResetInvesting = ({ status, className, showAlways, onReset }) => {
  const { allocatedGroupA, allocatedGroupB } = useGetAllocationStatus();
  const dispatch = useDispatch();

  if (!status) return null;

  if (status.editable_until && isExpiredResetDate(status.editable_until))
    return null;

  if (!allocatedGroupA && !allocatedGroupB && !showAlways) return null;

  const showDate = status.has_accepted_necessary_terms;

  const handleReset = () => {
    status.step >= 3
      ? dispatch(
          setModals({
            type: MODAL_TYPES.confirm_modal,
            confirmLabel: 'Reset',
            onConfirm: () => {
              onReset();
              dispatch(setModals([]));
            },
            onCancel: () => dispatch(setModals([])),
            title: 'Are you sure you want to proceed with the reset?',
            desc: 'Resetting will erase your current settings, including any completed steps. To make adjustments, please use the Edit button instead.',
          })
        )
      : onReset();
  };

  return (
    <div className={classnames(s.wrapper, className)}>
      <div>
        <h2 className="f-26 f-500">Changed your mind?</h2>
        <p className="f-16 f-400">
          {showDate
            ? `You can decide not to participate in the round before ${resetInvestingExpireDate(
                status.editable_until,
                status.end_date
              )}`
            : 'You can decide not to participate in the round'}
        </p>
      </div>
      <button type="button" className="f-16 f-400 hov" onClick={handleReset}>
        Reset investing
      </button>
    </div>
  );
};
