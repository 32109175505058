import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage } from '../../helpers/utils';
import { GET_COMPLAINT_DETAIL, GET_COMPLAINTS } from '../types/complaintTypes';

const getComplaintsRequest = () => ({ type: GET_COMPLAINTS.REQUEST });
const getComplaintsSuccess = payload => ({
  type: GET_COMPLAINTS.SUCCESS,
  payload,
});
const getComplaintsFailure = error => ({ type: GET_COMPLAINTS.FAILURE, error });

const getComplaintDetailRequest = () => ({
  type: GET_COMPLAINT_DETAIL.REQUEST,
});
const getComplaintDetailSuccess = payload => ({
  type: GET_COMPLAINT_DETAIL.SUCCESS,
  payload,
});
const getComplaintDetailFailure = error => ({
  type: GET_COMPLAINT_DETAIL.FAILURE,
  error,
});

export const getComplaints = () => {
  return dispatch => {
    dispatch(getComplaintsRequest());
    return axios
      .get(Pathes.Complaint.complaints)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getComplaintsSuccess(data));
          return { data, success: true };
        }

        throw new Error(message);
      })
      .catch(e => {
        dispatch(getComplaintsFailure(e.message));
      });
  };
};

export const getComplaintDetail = id => {
  return dispatch => {
    dispatch(getComplaintDetailRequest());
    return axios
      .get(Pathes.Complaint.complaint(id))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getComplaintDetailSuccess(data));
          return { data, success: true };
        }

        throw new Error(message);
      })
      .catch(e => {
        dispatch(getComplaintDetailFailure(e.message));
      });
  };
};
